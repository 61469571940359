import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prop2arr'
})
export class Prop2arrPipe implements PipeTransform {

  /**
   * Returns an array of the props
   */
  transform(obj: Array<any>, propName: string): Array<any> {
    return obj?.map(o=>o?.[propName]);
  }

}
