import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { PrintProduct } from 'src/app/models/printProduct.model';
import { PartialPrintGroupData, mapGroupData } from '../print-partial-groups/print-partial-groups.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { _PrintFile, getPrintFileJobType } from 'src/app/models/models';
import { PrintJob } from 'src/app/models/print-job.model';
import { peloticaColor } from '../print-partial-group-info/print-partial-group-info.component';
import { getPrintFileCoverException, getPrintFileCoverExceptionTags, getPrintFileCoverSettings } from 'src/app/shared/utils/print-group-document-helper';

export interface WorkFile extends _PrintFile {
  index: number,
  type: PrintJob.Type,
}

@Component({
  selector: 'app-print-partial-files',
  templateUrl: './print-partial-files.component.html',
  styleUrls: ['./print-partial-files.component.scss']
})
export class PrintPartialFilesComponent implements OnInit {
  @Input() printProducts: Array<PrintProduct> = [];
  @Input() exceptionCoverLaminated: FormControl;
  @Output() changes = new EventEmitter<any>();
  @ViewChild(MatTable) tableData: MatTable<any>;

  constructor() { }
  groupData: Array<PartialPrintGroupData> = [];
  files: WorkFile[] = [];
  displayedColumns: string[] = ["select", "info"];
  selection = new SelectionModel<any>(true, []);
  emitSubscription: Subscription;
  groupIndex = new FormControl(null);
  group: PartialPrintGroupData = null;

  files$: Observable<WorkFile[]>;

  ngOnInit(): void {

    this.emitSubscription = combineLatest([
      this.selection.changed.pipe(startWith(this.selection.selected)),
      this.groupIndex.valueChanges,
    ]).subscribe(_ => {
      // console.log('EMIT', this.selection.selected.length, this.groupIndex.value, `has value: ${this.selection.hasValue()}`);
      this.changes.emit({
        type: 'selected',
        payload: {
          selected: [this.groupData[this.groupIndex.value]],
          selectedFiles: this.selection.selected as Array<WorkFile>,
        }
      })
    });

    this.files$ = combineLatest([
      this.exceptionCoverLaminated.valueChanges.pipe(startWith(this.exceptionCoverLaminated.value)),
      this.groupIndex.valueChanges,
    ]).pipe(
      map(([exceptionCoverLaminated, groupIndex]) => {
        // console.log('CLEAR', this.selection.selected.length, this.groupIndex.value, `has value: ${this.selection.hasValue()}`);
        this.group = this.groupData[groupIndex];

        const printProduct = this.group.printProduct as PrintProduct;
        const printSettings = printProduct.printingGroup.printSettings;
        this.files = printProduct.printingGroup.files
          .map((file, index) => {
            // check cover exceptions:
            const { coverType, needsSeparateCover, isDepaginated } = getPrintFileCoverSettings(file, printSettings);
            const printFileCoverException = getPrintFileCoverException(file, printSettings);
            // get cover tags:
            const options = {
              file: file, printSettings: printSettings,
              printFileCoverException, needsSeparateCover,
              coverType, isDepaginated,
            };
            const coverTags = getPrintFileCoverExceptionTags(options);
            const coverTagsHl = coverTags.filter(e => !e.includes('despaginación')); //  :-/  ugly patch
            const coverTagsRegular = coverTags.filter(e => e.includes('despaginación'));
            const printFileJobType = getPrintFileJobType(file, printSettings);

            const _file = ({
              ...file,
              index,
              type: printFileJobType,
              peloticaColor: peloticaColor(printFileJobType),
              coverTags, coverTagsHl, coverTagsRegular,
            });
            return _file;
          }).filter(e => !exceptionCoverLaminated || !!e.coverLaminated);

        this.selection.clear();
        // this.emitChanges();
        return this.files;
      })
    )
  }

  ngOnChanges({ printProducts: _printProducts }: SimpleChanges): void {
    if (_printProducts && _printProducts.currentValue) {
      const printProducts = _printProducts.currentValue;
      this.groupData = mapGroupData(printProducts);
      this.groupIndex.patchValue(this.groupData.length ? 0 : null)
    }
  }

  ngOnDestroy(): void {
    this.emitSubscription.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.files.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.files);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  peloticaColor = peloticaColor;
}

