import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EmmiterMessage, OutputMessage } from 'src/app/models/output.model';

@Component({
  selector: 'app-printer-selector',
  templateUrl: './printer-selector.component.html',
  styleUrls: ['./printer-selector.component.scss']
})
export class PrinterSelectorComponent implements OnInit, EmmiterMessage {
  @Input() disabled:boolean = true;
  @Input() control: FormControl = null;
  @Input() iconTrigger:string = null;
  @Input() icon:string = null;
  @Input() options:Array<any> = [];
  @Input() callbackName:string = "OnSelect";
  @Input() defaultValue:string = "No Seleccionado";
  @Input() finderDisplayValue:string[] = [];
  @Output() action = new EventEmitter<OutputMessage>();
  

  constructor() { }
  
  ngOnInit(): void {
  }
  
  get currentValue(){
    let result = this.control?.value ?? null
    if (result) result = this.getPropOf(result);
    return result;
  }


  public getPropOf(objectToSee){
    this.finderDisplayValue.forEach(finder => {
      objectToSee = objectToSee?.[finder];
    });
    return objectToSee;
  }


  emitAction(type: string, value?: any): void { this.action.emit(new OutputMessage(type,value))}
}
