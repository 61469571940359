import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPrintersDialogComponent } from './user-printers-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    UserPrintersDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  exports: [
    UserPrintersDialogComponent
  ]
})
export class UserPrintersDialogModule { }
