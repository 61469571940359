import { UserModel, _User } from "../core/user.model";
import { Order, PageSize, PaperWeight, PrintColor, ShippingCourier, UpdatedBy } from "./models";
import { PrintJob } from "./print-job.model";
import { ProcessError } from "./errors.js";

export interface Printer {
  // on creation by client:
  id?: string;
  createdAt: Date;
  updatedAt: Date;
  port: number; // port number on network
  label: string; // as named by copyfly
  status: Printer.Status; // on creation: 'validate' to request validation
  isVisible: boolean; // to hide printer from all except super-admin rol
  // others added by client:
  uids?: Array<String>; // user ids assigned to this printer
  // validated by server:
  errorMessage?: String; // error in case status=invalid
  colorSupported: boolean;
  finisherDescription?: String; // null if no finisher
  makeModel: String; // as in: 'TASKalfa 9003i'
  name: String; // as in: 'KMAFA2F3'
  uri: String; // used by server
  supportedSettings: Array<Printer.SettingDefinition>; // editable settings by operator
  mediaSourceSettings: Array<Printer.MediaSourceSettings>; // settings to configure paper size and weight by source tray

  usage: string; //Printer.PrinterUsage
  // shippingCourier?: string; // ShippingCourier // not anymore
  // shippingCouriers?: Array<string>; // ShippingCourier ==> Error: 3 INVALID_ARGUMENT: A maximum of 1 'ARRAY_CONTAINS' filter is allowed per disjunction.
  shippingCouriers?: Printer.ShippingCouriersConfig;

  userIds: Array<string>;
  // users: Array<_User>; // not used anymore
  updatedBy?: UpdatedBy & { updatedAt: Date }; // updated settings

  printsCount?: Printer.PrintsCount; // XA: 24/oct/2022
  allowMixedJobs?: boolean;
  allowBnJobs?: boolean;
}
// Reference with attributes:
export namespace Printer {

  export interface PrintsCount {
    snmpCode: string;
    monthlyLimit?: number; // ex: ~ 1.000.000
    monthlyAlertPct?: number; // ~ 0 - 100
    alertStatus?: 'inactive' | 'active' | 'override';
    currentCount?: number;// ~75.000.000
    monthlyCurrentCount?: number; // // 800.000
  }


  export const newPrinterReference = (printer: Printer.ReferenceSettings): Printer.Reference => {
    const { id, label, uri } = printer;
    return { id, label, uri }
  }
  export interface Reference {
    id?: String;
    label: String; // as named by copyfly
    uri: String; // used by server
  }

  export interface ReferenceSettings {
    id?: String;
    label: String; // as named by copyfly
    makeModel: String;
    uri: String; // used by server
    supportedSettings: Array<Printer.SettingDefinition>; // editable settings by operator
  }

  export enum Status {
    VALIDATE = 'validate',
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    INVALID = 'invalid',
  }

  export interface SettingDefinition {
    key: String;
    label: String;
    values: Array<{
      label: String;
      value: any; // usually, string. maybe object
    }>;
  }

  export interface MediaSourceSettings { // not used by client
    mediaSource: String; // 'by-pass-tray', 'tray-1', 'tray-2', 'tray-3', 'tray-4'...
    paperSize?: PageSize; // ==>> ALREADY DEFINED SOMEWHERE ELSE
    paperWeight?: PaperWeight; // ==>> ALREADY DEFINED SOMEWHERE ELSE
  }

  export enum PrinterUsage {
    DOCUMENTS = 'documents', // "Documentos"
    LABELING = 'labeling',  // "Etiquetadora"
  };

  export enum Manufacturer {
    CANON = 'Oce', // yup... Oce it reports...
    KONICA = 'KONICA MINOLTA',
  };


  export const printStatusInfo = {
    [Status.VALIDATE]: {
      color: '#009ACF',
      icon: 'sync',
      tooltip: 'Validando',
      status: Status.VALIDATE
    },
    [Status.ENABLED]: {
      color: '#76ab21ff',
      icon: 'check_circle_outline',
      tooltip: 'Activa',
      status: Status.ENABLED
    },
    [Status.DISABLED]: {
      color: '#d8e7e9ff',
      icon: 'block',
      tooltip: 'Inactiva',
      status: Status.DISABLED
    },
    [Status.INVALID]: {
      color: '#e00007ff',
      icon: 'error_outline',
      tooltip: 'Inválida',
      status: Status.INVALID
    }
  }

  export interface ShippingCouriersConfig { // mu ma... cómo se hace esto de forma genérica si lo que hay es un enum? pendiente...
    [ShippingCourier.NONE]: boolean,
    [ShippingCourier.CEX]: boolean,
    [ShippingCourier.MRW]: boolean,
    [ShippingCourier.SENDING]: boolean,
  }
}

export interface PrinterStatus {
  id: string;
  colorSupported: boolean,
  deviceId: string;
  label: string;
  pagesPerMinute: number;
  printSheets: number;
  printSheetsByWeight: printSheetsByWeight;
  printSides: number;
  protocol: "cups"
  queuedSheets: number;
  status: Printer.Status;
  ippStatus?: IppStatus;
  userIds: Array<string>;
  estimatedSeconds: number;
  allowMixedJobs?: boolean;
  allowBnJobs?: boolean;
  manufacturer?: string;
  // others not directly stored in firebase (but mapped from printer capabilities):
  stapleSupported?: boolean,
  blankSheetsSupported?: boolean,
  coverLaminatedSupported?: boolean,
  pageSizeSupported?: Array<PageSize>,
  paperWeightSupported?: Array<PaperWeight>,
  largerPageSizeSupported?: Array<PageSize>,
  updatedBy?: UpdatedBy & { updatedAt: Date }; // updated settings
}

export interface PrinterCapabilities {
  id: string; // printer id. beware when taking from PrinterStatus, this id is actually 'printerStatus'
  colorSupported: boolean,
  label: string;
  allowMixedJobs: boolean,
  allowBnJobs: boolean,
  stapleSupported: boolean,
  blankSheetsSupported: boolean,
  coverLaminatedSupported: boolean,
  pageSizeSupported: Array<PageSize>,
  paperWeightSupported: Array<PaperWeight>,
  largerPageSizeSupported: Array<PageSize>,
  manufacturer?: string;
}

export namespace PrinterCapabilities {
  export const fromPrinterStatus = (printerStatus?: PrinterStatus): PrinterCapabilities => {
    // console.log('fromPrinterStatus', printerStatus);
    return printerStatus ? {
      id: printerStatus.deviceId,
      colorSupported: printerStatus.colorSupported ?? false,
      label: printerStatus.label,
      allowMixedJobs: printerStatus.allowMixedJobs ?? false,
      allowBnJobs: printerStatus.allowBnJobs ?? false,
      stapleSupported: printerStatus.stapleSupported ?? false,
      blankSheetsSupported: printerStatus.blankSheetsSupported ?? false,
      coverLaminatedSupported: printerStatus.coverLaminatedSupported ?? false,
      pageSizeSupported: printerStatus.pageSizeSupported ?? [],
      paperWeightSupported: printerStatus.paperWeightSupported ?? [],
      largerPageSizeSupported: printerStatus.largerPageSizeSupported ?? [],
      manufacturer: printerStatus.manufacturer ?? null,
    } : null;
  };
}


export interface IppStatus {
  printerIsAcceptingJobs: boolean;
  printerState: "idle" | "processing" | "stopped", //usar esto
  printerStateReasons: PrinterStateReasons; //usar esto
  queuedJobCount: number;
}

interface printSheetsByWeight {
  [PaperWeight.W80]: number;
  [PaperWeight.W90]: number;
  [PaperWeight.W100]: number;
  [PaperWeight.W120]: number;
  [PaperWeight.W160]: number;
  [PaperWeight.W200]: number;
  [PaperWeight.W300]: number;
}

interface printSidesByColor {
  [PrintColor.BLACKNWHITE]: number;
  [PrintColor.COLOR]: number;
}

type PrinterStateReasons = Array<string> //'media-empty-warning' //Partir ultimo guion  'warning' 'error' 'report', primera parte el problema.



export type loadStatusType = 'free' | 'little' | 'medium' | 'large'
export const loadStatusDef = {
  'free': { color: 'var(--color-green)' },
  'little': { color: 'var(--color-yellow)' },
  'medium': { color: 'var(--color-orange)' },
  'large': { color: 'var(--color-red)' },
  // 'free':{color:'green'},
  // 'little':{color: 'rgb(230,200,89)'},
  // 'medium':{color: 'orange'},
  // 'large':{color: 'red'}
}
export const getLoadStatus = (estimatedSeconds) => {
  const statusRange = [
    { min: Number.NEGATIVE_INFINITY, max: 1, status: 'free' },
    { min: 1, max: 300, status: 'little' },
    { min: 300, max: 600, status: 'medium' },
  ]
  const inRange = statusRange.find(range => estimatedSeconds >= range.min && estimatedSeconds < range.max);
  return inRange ? inRange.status : 'large';
}

export interface PrinterAlert {
  level: number;
  status: 'error' | 'warning';
  reason: string;
  display: {
    icon: string;
    color: string;
  }
}

export const getPrinterAlerts = (printerStatus: PrinterStatus) => {
  const printerReasons = [...printerStatus.ippStatus.printerStateReasons];
  const LEVEL = {
    error: 0,
    warning: 1
  }
  const DISPLAY = {
    error: {
      icon: 'error',
      color: 'red'
    },
    warning: {
      icon: 'warning_amber',
      color: 'orange'
    }
  }

  return printerReasons
    .map(splitReason)
    .filter(alertReason => ['error', 'warning'].includes(alertReason.status))
    .map(alertReason => ({ ...alertReason, level: LEVEL[alertReason.status], display: DISPLAY[alertReason.status] } as PrinterAlert))
    .sort((a, b) => a.level - b.level)
}

const splitReason = (printerReason: string) => {
  const tokens = printerReason.split('-');
  const status = tokens.pop();
  const reason = tokens.join('-');
  return { status, reason }
}


export interface PrintRequirement {
  id?: string
  createdAt?: Date | any;
  updatedAt?: Date | any;
  order: RequirementOrderRef;
  // type?: RequirementType // (order, group, file) <- bundle type as well
  bundles: Array<PrintBundle>
  status: RequirementStatus;
  user: UserModel.Reference;
  // printJobType?: PrintJob.Type // PrintJobType: bn, color, cover: force this type only  
  pageSize?: PageSize; // default, a4
  reportOnly?: boolean; // default, false. print only the report, not the print groups
  skipReport?: boolean; // default: false. skip printing the report when it usually should. overriden by reportOnly.
  printGroupIndexes?: Array<number>; // default: null.  cart.items[].filter(product.type=='print') => print group indexes to be printed
  printGroupFileIndexes?: Array<number>; // default: null.  cart.items[].filter(product.type=='print').product.printGroup.files[] => files to be printed. printGroupIndexes should be single index.
  // extras:
  forceLargerPageSize?: boolean; // force print in next larger page size
}

export type RequirementOrderRef = Pick<Order, "id" | "number" | "contains" | 'createdAt' | 'priority'>
export const genRequirentOrderRef = (order: Order) => {
  return ['id', 'number', 'contains', 'createdAt', 'priority'].reduce((ref, key) => {
    if (key in order) ref[key] = order[key];
    return ref
  }, {} as RequirementOrderRef)

}

// export type RequirementType = 'order' | 'group' | 'file';
export enum PrintJobTypeByPrinterColor {
  BN_ON_BN = 'bnOnBn',
  BN_ON_COLOR = 'bnOnColor',
  COLOR_ON_COLOR = 'colorOnColor',
}
export interface PrintJobTypeByPrinterColorSet {
  [PrintJobTypeByPrinterColor.BN_ON_BN]: number,
  [PrintJobTypeByPrinterColor.BN_ON_COLOR]: number,
  [PrintJobTypeByPrinterColor.COLOR_ON_COLOR]: number,
}

export interface PrintBundle {
  id: string;
  printJobType: PrintJob.Type // PrintJobType: bn, color, cover
  pageSize: PageSize;
  printSheets: number;
  printSheetsByWeight: printSheetsByWeight;
  printSidesByColor: printSidesByColor;
  printer: { id: String, label: String, colorSupported?: boolean };
  status: BundleStatus // print status
  pages?: number
  date?: Date
  reportOnly?: boolean; // print only the report, not the print groups
  printGroupIndexes?: Array<number>; // default: null (means all docs were printed)
  printGroupFileIndexes?: Array<number>; // default: null (means all docs were printed)
  // server stuff:
  pageSizeForced?: PageSize; // added by server on requirement.forceLargerPageSize: true
  error?: ProcessError;
  // preprocessJobIdsLength does not exist on actual subcollection bundle. preprocessJobIds[] does.
  preprocessJobIdsLength?: number;
  // printJobStatusesLength does not exist on actual subcollection bundle. printJobStatuses[] does.
  printJobStatusesLength?: number;
  // retry handling:
  retriedFrom?: { // if this exists: this is destination bundle making the retry
    bundleId: string, // bundle id this bundle is retrying from
    requirementId: string,
  },
  retryStatus?: BundleRetryStatus, // this is the origin bundle being retried
}

export enum BundleStatus {
  PENDING = 'pending', // not triggered yet
  QUEUED_PROCESS = 'queued_process', // queued to be processed (file transform)
  PREPROCESSING = 'preprocessing', // file transformation
  QUEUED_PRINT = 'queued_print', // queued to be printed
  PRINTING = 'printing', // currently printing
  WARNING = 'warning', // some file is in warning status (paused, recoverable error)
  FAILED = 'failed', // some file had an unrecoverable error
  FINISHED = 'finished', // all good, finished printing without problems
}

export enum BundleRetryStatus {
  PENDING = 'pending', // not used actually
  PROCESSING = 'processing', // bundle is being retried in another requirement
  FINISHED = 'finished', // bundle has been retried in another requirement (with a failed/finished bundle status)
}

export enum RequirementStatus {
  PENDING = 'pending', // not triggered
  PROCESSING = 'processing',
  CLEARED = 'cleared', // not shown in list
}
