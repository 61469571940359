import { isEqual, pick } from "lodash";
import { _PrintFile, CoverType, PagesPerSheet, PrintColor, PrintSettings, Settings } from "src/app/models/models";
import { PrintJob } from "src/app/models/print-job.model";

/////////////////////////////////////////////////////////////////
//// ===>> same functions and definitions from functions 14. ////
////                       DO NOT EDIT                       ////
/////////////////////////////////////////////////////////////////

// this represents the actual color of the print group (according to files and covers),
// not what the client asked on the print settings
export enum PrintGroupActualColor {
  BN = "bn",
  COLOR = "color",
  MIXED = "mixed",
}

export enum PrintFileColorException { // keep order: most problematic first
  COVER_LAMINATED = 'cover_laminated',
  COVER_COLOR = 'cover_color',
  COVER_BN = 'cover_bn', // bn cover on bn group
  DOCUMENT_COLOR = 'document_color',
  DOCUMENT_BN = 'document_bn',
  DOCUMENT_COVER_ONLY = 'cover_only',
}

export const PrintGroupActualColorLabel = {
  [PrintGroupActualColor.COLOR]: 'Color',
  [PrintGroupActualColor.BN]: 'B/N',
  [PrintGroupActualColor.MIXED]: 'Mixto'
}

export const PrintFileColorExceptionLabel = {
  [PrintFileColorException.COVER_LAMINATED]: 'Portada PL',
  [PrintFileColorException.COVER_COLOR]: 'Portada Color',
  [PrintFileColorException.COVER_BN]: 'Portada B/N',
  [PrintFileColorException.DOCUMENT_COLOR]: 'Color',
  [PrintFileColorException.DOCUMENT_BN]: 'B/N',
  [PrintFileColorException.DOCUMENT_COVER_ONLY]: 'Sólo Portada',
}


//// document helpers:

/**
* Calculates definitions for a file according to cover settings
* @param {Object} file file to print
* @param {PrintSettings} printSettings from group, no file overrides yet
* @param {Object?} options extra options needed on actual printing
* @param {PrintJob.Type} options.printJobType print job type
* @param {boolean} options.hasMixedPrint whether prints will go through single mixed bundle
* @param {boolean} options.forPrint whether this calculation will be used for printing (false on admin and report. true on requirements)
* @returns 
*/
export function getPrintFileCoverSettings(
  file: _PrintFile,
  printSettings: PrintSettings,
  options: { forPrint?: boolean, printJobType?: PrintJob.Type, hasMixedPrint?: boolean } = {}, // not used here in admin
) {
  const { forPrint, printJobType, hasMixedPrint } = options; // useful only when printing

  // file.coverType: now cover is never printed as an extra sheet.
  // split to handle cover settings, but never double print
  const defaultCoverType: Partial<CoverType> = { // try to build default from other values
    coverColor: !!file.docColor,
    twoSided: printSettings.twoSided,
    pagesPerSheet: printSettings.pagesPerSheet,
    paperWeight: printSettings.paperWeight,
  };
  const oldCoverType: Partial<CoverType> = { // try to build equivalent of old cover color
    coverColor: true,
    twoSided: false,
    pagesPerSheet: PagesPerSheet.ONE,
    paperWeight: printSettings.paperWeight,
    default: false,
  };
  // patch: new f.coverType may not exist in old repeating orders. don't break!
  // this is the corresponding coverType, in case file didn't have any
  const coverType = file.coverType ?? (file.coverColor ? oldCoverType : defaultCoverType);

  // definitions:
  // patch 2: recalculate default: it's not used the same way in app that in server
  const isCoverDefault = isEqual(defaultCoverType, pick(coverType, Object.keys(defaultCoverType)));

  const isDepaginated = !isCoverDefault
    && (
      printSettings.pagesPerSheet !== coverType.pagesPerSheet
      || printSettings.twoSided !== coverType.twoSided
    );
  // if job type is not requested, this may be false and rest of document won't have the appropriate page range: "would need" vs "needs"
  // make sure evaluation arguments are boolean! otherwise we could get undefined
  const wouldNeedSeparateCover = !isCoverDefault || !!file.coverLaminated; // not default cover, or laminated (default or not)

  const needsSeparateCover = (!printJobType || [PrintJob.Type.COVER, PrintJob.Type.MIXED].includes(printJobType))
    && (
      forPrint
        ? (
          (!isCoverDefault && !file.coverLaminated && !printSettings['coverLaminatedException']) // regular cover, no exception requested (regular page size is being printed)
          || (!!file.coverLaminated && !!printSettings['coverLaminatedException']) // coverLaminated, with it's exception
        )
        : (wouldNeedSeparateCover)
    );

  const separateCoverSides = wouldNeedSeparateCover
    ? (
      (!printSettings.twoSided || isDepaginated)
        ? 1
        : Math.min(2, file.pages) // this covers the case where 1-2 page doc is cover
    )
    : 0;
  // i.e.: in app, user can't select 2pps color cover when doc is bn. BUT, we can change paperWeight and cause a separate cover.
  // We need to count pages in case of 2-4pps:
  const separateCoverPages = Math.min(separateCoverSides * coverType.pagesPerSheet, file.pages);
  const isDocCoverOnly = separateCoverPages >= file.pages;
  // handle new docColor definition: now each file is represented by its docColor setting
  const needsColorDoc = (!printJobType || [PrintJob.Type.COLOR, PrintJob.Type.MIXED].includes(printJobType))
    && file.docColor && !isDocCoverOnly;
  const needsBnDoc = (!printJobType || [PrintJob.Type.BN, PrintJob.Type.MIXED].includes(printJobType))
    && !file.docColor && !isDocCoverOnly;
  // needsTraceCover: repeated cover in bn to be printed in front of rest of bn document when cover WOULD be printed in a different bundle
  const needsTraceCover = wouldNeedSeparateCover
    && (
      (!!file.coverLaminated && (needsBnDoc || needsColorDoc)) // with bn or color doc (if they were requested)
      || (needsBnDoc && coverType.coverColor && !hasMixedPrint) // with bn doc (if it was requessted)
    );

  return {
    coverType, isCoverDefault, isDepaginated,
    wouldNeedSeparateCover, needsSeparateCover,
    separateCoverSides, separateCoverPages, isDocCoverOnly,
    needsColorDoc, needsBnDoc, needsTraceCover,
  };
}


// this represents the actual color of the print group (according to files and covers), not what the client asked on the print settings
export function getPrintGroupActualColor(
  files: _PrintFile[],
  printSettings: PrintSettings,
): PrintGroupActualColor {
  // this below is not taking into account isDocCoverOnly exception, for each file
  let colorActual: PrintGroupActualColor;
  // these 2 are not properly being set from app. calculate locally: (don't use printSettings.docColor)
  // const coverColor = files.some(f => {
  //   return f.coverType?.coverColor ?? f.coverColor ?? false; // consider old orders
  // });
  const docColor = printSettings.color == PrintColor.BLACKNWHITE
    ? files.some(e => e.docColor) // bn group: docColor:true means there's at least 1 color doc
    : files.every(e => e.docColor); // color group: docColor:false means there's at least 1 bn doc


  if (printSettings.color == PrintColor.COLOR) {
    if (docColor) {
      // full color - no bn docs in color group
      colorActual = PrintGroupActualColor.COLOR;
    } else {
      // some bn docs in a color group
      const allBn = files.every(f => !f.docColor);
      if (allBn) {
        // they're actually all bn (evaluate like a bn group)
        colorActual = PrintGroupActualColor.BN;
      } else {
        // some bn docs in color group (mix)
        colorActual = PrintGroupActualColor.MIXED;
      }
    }
  } else { // color == 'bn'
    if (!docColor) {
      // no color docs
      colorActual = PrintGroupActualColor.BN;
    } else {
      // some color docs in a bn group
      const allColor = files.every(f => f.docColor);
      if (allColor) {
        // they're all color docs
        colorActual = PrintGroupActualColor.COLOR;
      } else {
        // some color docs in a bn group (mix)
        colorActual = PrintGroupActualColor.MIXED;
      }
    }
  }
  return colorActual;
}

// get file color exception for single file (depends on group actual color)
// this is to show on the file color exception
export function getPrintFileDocumentException(
  file: _PrintFile,
  printGroupActualColor: PrintGroupActualColor,
  options: {
    isDocCoverOnly: boolean,
  }
): PrintFileColorException {
  const { isDocCoverOnly } = options;
  let printFileColorException: PrintFileColorException = null;

  if (isDocCoverOnly) {
    printFileColorException = PrintFileColorException.DOCUMENT_COVER_ONLY;
  } else if (printGroupActualColor == PrintGroupActualColor.MIXED) {
    printFileColorException = file.docColor
      ? PrintFileColorException.DOCUMENT_COLOR : PrintFileColorException.DOCUMENT_BN;
  }

  return printFileColorException;
}

export function getPrintFileCoverException(
  file: _PrintFile,
  printSettings: PrintSettings,
): PrintFileColorException {
  let printFileColorException: PrintFileColorException = null;

  const {
    needsSeparateCover, separateCoverSides, separateCoverPages,
    coverType, isDocCoverOnly, isDepaginated,
    needsBnDoc, needsColorDoc
  } = getPrintFileCoverSettings(file, printSettings);

  if (file.coverLaminated) {
    printFileColorException = PrintFileColorException.COVER_LAMINATED;
  } else if (needsSeparateCover && !file.docColor && coverType.coverColor) {
    printFileColorException = PrintFileColorException.COVER_COLOR; // separate color cover in bn doc
  } else if (needsSeparateCover && !file.docColor && !coverType.coverColor) {
    printFileColorException = PrintFileColorException.COVER_BN; // separate bn cover in bn doc. color matches
  } else if (needsSeparateCover && file.docColor && coverType.coverColor) {
    printFileColorException = PrintFileColorException.COVER_COLOR; // separate color cover in color doc. color matches
  }

  return printFileColorException;
}

export function getPrintFileCoverExceptionTags(options: {
  file: _PrintFile,
  printSettings: PrintSettings,
  printFileCoverException: PrintFileColorException,
  needsSeparateCover: boolean,
  coverType: Partial<CoverType>,
  isDepaginated: boolean,
}): string[] {
  const {
    file, printSettings,
    printFileCoverException,
    needsSeparateCover, coverType, isDepaginated,
  } = options;

  const coverTags: string[] = [];

  if (needsSeparateCover) {
    if (printFileCoverException) {
      coverTags.push(PrintFileColorExceptionLabel[printFileCoverException]);
    }
    if (coverType.coverColor && !file.docColor) {
      const alreadyColor = [
        PrintFileColorException.COVER_COLOR,
        PrintFileColorException.COVER_LAMINATED,
      ].includes(printFileCoverException);
      if (!alreadyColor) {
        coverTags.push(PrintFileColorExceptionLabel[PrintFileColorException.DOCUMENT_COLOR]);
      }
    }
    if (coverType.pagesPerSheet !== printSettings[Settings.PAGES_PER_SHEET]) {
      coverTags.push(`${coverType.pagesPerSheet}ppc`);
    }
    if (coverType.paperWeight !== printSettings[Settings.PAPER_WEIGHT]) {
      coverTags.push(`${coverType.paperWeight}g`);
    }
    if (printSettings[Settings.TWO_SIDED]) {
      if (isDepaginated) {
        coverTags.push(`Con despaginación`);
      } else {
        coverTags.push(`Sin despaginación`);
      }
    }
  }

  return coverTags;
}

// gets worst file exception for the group, according to individual files
// (document or cover exception)
// this is to show on the group color exception
export function getPrintFilesColorException(
  files: _PrintFile[],
  printSettings: PrintSettings,
  printGroupActualColor: PrintGroupActualColor,
): PrintFileColorException {

  const printFileColorExceptions = new Set();

  for (const file of files) {
    let printFileColorException: PrintFileColorException;
    // cover exception:
    printFileColorException = getPrintFileCoverException(file, printSettings);
    if (printFileColorException) {
      printFileColorExceptions.add(printFileColorException);
    }
    // document exception:
    const options = getPrintFileCoverSettings(file, printSettings);
    printFileColorException = getPrintFileDocumentException(file, printGroupActualColor, options);
    if (printFileColorException && printFileColorException !== PrintFileColorException.DOCUMENT_BN) {
      printFileColorExceptions.add(printFileColorException);
    }
  }

  // force order: most problematic first (according to definition)
  const _printFileColorExceptions: PrintFileColorException[] = [];
  for (const ex of Object.values(PrintFileColorException)) {
    if (printFileColorExceptions.has(ex)) {
      _printFileColorExceptions.push(ex);
    }
  }

  return _printFileColorExceptions.shift(); // may be undefined
}
