<div mat-dialog-title fxLayout="row" fxLayoutGap="14px">
    <div>
        Impresión parcial
    </div>
    <a
        [routerLink]="['/admin/orders/',data.order.id]"
        target="_blank"
    >
        #{{data.order.number.toString().padStart(4, '0')}}
        <span
            *ngIf="data.order?.linkedOrders?.length"
            class="hl-complementary"
        >
            &nbsp;*
        </span>
    </a>

    <div class="order-contains">
        <span *ngFor="let content of data.order.contains" 
          class="shape"  
          [ngClass]="content"
          [matTooltip]="(('ORDER.CONTENT.SHAPE.'+content)| translate)"
        > 
          {{content}} 
        </span>
        <span [matTooltip]="'Nada'" *ngIf="!data.order.contains">-</span>
    </div>    
</div>

<!-- *ngIf="order$ | async as order;  else elseTemplate" -->
<div
    fxLayout="row"
    class="content-area"
>
    <div
        fxLayout="column"
        class="element-selection"
    >
        <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="p-10"
        >
            <mat-button-toggle-group
                appearance="legacy"
                name="selectionFilter"
                aria-label="Selection Filter"
                [formControl]="selectedMode"
            >
                <mat-button-toggle value="groups">Grupos</mat-button-toggle>
                <mat-button-toggle value="files">Archivos</mat-button-toggle>
            </mat-button-toggle-group>
            <div
                *ngIf="showExceptionCoverLaminated$ | async">
                <mat-checkbox
                    [formControl]="exceptionCoverLaminated">
                    Imprimir solo portadas plastificadas
                </mat-checkbox>
            </div>
        </div>
        <div
            *ngIf="(selectedMode.value) == 'groups'; else elseTemplateFiles"
            fxFlex
            class="element-selection-data"
        >
            <app-print-partial-groups
                [printProducts]=" printProducts$ | async"
                [exceptionCoverLaminated]="exceptionCoverLaminated"
                (changes)="onSelectionChanges($event, 'group')"
            ></app-print-partial-groups>
        </div>
        <ng-template #elseTemplateFiles>
            <div fxFlex  class="element-selection-data">
                <app-print-partial-files
                    [printProducts]=" printProducts$ | async"
                    [exceptionCoverLaminated]="exceptionCoverLaminated"
                    (changes)="onSelectionChanges($event, 'files')"
                ></app-print-partial-files>
            </div>
        </ng-template>

        <!-- {{  printProducts$ | async  | json}} -->
    </div>
    <div fxFlex class="p-10 printers-container">
        <app-print-partial-printers
            fxFlex
            [printWorksSelected]="printWorksSelected$ | async"
            [printFilesSelected]="printFilesSelected$ | async"
            [selectedMode]="selectedMode.value"
            [isPrintableStatusFinished]="isPrintableStatusFinished$ | async"
            [printers]="data.printers"
            [pageSize]="pageSize"
            [exceptionCoverLaminated]="exceptionCoverLaminated.value"
            [exceptionCoverLaminatedPageSize]="exceptionCoverLaminatedPageSize"
            (changes)="onPrintersChanges($event)"
        ></app-print-partial-printers>

    </div>

</div>
<div
    mat-dialog-actions
    align="end"
>
    <button
        mat-button
        mat-dialog-close
    >
        Cancelar
    </button>
    <button
        mat-button
        color="primary"
        cdkFocusInitial
        (click)="onPrint()"
        [disabled]="!canPrint"
    >
        Imprimir
    </button>
</div>

<ng-template #elseTemplate>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
