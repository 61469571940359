import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatTable } from "@angular/material/table";
import { Observable, Subscription, combineLatest } from "rxjs";
import { getAdminPrintLabels } from "src/app/admin/orders/order/products/print-group-info/print-group-info.component";
import { PageSize, Settings, getPrintGroupJobType } from "src/app/models/models";
import { PrintProduct } from "src/app/models/printProduct.model";
import { PrintJob } from "src/app/models/print-job.model";
import { FormControl } from "@angular/forms";
import { uniq, map as _map } from "lodash";
import { startWith, map } from "rxjs/operators";

@Component({
  selector: "app-print-partial-groups",
  templateUrl: "./print-partial-groups.component.html",
  styleUrls: ["./print-partial-groups.component.scss"],
})
export class PrintPartialGroupsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() printProducts: Array<PrintProduct> = [];
  @Input() exceptionCoverLaminated: FormControl;
  @Output() changes = new EventEmitter<any>();
  @ViewChild(MatTable) tableData: MatTable<any>;
  groupData: Array<PartialPrintGroupData> = [];
  groupDataFiltered: Array<PartialPrintGroupData> = [];
  emitSubscription: Subscription;
  pageSizeValues = [];

  groupDataFiltered$: Observable<Array<PartialPrintGroupData>>;


  constructor() { }
  displayedColumns: string[] = ["select", "info"];
  selection = new SelectionModel<any>(true, []);
  selectedPageSize = new FormControl(null)

  ngOnInit(): void {

    this.emitSubscription = combineLatest([
      this.selection.changed.pipe(startWith(this.selection.selected)),
      this.selectedPageSize.valueChanges,
    ]).subscribe(_ => {
      // console.log('EMIT', this.selection.selected.length, this.selectedPageSize.value, `has value: ${this.selection.hasValue()}`);
      this.changes.emit({
        type: 'selected',
        payload: {
          selected: this.selection.selected,
          pageSize: this.selectedPageSize.value,
        }
      });
    });


    this.groupDataFiltered$ = combineLatest([
      this.exceptionCoverLaminated.valueChanges.pipe(startWith(this.exceptionCoverLaminated.value)),
      this.selectedPageSize.valueChanges,
    ]).pipe(
      map(([exceptionCoverLaminated, selectedPageSize]) => {
        // console.log('CLEAR', this.selection.selected.length, this.selectedPageSize.value, `has value: ${this.selection.hasValue()}`);
        this.groupDataFiltered = this.groupData
          .filter(gd => gd.size == selectedPageSize)
          .filter(e => (!exceptionCoverLaminated || e.printProduct.printingGroup.printSettings[Settings.COVER_LAMINATED]));

        this.selection.clear();
        return this.groupDataFiltered;
      }),
    );

  }

  ngOnChanges({ printProducts: _printProducts }: SimpleChanges): void {
    if (_printProducts && _printProducts.currentValue) {
      const printProducts = _printProducts.currentValue;
      this.groupData = mapGroupData(printProducts);
      this.pageSizeValues = uniq(_map(this.groupData, 'size'));
      this.selectedPageSize.patchValue(this.pageSizeValues[0]);
    }
  }

  ngOnDestroy(): void {
    this.emitSubscription.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.groupDataFiltered.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.groupDataFiltered);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }



  peloticaColor(type: PrintJob.Type) {
    const colorDef = {
      [PrintJob.Type.COLOR]: "roja",
      [PrintJob.Type.BN]: "negra",
      [PrintJob.Type.MIXED]: "verde"
    }
    return colorDef[type]

  }
}


export function mapGroupData(printProducts: Array<PrintProduct>): PartialPrintGroupData[] {
  return printProducts?.length
    ? printProducts.map((printProduct, index) => {
      const printingGroup = printProduct.printingGroup;
      return {
        id: index,
        name: "G" + (index + 1),
        docs: printingGroup.files.length,
        pages: printingGroup.files.reduce((pages, file) => pages + file.pages, 0),
        printLabels: getAdminPrintLabels(printingGroup, true),
        type: getPrintGroupJobType(printingGroup),
        size: printingGroup.printSettings[Settings.PAGE_SIZE],
        printProduct
      };
    })
    : [];
}

export interface PartialPrintGroupData {
  id: number,
  name: string,
  docs: number,
  pages: number,
  printLabels: Array<any>,
  type: PrintJob.Type,
  size: PageSize,
  printProduct: PrintProduct,
}

