<button [matTooltip]="'Opciones'" mat-button class="button-menu" [matMenuTriggerFor]="menu">
  <mat-icon class="menu-vertical">more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="changePassword()">
    <mat-icon>lock</mat-icon>
    <span>{{ "HEADER.CHANGE_PASSWORD" | translate }}</span>
  </button>

  <button mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ "HEADER.LOGOUT" | translate }}</span>
  </button>
</mat-menu>