import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import * as Rollbar from "rollbar";
import { environment } from "src/environments/environment";

const rollbarConfig: Rollbar.Configuration = {
  accessToken: environment.rollbar.accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureEmail: true,
  captureIp: true,
  captureUsername: true,
  code_version: environment.version,
  environment: environment.production ? "production" : "development",
  enabled: environment.production ? true : true,
  ignoredMessages: ["Script error."],
};

export const RollbarService = new InjectionToken<Rollbar>("rollbar");

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {
    this.rollbar.configure({
      version: environment.version,
    });
  }

  addRollbarPerson(user) {
    this.rollbar.configure({
      payload: {
        person: {
          uid: user.uid,
          email: user.email
        }
      }
    })
  }

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }

  info(msg: any): void{
    this.rollbar.info(msg)
  }
  warn(msg:any):void{
    this.rollbar.warning(msg)
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
