<mat-toolbar class="stickyBar" fxLayout="row">
  <ng-container *ngIf="!!user">

    <button [matTooltip]="'Ir Atrás'" *ngIf="previousNav" mat-icon-button aria-label="Back Button" (click)="back()">
      <mat-icon>arrow_back</mat-icon>
      <!-- {{ "HEADER.BACK" | translate }} -->
    </button>
    <!-- TODO, revisar como añadirlo con la diferencias de coupon ? -->
    <!-- <button class="back-button" mat-icon-button (click)="location.back()" aria-label="Go Back">
        <mat-icon>arrow_back</mat-icon>
        {{ "HEADER.BACK" | translate }}
      </button> -->


    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

      <span>{{title}}</span>

      <div class="profile" fxLayoutAlign="center center">
        <img [matTooltip]="'Eres tú!'" *ngIf="user.photoURL" [src]="user.photoURL" width="36px" height="36px">
        <mat-icon *ngIf="!user.photoURL">account_circle</mat-icon>
        &nbsp;
        <div fxLayout="column" fxLayoutAlign="center end">
          <div>
            {{user.displayName}}
          </div>
          <span *ngIf="!(isNewVersionAvailable$ | async)" class="version-header">
            {{version}}
          </span>
          <span *ngIf="isNewVersionAvailable$ | async" class="version-header link" (click)="reload()">
            Nueva versión disponible
          </span>
        </div>

        <app-vertical-menu></app-vertical-menu>
      </div>
    </div>

  </ng-container>
</mat-toolbar>