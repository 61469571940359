import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PrintWorkSelected } from '../../print-partial-dialog.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { PrintJob } from 'src/app/models/print-job.model';
import { WorkFile } from '../../print-partial-files/print-partial-files.component';

@Component({
  selector: 'app-print-type-selection',
  templateUrl: './print-type-selection.component.html',
  styleUrls: ['./print-type-selection.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintTypeSelectionComponent implements OnInit, OnDestroy {
  @Input() printWorksSelected: Array<PrintWorkSelected> = [];
  @Input() printFilesSelected: Array<WorkFile> = [];
  @Input() selectedMode: 'groups' | 'files'
  @Input() hasReport: boolean = false;
  @Input() exceptionCoverLaminated: boolean;
  @Output() changes = new EventEmitter<any>();

  subscription: Subscription;
  printTypeSelected = new FormControl(null);
  printWorksType = new BehaviorSubject(null);

  constructor() { }

  ngOnInit(): void {
    this.subscription = this.printTypeSelected.valueChanges.subscribe(pt => {
      // console.log('==> EMIT: ', pt);
      this.changes.emit({ type: 'update', payload: { printTypeSelected: pt } });
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  filteredTypes$ = this.printWorksType.pipe(map(pwt => {
    // console.log('==> SUBJECT: ', pwt);
    const filteredTypes: Array<{ key: PrintType, label: string }> = [PrintJob.Type.COLOR, PrintJob.Type.BN].includes(pwt)
      ? PRINT_TYPES.filter(pt => pt.key == pwt)
      : pwt == 'RP'
        ? PRINT_TYPES.filter(pt => [PrintJob.Type.BN, PrintJob.Type.COLOR].includes(pt.key as any))
        : pwt ? PRINT_TYPES : [];

    // console.log('filteredTypes: ', JSON.stringify(filteredTypes));
    // console.log('current printTypeSelected: ', this.printTypeSelected.value);
    if (!filteredTypes.length) {
      this.printTypeSelected.patchValue(null);
    } else if (!filteredTypes.find(ft => ft.key == this.printTypeSelected.value)) {
      // assign new printTypeSelected if current assigned value is not found in new filteredTypes
      // (i.e.: keep previous selection if possible)
      if (pwt === PrintJob.Type.MIXED
        && (!this.printTypeSelected.value || [PrintJob.Type.BN, PrintJob.Type.COLOR].includes(this.printTypeSelected.value))
      ) {
        this.printTypeSelected.patchValue('BC');
      } else if (!filteredTypes.find(pt => pt.key === this.printTypeSelected.value)) {
        this.printTypeSelected.patchValue(filteredTypes[0].key);
      }
    }

    return filteredTypes;
  }))

  ngOnChanges({
    printWorksSelected: _printWorksSelected,
    printFilesSelected: _printFilesSelected,
    selectedMode: _selectedMode,
  }: SimpleChanges): void {
    // console.log('==> INPUT: ');
    const printWorksSelected = _printWorksSelected?.currentValue ?? this.printWorksSelected;
    const printFilesSelected = _printFilesSelected?.currentValue ?? this.printFilesSelected;
    const selectedMode = _selectedMode?.currentValue ?? this.selectedMode;

    if (selectedMode) {
      this.setPrintType(selectedMode == 'groups' ? printWorksSelected : printFilesSelected)
    }
  }

  setPrintType(printWorksSelected: Array<PrintWorkSelected>) {
    const printWorksType = getAggregatedPrintJobType(printWorksSelected, this.hasReport, this.exceptionCoverLaminated);
    // console.log('setPrintType: ', printWorksType);
    this.printWorksType.next(printWorksType);
  }

}

const PRINT_TYPES: Array<{ key: PrintType, label: string }> = [
  { key: PrintJob.Type.BN, label: 'Solo negro' },
  { key: PrintJob.Type.COLOR, label: 'Solo color' },
  { key: 'BC', label: 'Negro y color separados' },
  { key: PrintJob.Type.MIXED, label: 'Impresión mixta' },
]

export type PrintType = PrintJob.Type.BN | PrintJob.Type.COLOR | 'BC' | PrintJob.Type.MIXED;


// para los grupos o archivos seleccionados:
function getAggregatedPrintJobType(printJobTypes = [], hasReport: boolean, exceptionCoverLaminated: boolean) {
  let printJobType: any = printJobTypes?.length ? PrintJob.Type.MIXED : null;

  if (exceptionCoverLaminated) {
    printJobType = PrintJob.Type.COLOR;
  } else if (printJobTypes?.length) {
    if (printJobTypes.every(e => e.type == PrintJob.Type.COLOR)) {
      printJobType = PrintJob.Type.COLOR;
    } else if (printJobTypes.every(e => e.type == PrintJob.Type.BN)) {
      printJobType = PrintJob.Type.BN;
    } // else, mixed
  } else if (hasReport) {
    printJobType = "RP"
  }
  return printJobType;
}
