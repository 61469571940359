import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { UploadTask } from '@angular/fire/storage/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileDbService } from './file-db.service';

export interface UploadState {
  state: "running" | "success" | "canceled" | "error" | "paused" | "inactive" | 'saved';
  progress: number;
  file: any;
}

@Injectable(
)
export class FileUploadService {
  file: any;
  // file: FileModel;
  _uploadState = new BehaviorSubject<UploadState>({ state: "inactive", progress: 0, file: null })
  uploadState$ = this._uploadState.asObservable();
  //  new Observable<UploadState>();
  task: AngularFireUploadTask;
  hasError = false;

  constructor(
    private fileDbService: FileDbService,
    private storage: AngularFireStorage
  ) {
    // console.log("Pobando 1")
  }

  init() {
    // console.log("Subiendo archivo");
  }

  uploadFile(file2Upload, basePath, collection) {
    const fileId = this.fileDbService.createId();
    const path = `${basePath}/${fileId}/${file2Upload.name}`;
    const ref = this.storage.ref(path);

    //se crea el archivo base
    const file = this.newFile({
      path,
      name: file2Upload.name,
      size: file2Upload.size,
      version: 0,
      contentType: file2Upload.type,
      // ...this.reference
    });

    this.task = ref.put(file2Upload);
    this.task.snapshotChanges()
      .pipe(map(s => {
          // console.log("@@@@@ssss:", s);
          return {
            state: s.state,
            progress: (s.bytesTransferred / s.totalBytes) * 100,
            file
          } as UploadState
        })
      ).subscribe(val => this._uploadState.next(val))


    if (collection) {
      this.task
        .then(res => res.ref.getDownloadURL())
        .then(url => {
          file.url = url;
          return this.fileDbService.create(fileId, file, collection);
        })
        .then(_ => {
          // console.log("files uploades");
          //lo eliminamos de la lista ya que aparecerá por donde está suscrito
          //this.remove();
          this.fileSaved(file);

        })
        .catch(err => {
          this.hasError = true;
          console.error("Upload File Error", err);
        })
    }

  }

  newFile(base) {
    const file: any = { ...base };
    // const file: FileModel = {...base};
    file.payload = {};
    // file.iconUrl = FileUtils.icon(file.contentType);
    return file;
  }

  fileSaved(file) {
    this._uploadState.next({
      state: "saved",
      progress: 99,
      file
    })
  }

  cancelUpload() {
    const currentState = this._uploadState.value;
    this._uploadState.next({ ...currentState, state: "canceled" })
    this.task.cancel();
    // this.deleteFile.emit(this.file2Upload)
  }

}
