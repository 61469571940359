import { BehaviorSubject, merge, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { A2filter } from '@a2system/angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { OrderService } from 'src/app/shared/order.service';
import { Order, OrderGroupPrintStatus, PrintColor } from 'src/app/models/models';
import { ImpresionContentFilterType, ImpresionService } from '../../impresion.service';
import { BundleStatus, PrintBundle, PrintRequirement, RequirementStatus } from 'src/app/models/printer-model';
import { flatMap } from 'lodash';

export class PendingOrdersTableDataSource extends MatTableDataSource<any> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  // public paginator: MatPaginator;
  // public sort: MatSort;

  public count = 0;

  public a2filter: A2filter;


  constructor(
    public dataService: OrderService,
    private impresionService: ImpresionService,
    // private pageSizeA4Only: boolean,
    private impresionContentFilterType: ImpresionContentFilterType,
  ) {
    super();
  }

  connect(): any {// se puso any de momento pero es Observable<any[]>

    //nos permite saber si algo cambió
    const dataMutations = [
      this.paginator.page,
      this.sort.sortChange,
      this.a2filter.filterChange
    ];

    const orders$ = merge(...dataMutations).pipe(
      switchMap((qry) => {
        this.loadingSubject.next(true);
        return this.dataService.queryPendingJobs(this.paginator, this.sort, this.a2filter.filters, this.impresionContentFilterType)
          .then(response => {
            // console.log(`A =========>>>> queryPendingJobs`);
            this.count = response._meta.count;
            this.paginator.length = response._meta.count;
            //Actualizamos la cuenta d epágina
            this.data = response.data;
            this.loadingSubject.next(false);
            return this.data;
          })
          .catch(err => {
            this.loadingSubject.next(false);
            console.error("____Order data Source Fail", err)
          })
      }),
    );

    return combineLatest([orders$, this.impresionService.printerRequirements$]).pipe(
      map(([orders, printerRequirements]: [Array<any>, Array<PrintRequirement>]) => {
        // overwrite remote ES printStatusGroups with local printStatusGroups
        // calculated from local printerRequirements.bundles[]
        // console.log(`B =========>>>> combineLatest triggered: ${printerRequirements.length} elements`);
        return orders.map((order: Order) => {
          // for each order, fake 2 things:

          // 1.- isProcessing: if any pending requirement for this order, enable isProcessing
          //     while we wait for server to update the requirement to processing.
          const _printerRequirements = printerRequirements?.filter(e => e.order.id === order.id) ?? [];
          let isProcessing = _printerRequirements.some(e => e.status == RequirementStatus.PENDING);

          // _printerRequirements.forEach(e => {
          //   console.log(`Req: ${e.status} Bundles: ${e.bundles.map(b=>b.status)}, Ids: ${e.bundles.map(b=>b.id)}`);
          // });

          // 2.- printStatusGroups: overwrite each group status according to its requirements,
          //     while waiting for ES to properly refresh this order.
          const printStatusGroups = order.printStatusGroups ?? [];
          const bundles = flatMap(_printerRequirements.map(e => e.bundles)) as Array<PrintBundle>;
          // console.log(`printStatusGroups: ${JSON.stringify(printStatusGroups, null, 2)}`);
          bundles?.forEach(bundle => {
            const group = printStatusGroups.find(e => e.bundleId == bundle.id);
            if (group) {
              // const oldStatus = group.status;
              group.status = this._mapBundleToPrintStatus(bundle.status);
              // console.log(`C =========>>>> new status: ${oldStatus} -> ${group.status}`);
            } else {
              // 1.- if requirement is already processing, isProcessing will be false.
              // mark it as true if there's a bundle being processed that is not found yet in printStatusGroups
              // (printStatusGroups[].bundleId is missing until ES updates)
              isProcessing = true;
            }
          });
          // 3.- calculate bn to color ratio (in %):
          let bnToColorPct = 0;
          if (order.contains?.includes('M')) {
            const sidesByColor = { [PrintColor.BLACKNWHITE]: 0, [PrintColor.COLOR]: 0 };
            order.printStatusGroups?.forEach(e => sidesByColor[e.color] += e.sideCount);
            const _bn = sidesByColor[PrintColor.BLACKNWHITE];
            const _color = sidesByColor[PrintColor.COLOR];
            bnToColorPct = (100 * _bn) / (_bn + _color);
          }


          // console.log(`=========>>>> BUNDLES: ${JSON.stringify(bundles)}`);
          // console.log(`=========>>>> PRINTSTATUSGROUPS: ${JSON.stringify(printStatusGroups)}`);
          // console.log(`=========>>>> BN TO COLOR RATIO: ${bnToColorPct}`);

          // 4.- others...
          // hide unwanted contains letters
          if (order.contains?.includes('E') && (order.contains?.includes('Ex') || order.contains?.includes('Ec'))) {
            order.contains = order.contains.filter(e => e != 'E');
          }

          return {
            ...order,
            isProcessing: isProcessing,
            printStatusGroups: [...printStatusGroups], // re-create array for print status group cell component to update
            bnToColorPct: bnToColorPct,
          };

        });
      })
    );


  }
  _updatePaginator(data) {
    return this.count;
  }
  disconnect() {
    this.loadingSubject.complete();
  };

  _mapBundleToPrintStatus(bundleStatus: BundleStatus = BundleStatus.PENDING) {
    switch (bundleStatus) {
      case BundleStatus.PENDING:
        return OrderGroupPrintStatus.PENDING;
      case BundleStatus.QUEUED_PROCESS:
      case BundleStatus.PREPROCESSING:
      case BundleStatus.QUEUED_PRINT:
      case BundleStatus.PRINTING:
        return OrderGroupPrintStatus.PROCESSING;
      case BundleStatus.WARNING:
      case BundleStatus.FAILED:
        return OrderGroupPrintStatus.ERROR;
      case BundleStatus.FINISHED:
        return OrderGroupPrintStatus.FINISHED;
      default:
        return OrderGroupPrintStatus.PENDING;
    }
  }
}
