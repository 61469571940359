<!-- <div *ngIf="user" fxLayout="column" class="full-size"> -->
<div fxLayout="column" class="full-size">
  
  <!-- <app-header-toolbar [title]="userId ? ('Usuario ' + user.displayName) : 'Nuevo Usuario'"></app-header-toolbar> -->
  <app-header-toolbar  [previousNav]="true"[title]="('HEADER.BACK' | translate)"></app-header-toolbar>
 
  <div class="container-boxed" fxFlex fxLayout="row">
    <div fxFlex class="card-container">
      <mat-card  fxLayout="column">
        <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" >
          <mat-card-title>{{ userId ? ('Usuario ' + user.displayName)   : 'Nuevo Usuario'}}</mat-card-title>
        </mat-card-header>     
        <mat-card-content  fxFlex>
          <form [formGroup]="formUser" (submit)="onSubmit()" id="idformUserAdmin" fxLayout="column" fxLayoutAlign="start stretch">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre" type="text" formControlName="firstName" #focus required/>
  
                <mat-error *ngIf="formUser.get('firstName').invalid">
                  <mat-icon>info</mat-icon>
                  {{ ui.getErrorMessage( formUser.get("firstName"), validationMessages ) | translate }}
                </mat-error>
              </mat-form-field>
  
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Apellido</mat-label>
                <input matInput placeholder="Apellido" type="text" formControlName="lastName" required/>
  
                <mat-error *ngIf="formUser.get('lastName').invalid">
                  <mat-icon>info</mat-icon>
                  {{ ui.getErrorMessage( formUser.get("lastName"), validationMessages ) | translate }}
                </mat-error>
              </mat-form-field>
            </div>
  
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" type="text" formControlName="email" [readonly]="userId !== undefined"/>
  
                <mat-error *ngIf="formUser.get('email').invalid">
                  <mat-icon>info</mat-icon>
                  {{ ui.getErrorMessage( formUser.get("email"), validationMessages ) | translate }}
                </mat-error>
              </mat-form-field>
  
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Rol</mat-label>
                <mat-select formControlName="role" placeholder="Rol">
                  <mat-option *ngFor="let role of roleList" [value]="role">{{ ('ROLE.' + role) | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
<!--   
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <button mat-raised-button color="warn" *ngIf="!formUser.get('disabled').value" (click)="formUser.get('disabled').setValue(true)">Desactivar</button>
              <button mat-raised-button color="primary" *ngIf="formUser.get('disabled').value" (click)="formUser.get('disabled').setValue(false)">Activar</button>
            </div> -->
          </form>
        </mat-card-content>
  
        <mat-card-actions fxLayoutAlign="end center">
          <button *ngIf="!isNew" mat-raised-button  [color]="isDisabled() ? 'primary' : 'warn'"  (click)="setEnableDisable()">{{isDisabled() ? 'Activar' :'Desactivar'}}</button>
          &nbsp;
          <button mat-raised-button type="button" (click)="onCancel()">Cancelar</button>
          <button mat-raised-button color="primary" type="submit" form="idformUserAdmin">{{userId ? "Guardar" : "Crear"}}</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>