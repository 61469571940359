<form [formGroup]="supportForm" id="idFormSupport" (submit)="createClickupTask();" fxLayout="column"
      fxLayoutAlign="center space-between">
  <mat-form-field appearance="outline">
    <mat-label>Título</mat-label>
    <input id="titleInput" type="text" matInput formControlName="title">
    <mat-error *ngIf="supportForm.get('title').invalid">
      <mat-icon>info</mat-icon>
      {{ ui.getErrorMessage(supportForm.get('title'), validationMessages) | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Descripción</mat-label>
    <textarea matInput formControlName="description"></textarea>
    <mat-error *ngIf="supportForm.get('description').invalid">
      <mat-icon>info</mat-icon>
      {{ ui.getErrorMessage(supportForm.get('description'), validationMessages) | translate }}
    </mat-error>
  </mat-form-field>
  <button type="button" mat-raised-button (click)="fileInput.click()">Adjuntar archivos (opcional)</button>
  <input hidden (change)="onFileSelected($event)" #fileInput type="file" multiple>
  <div *ngIf="attachments.length > 0">
    <ul>
      <li *ngFor="let attachment of attachments">
        {{ attachment.file.name }}
      </li>
    </ul>
  </div>
  <div *ngIf="attachments.length !== filesUploadingCount" class="flex flex-row items-center justify-center mt-7">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  <button mat-raised-button color="primary" type="submit"
          [disabled]="!supportForm.valid || attachments.length !== filesUploadingCount">Enviar
  </button>


</form>
