/**
 * Componente que sirve de Adapter para hacer Búsquedas en Elastic Search
 * Version: 2020-05-11
 * Cambios:
 *  2020-05-11 Se coloca la opcion para ir directamente a es
 *  2020-04-14 Se im implemntó el uso de Extras para hacer búsquedas con WildCard y Nested
 */


import { Injectable, NgZone } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { auth } from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { RangeDate } from '../admin/printers/printers-activity-table/printers-activity-table.component';
import {
  differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarQuarters,
  differenceInCalendarWeeks, differenceInCalendarYears, format, subMonths,
} from 'date-fns';
import * as moment from 'moment';

export interface CalendarDateRange {
  startDate: Date;
  endDate: Date;
};
export type CalendarInterval = 'day' | 'week' | 'month' | 'quarter' | 'year'; // 'minute' | 'hour' |
export interface CalendarIntervalData {
  startDate: string;
  endDate: string;
  interval: CalendarInterval;
  unit: string;
  format: string;
  extendedBounds: {
    min: string;
    max: string;
  };
};

@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {
  private url = environment.elasticSearch.url;

  constructor(
    public zone: NgZone,
    private functions: AngularFireFunctions,
    private httpClient: HttpClient
  ) {
  }

  rq(path, body) {
    // console.log(body);
    //FIXME: Aparentemente despues de hibernar tarda en obtener el currentUser debemos colocar un spinner.
    if (auth().currentUser) {
      return auth().currentUser.getIdToken(/* forceRefresh */ false).then(idToken => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${idToken}`,
          'project-id': `${environment.firebaseConfig.projectId}`
        });
        return this.httpClient.request('POST', this.url + path, { headers, body }).toPromise();
      })
    } else {
      return Promise.reject(new Error('fail-invalid currentUser'));
    }
  }

  a2f(body) {
    return this.rq('search', body);
  }

  getDashboard(paths, terms) {
    const arrQry = [];
    paths.forEach(path => arrQry.push(this.rq(path, terms)));
    return Promise.all(arrQry);
  }

  /**
   * Buscar por Indice en Elastic Search
   * @param index Indice de la "colección"
   * @param body Parámetros de Busqueda en formato ElasticSearch
   * @param serialize (Opcional) Serializa los datos que llegan de elastic search y transforma a como lo necesitamos.

   */
  query(index, body, serialize?): Promise<any> {
    // console.log("Index: ", index);
    // console.log("body: ", body);
    if (!index || !body) Promise.reject("mu mal");
    // return this.fn({index, body}).toPromise()
    return this.a2f({ index, body })
      .then((result: any) => {
        // console.log("Result", result);
        let response = result;
        let data = response.hits.hits.map(item => {
          const id = item._id;
          //Serializamos por si en la consulta hay datos que transformar, lo hará cada responsable
          let source = serialize ? serialize(item._source) : item._source;
          return { id, ...source };
        })
        let _meta = {
          count: response.hits.total.value
        }
        return { data, _meta }
      })
      .catch(err => console.error("Es error: ", err));
  }

  //helper para convertir los datos que recibimos de forma estandar desde MatTable, quien use es debe llamar
  //Esta función para hacer el cambio
  // Ejemplo de Extras
  // let extras = {
  //   vehicles_idType:{
  //     nested:"vehicles"
  //   },
  //   vehicles_year:{
  //     nested:"vehicles"
  //   },
  //   vehicles_color:{
  //     nested:"vehicles"
  //   },
  //   vehicles_idBrand:{
  //     nested:"vehicles"
  //   },
  //   vehicles_numberPlate:{
  //     nested:"vehicles",
  //     type:"wildcard"
  //   }
  // }
  mtToBody(_paginator, _sort, _filter, extras?) {
    let from = _paginator.pageIndex * _paginator.pageSize;
    let size = _paginator.pageSize;
    let queryTerm = this._getFilter(_filter, extras);
    //Armamos el Sort
    let sort = {}
    if (_sort.active && _sort.direction.length) {
      let index = _sort.active;
      // console.log("Index Sort", index);
      sort = [{
        [index]: { "order": _sort.direction }
      }]
    } else {
      sort = {} //Default
    }
    let query = {
      //"match_all": {}
      bool: queryTerm
    }
    return { query, from, size, sort }
  }

  agg(index, body): Promise<any> {
    // return this.fn2({index, body}).toPromise()
    return this.a2f({ index, body })
      .then((result: any) => {
        // console.log("agg", result);
        return result.aggregations.activationGroups.buckets;
      })
  }

  //////////////////////////////////
  //////////// private zone... utilities:
  private timeZone = 'Europe/Madrid';
  private inputDateFormat = 'yyyy-MM-dd';

  private _suggestInterval(range: CalendarDateRange): CalendarInterval {
    const minDiff = 6; // >= 6 will generate at least 6 buckets
    let interval: CalendarInterval = 'day'; // no shorter period allowed

    if (differenceInCalendarYears(range.endDate, range.startDate) >= minDiff) {
      interval = 'year';
    } else if (differenceInCalendarQuarters(range.endDate, range.startDate) >= minDiff) {
      interval = 'quarter';
    } else if (differenceInCalendarMonths(range.endDate, range.startDate) >= minDiff) {
      interval = 'month';
    } else if (differenceInCalendarWeeks(range.endDate, range.startDate) >= minDiff) {
      interval = 'week';
    } else if (differenceInCalendarDays(range.endDate, range.startDate) >= minDiff) {
      interval = 'day';
    }

    return interval;
  }

  private _mapCalendarInterval = (range: CalendarDateRange, interval?: CalendarInterval): CalendarIntervalData => {
    interval ??= this._suggestInterval(range);
    const unitInterval: CalendarInterval = interval == 'quarter' ? 'month' : interval; // not quite correct... shoould round to quarter
    const unit = unitInterval == 'month'
      ? unitInterval.substring(0, 1).toUpperCase()
      : unitInterval.substring(0, 1);
    // ES date format:
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.17/search-aggregations-bucket-daterange-aggregation.html#date-format-pattern
    // date-fns format:
    // https://date-fns.org/v2.29.2/docs/format#description
    let _format = 'dd-MM-yyyy'; // output format: day, week
    // let format = 'yyyy-MM-dd'; // output format: day, week
    switch (interval) {
      // avoid hour, minute for now...
      // we'd need to handle timezone properly on formatDate,
      // plus 'format' could not be used in 'extendedBounds' as dd H,
      // case 'minute': format = 'yyyy-MM-dd hh mm'; break;
      // case 'hour': format = "dd H 'h'"; break;
      // case 'hour': format = "yyyy-MM-dd H 'h'"; break;
      case 'month':
        _format = 'MM-yyyy';
        break;
      // case 'month': format = 'yyyy-MM'; break;
      case 'quarter':
        _format = 'QQQ-yyyy';
        break;
      case 'year':
        _format = 'yyyy';
        break;
    }
    const extendedBounds = {
      min: format(range.startDate, _format), // tz needed here...
      max: format(range.endDate, _format),
    };
    return {
      startDate: format(range.startDate, this.inputDateFormat),
      endDate: format(range.endDate, this.inputDateFormat),
      interval, unit, format: _format, extendedBounds,
    };
  }

  private _toDateRangeFilter(field: string, calendarIntervalData: CalendarIntervalData) {
    return {
      range: {
        [field + '._seconds']: {
          'format': this.inputDateFormat, // from local calendar. keep as it is.
          'time_zone': this.timeZone,
          'gte': `${calendarIntervalData.startDate}||/1${calendarIntervalData.unit}`,
          'lte': `${calendarIntervalData.endDate}||/1${calendarIntervalData.unit}`
        }
      }
    };
  }

  private _toDateHistogramAggr(field: string, calendarIntervalData: CalendarIntervalData) {
    return {
      field: field + '._seconds',
      calendar_interval: calendarIntervalData.interval,
      format: calendarIntervalData.format,
      time_zone: this.timeZone,
      extended_bounds: calendarIntervalData.extendedBounds,
    };
  }

  private _suggestedDateRange(min?: number, max?: number): CalendarDateRange {
    let dateRange = { startDate: null, endDate: null } as CalendarDateRange; // now - 5 months = 6 months

    if (min && max) {
      dateRange.startDate = new Date(min);
      dateRange.endDate = new Date(max);
      if (differenceInCalendarMonths(dateRange.endDate, dateRange.startDate) < 5) {
        dateRange.startDate = subMonths(dateRange.endDate, 5);
      }
    } else {
      // now - 5 months = 6 month range
      dateRange.startDate = subMonths(new Date(), 5);
      dateRange.endDate = new Date();
    }
    // console.log('SUGGESTED DATE RANGE:', min, max, dateRange);
    return dateRange;
  }

  ////////////////////////// end private zone: utils
  ///////////////////////////////////////////////////////////////////////

  //Esto transforma el a2filter a filter es
  private _getFilter(filters, extras?) {
    const query = {
      must: [],
      filter: [],
      must_not: []
    }
    Object.keys(filters).forEach(_key => {
      const type = (extras && extras[_key] && extras[_key].type) ? extras[_key].type : 'term';
      const queryTerm = {
        must: [],
        filter: [],
        must_not: []
      }
      filters[_key].forEach(filter => {
        let values = Array.isArray(filter.value) ? filter.value : [filter.value];
        let op = filter.op;
        let key = filter.key;
        let queryOption = op == '!==' ? 'must_not' : 'must'; // TODO: ===> ESTO ESTÁ MAL, DEBE SER FILTER, NO MUST

        values.forEach(value => {
          switch (type) {
            case 'id':
              queryTerm[queryOption].push(this._getId(key, value));
              break;
            case 'term':
              queryTerm[queryOption].push(this._getTerm(key, value));
              break;
            case 'wildcard':
              queryTerm[queryOption].push(this._getWilcard(key, value));
              break;
            case 'dateRange':
              queryTerm[queryOption].push(this._getDateRange(key, value));
              break;
            case 'search':
              queryTerm[queryOption].push(this._getSearch(value.value, value.terms));
              break;
            case 'text':
              queryOption = op == '!==' ? 'must_not' : 'must';
              queryTerm[queryOption].push(this._getText(key, value));
              break;
          }
        });
      })

      //si cualquiera de los elementso dentro de queryTerm tiene mas de uno se convierte en
      Object.keys(queryTerm).forEach(key => {
        if (queryTerm[key].length > 1) {
          queryTerm[key] = [{
            "bool": {
              "should": queryTerm[key],
              "minimum_should_match": 1
            }
          }]
        }
      })

      //verificamos si es nested
      if (extras && extras[_key] && extras[_key].nested) {
        Object.keys(queryTerm).forEach(key => {
          if (queryTerm[key].length) {
            queryTerm[key] = [{
              nested: {
                path: extras[_key].nested,
                query: {
                  bool: {
                    filter: queryTerm[key]
                  }
                }
              }
            }]
          }
        })
      }
      //Asignamos al query principal los querys de la propiedad al apartado que pertenece
      Object.keys(query).forEach(key => query[key].push(...queryTerm[key]));

    })
    return query;
  }

  private _getSearch(searchValue, searchTerms) {
    const fields = [];
    searchTerms.forEach(term => {
      term.score ? fields.push(`${term.field}^${term.score}`) : fields.push(`${term.field}`);
    });
    return {
      multi_match: {
        query: searchValue,
        fields,
        type: 'most_fields',
        fuzziness: "AUTO"
      }
    };
  }

  private _getWilcard(key, value) {
    return { "wildcard": { [key]: /[*?]/.test(value) ? value : '*' + value + '*' } };
  }

  private _getDateRange(key, value) {
    const _format = 'yyyy-MM-dd'
    const fi = format(moment.isMoment(value.fi) ? value.fi.toDate() : value.fi, _format);
    const fe = format(moment.isMoment(value.fe) ? value.fe.toDate() : value.fe, _format);

    return {
      range: {
        [key + '._seconds']: {
          "format": _format,
          "time_zone": Intl.DateTimeFormat().resolvedOptions().timeZone, //"Europe/Madrid",
          "gte": fi + "||/d",
          "lte": fe + "||/d"
        }
      }
    }
  }

  private _getTerm(key, value) {
    return { "term": { [key]: (typeof value === 'object') ? value.id : value } };
  }

  private _getId(key, value) {
    return { "ids": { values: [(typeof value === 'object') ? value.id : value] } };
    // return  {"id" : {[key] : (typeof value === 'object') ? value.id : value }};
  }

  private _getText(key, value) {
    const match = value.split(" ").length;
    return { "match_bool_prefix": { [key]: { "query": value, "minimum_should_match": match } } };
  }

  _API_call = (path, data = null): Observable<any> => {
    return this.functions.httpsCallable(path)(data);
  }

  _API_search<T>(options: T | Array<T>): Observable<any> {
    const query = Array.isArray(options) ? { queries: options } : options;
    const API_SEARCH = 'api2/search';
    return this._API_call(API_SEARCH, query);
  }

  _toDateRangeFilter_old(field: string, calendarIntervalData: RangeDate) {
    let endDate: moment.Moment = calendarIntervalData.endDate;
    let startDate: moment.Moment = calendarIntervalData.startDate;

    return {
      range: {
        [field]: {
          'format': this.inputDateFormat,
          'time_zone': this.timeZone,
          'gte': `${startDate.format("yyyy-MM-DD")}||/1d`,
          'lte': `${endDate.format("yyyy-MM-DD")}||/1d`
        }
      }
    };
  }

  // public:
  public getFilter = this._getFilter;
  public mapCalendarInterval = this._mapCalendarInterval;
  public toDateRangeFilter = this._toDateRangeFilter;
  public toDateHistogramAggr = this._toDateHistogramAggr;
  public search = this._API_search;

}
