import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'controls'
})
export class ControlsPipe implements PipeTransform {

  /**
   * @param form 
   * @param args 
   * @returns the value of the formControl
   */
  transform(form: FormGroup, args: Array<string>): any {
    let ctrl:any = form.controls;
    args?.forEach(arg => ctrl=ctrl[arg]);
    return ctrl;
  }

}
