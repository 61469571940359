import { Component, OnInit, Input } from '@angular/core';
import { environment } from "../../../environments/environment";
import { Location } from '@angular/common';
import { UserService } from 'src/app/core/user.service';
import { SettingService } from 'src/app/shared/setting.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import { compareVersions } from 'compare-versions';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit {

  //@Input() user: any;
  @Input() title: string;
  @Input() previousNav: any = null;

  user;

  version = environment.version;

  isNewVersionAvailable$ = this.settingService.get('admin').pipe(
    map(adminSettings => compareVersions(adminSettings.currentVersion, this.version) > 0),
    shareReplay(1),
    // tap(_ => console.log('NEW VERSION? ', _)),
  );

  constructor(
    private location: Location,
    private userService: UserService,
    private settingService: SettingService,
    // private router: Router,
  ) {

  }

  ngOnInit(): void {
    // console.log({
    //   prev:this.previousNav,
    //   prevId:this.previousNav?.id,
    //   stateId:this.location.getState(),
    //   r:this.router
    //   // r2: this.router.lastSuccessfulNavigation
    // });



    //this.user = userService.userG
    if (!this.userService.userG) {
      this.userService.user.subscribe((response) => {
        this.user = response;
        // console.log("@@@@@@@",this.user)
      });
    } else {
      this.user = this.userService.userG;
    }
  }

  back(): void {
    this.location.back();
  }

  reload(): void {
    window.location.reload();
  }
}
