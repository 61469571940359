import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/core/user.service';
import { PrinterStatus } from 'src/app/models/printer-model';
import { PrintersService } from 'src/app/shared/printers.service';

@Component({
  selector: 'app-user-printers-dialog',
  templateUrl: './user-printers-dialog.component.html',
  styleUrls: ['./user-printers-dialog.component.scss'],
})
export class UserPrintersDialogComponent implements OnInit {
  availablePrinters$: Observable<Array<PrinterStatus>>;
  userId: string;
  constructor(
    public dialogRef: MatDialogRef<UserPrintersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { availablePrinters$: Observable<Array<PrinterStatus>> },
    private userService: UserService,
    private printersService: PrintersService
  ) {

  }

  ngOnInit(): void {
    this.availablePrinters$ = this.data.availablePrinters$; // sorted already
    this.userId = this.userService.userG.id;
  }

  onChange(event, printer) {
    // console.log("Cambios", event.checked)
    // console.log("printer", printer.deviceId)
    this.printersService.setUserPrinter({ userId: this.userId, printerId: printer.deviceId, isAssigned: event.checked })
  }

  trackByItems(index: number, item: any) {
    return item.id;
  }
}
