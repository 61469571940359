// BEWARE!!!
// This is the old PrintJob (automation 1.0).
// Not used anymore.
// New model is PrintJob2 (not used in admin) ==> now it is

import { UserModel } from "../core/user.model";
import { StoredFile } from "./models";
import { Printer } from "./printer-model";

export interface PrintJob {
  id?: String;
  type: PrintJob.Type; // BN, Color, Covers
  status: PrintJob.Status; // print job status: initial: pending
  jobAttributes: PrintJob.Attributes;
  files?: Array<PrintJob.File>;  // simplified file data. required on PrintJob creation
  orderId: String; // order id
  printGroupIndex: number; // 0-based print group index
  queueOrder: number; // 0-based print order withing the same createdAt timestamp
  printer: Printer.Reference; // recommended printer for this job. empty if none found
  jobUri?: String; // set by server
  errorMessage?: String; // only in case status = aborted? show if exists...
  response?: any;  
  unsupportedAttributesTag?:any; // response in backend
  user?:UserModel.Reference


  bundleId: string;
}

export namespace PrintJob {
  export function newPrintJob(printJob): PrintJob{
    return {
      type: null,
      status: PrintJob.Status.PENDING,
      jobAttributes: null,
      files: [],
      orderId: null,
      printGroupIndex: null,
      queueOrder: 0, // always 0 from admin (so far...)
      printer: null,
      ...printJob
    }
  }
  export enum Type {
    BN = 'bn',
    COLOR = 'color',
    MIXED = 'mixed',
    COVER = 'cover', // (server lo usa. admin solo para mostrar, no para enviar)
  }

  export enum Status { // this is also used in PrintJob2 model, since it's actually an IPP status
    PENDING = 'pending',
    PENDING_HELD = 'pending-held',
    PROCESSING = 'processing',
    PROCESSING_STOPPED = 'processing-stopped',
    CANCELED = 'canceled',
    ABORTED = 'aborted',
    COMPLETED = 'completed',
  }
  export enum QueueStatus {
    PENDING= 'pending', // pending
    QUEUED= 'queued', // pending
    INPROGRESS= 'inprogress', // pending, pending-held, processing, processing-stopped
    FINISHED= 'finished', // canceled, aborted, completed
  }

  export interface Working {
    type: PrintJob.Type; // BN, Color, Covers
    printer?: Printer.ReferenceSettings; // recommended printer for this job. empty if none found
    printersAlt: Array<Printer.ReferenceSettings>; // alternative printers for this job. May be empty
    jobAttributes: PrintJob.Attributes;  

    status?:PrintJob.Status
  }
  
  export interface File {
    type: PrintJob.Type; // BN, Color, Covers
    selected: boolean; // whether this file will be printed
    originalType: String; // file type  // ==>> ALREADY DEFINED SOMEWHERE ELSE
    originalUrl?: String; // Url pointer with original file
    pages: number; // file total pages
    printFile: StoredFile; // PDF file to be printed // ==>> ALREADY DEFINED SOMEWHERE ELSE
    thumbUrl: String; // URL to thumbnail
  }

  // IPP settings: snake case
  export interface Attributes { // selection from Printer.SettingDefinition
    'copies': number; // items[i].qty
    'print-color-mode': String;
    'sides': String;
    'orientation-requested': String;
    'finishings': Array<String>;
    'media-col': {
      'media-size': any; // object!
      'media-source': String;
      'media-type': String;
    }
  }  
}