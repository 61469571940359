<h1 mat-dialog-title>Cambiar Contraseña</h1>
<div mat-dialog-content class="mat-typography" style="width:300px;">
  <form [formGroup]="matching_passwords_group" id="chgPassword" (ngSubmit)="chgPassword()" fxLayout="column">
    <mat-form-field>
      <mat-label>{{ "CHANGE_PASSWORD.NEW_PASSWORD" | translate }}</mat-label>
      <input appAutoFocus matInput placeholder="{{ 'CHANGE_PASSWORD.NEW_PASSWORD' | translate }}" type="password"  formControlName="password" required>
      <mat-error *ngIf="matching_passwords_group.get('password').invalid "><mat-icon>info</mat-icon> {{ getErrorMessage(matching_passwords_group.get('password')) | translate }}</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field >
      <mat-label>{{ "CHANGE_PASSWORD.VERIFY_PASSWORD" | translate }}</mat-label>
      <input matInput type="password" placeholder="{{ 'CHANGE_PASSWORD.VERIFY_PASSWORD' | translate }}" formControlName="confirm_password" required>
      <mat-error *ngIf="matching_passwords_group.get('confirm_password').invalid"><mat-icon>info</mat-icon>{{ getErrorMessage(matching_passwords_group.get('confirm_password')) | translate }}</mat-error>
      <!-- <mat-error *ngIf="matching_passwords_group.get('confirm_password').invalid || matching_passwords_group.invalid">
        <mat-icon>info</mat-icon>
        {{ matching_passwords_group.get('confirm_password').invalid ? getErrorMessage(matching_passwords_group.get('confirm_password')):"Password mismatch"}}
      </mat-error> -->


      <!-- validationMessages['matching_passwords'] -->
    </mat-form-field>
  </form>
  <span *ngIf="matching_passwords_group.get('password').valid && matching_passwords_group.get('confirm_password').valid && matching_passwords_group.hasError('areNotEqual')" class="mat-error">
    {{ "CHANGE_PASSWORD.PASSWORD_MISMATCH" | translate }}
  </span>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ "CHANGE_PASSWORD.CANCEL" | translate }}</button>
  <button mat-button type="submit" form="chgPassword">{{ "CHANGE_PASSWORD.CHANGE" | translate }}</button>
</div>
