<div class="container">
    <div class="mat-small">Impresoras</div>
    <div fxLayout="column">
        <!-- black printer -->
        <div [fxHide]="!isBlackPrintersVisible" class="p-10">
        
            <mat-select
                #_blackPrinters
                [compareWith]="byId"
                (selectionChange)="onPrinterChange($event)"
                [formControl]="blackPrinter"
            >
                <mat-select-trigger>
                    <div class="pelotica"></div>
                    {{_blackPrinters?.selected?.value.label }}
                </mat-select-trigger>
                <mat-option
                    *ngFor="let printer of printers.blackPrinters$ | async"
                    [value]="printer"
                >
                    <div class="pelotica"></div>
                    {{printer.label}}
                </mat-option>
            </mat-select>
        </div>
        
        <!-- color printer -->
        <div [fxHide]="!isColorPrintersVisible" class="p-10">
        
            <mat-select
                #_colorPrinters
                [compareWith]="byId"
                (selectionChange)="onPrinterChange($event)"
                [formControl]="colorPrinter"
            >
                <mat-select-trigger>
                    <div class="pelotica roja"></div>
                    {{_colorPrinters?.selected?.value.label }}
                </mat-select-trigger>
                <mat-option
                    *ngFor="let printer of printers.colorPrinters$ | async"
                    [value]="printer"
                >
                    <div class="pelotica roja"></div>
                    {{printer.label}}
                </mat-option>
            </mat-select>
        
        </div>
        
        <!-- mixed printer -->
        <div [fxHide]="!isMixedPrintersVisible" class="p-10">
            <mat-select
                #_mixedPrinters
                [compareWith]="byId"
                (selectionChange)="onPrinterChange($event)"
                [formControl]="mixedPrinter"
            >
                <mat-select-trigger>
                    <div class="pelotica verde"></div>
                    {{_mixedPrinters?.selected?.value.label }}
                </mat-select-trigger>
                <mat-option
                    *ngFor="let printer of printers.mixedPrinters$ | async"
                    [value]="printer"
                >
                    <div class="pelotica verde"></div>
                    {{printer.label}}
                </mat-option>
            </mat-select>
        </div>
    </div>
</div>
