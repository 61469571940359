<app-header-toolbar [title]="'Impresión Automatizada ' + (impresionContentFilterType)"></app-header-toolbar>
<div fxLayout="row" class="full-size p-10" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="start start" class="printerCardSection" [ngClass]="{'onlyPrinterCards': onlyPrinterCards}">
        <app-printer-cards [userPrintersStatus]="userPrintersStatus$ | async" [onlyPrinterCards]="onlyPrinterCards"></app-printer-cards>
        <button mat-icon-button [matMenuTriggerFor]="printersMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
    <mat-tab-group *ngIf="!onlyPrinterCards" [ngClass]="{'onlyPrinterCards': onlyPrinterCards}" class="tab-details mat-tab-body-wrapper" fxFlex animationDuration="500ms" selectedIndex="0">
        <!-- Pending -->
        <mat-tab label="Pendiente">
            <ng-template matTabContent>
                <app-pending-jobs [userPrintersStatus$]="userPrintersStatus$" [impresionContentFilterType]="impresionContentFilterType"></app-pending-jobs>
            </ng-template>
        </mat-tab>
        <!-- In Process -->
        <mat-tab>
            <ng-template matTabLabel>
                <!-- [matBadgeHidden]="!(printRequirementsProcess$ | async)?.length" -->
                <span [matBadge]="(printRequirementsProcess$ | async)?.length" matBadgeOverlap="false" matBadgeColor="accent">En proceso</span>
            </ng-template>
            <ng-template matTabContent>
                <app-filtered-requirements
                        [filteredRequirements$]="printRequirementsProcess$"
                        [type]="'process'"
                >
                </app-filtered-requirements>
            </ng-template>
        </mat-tab>
        <!-- Problems -->
        <mat-tab>
            <ng-template matTabLabel>
                <span [matBadge]="(printRequirementsProblem$ | async)?.length" matBadgeOverlap="false" matBadgeColor="warn">Problemas</span>
            </ng-template>
            <ng-template matTabContent>
                <app-filtered-requirements
                        [filteredRequirements$]="printRequirementsProblem$"
                        [type]="'problem'"
                >
                </app-filtered-requirements>
            </ng-template>
        </mat-tab>
        <!-- In tray -->
        <mat-tab>
            <ng-template matTabLabel>
                <span [matBadge]="(printRequirementsTray$ | async)?.length" matBadgeOverlap="false">Completados</span>
            </ng-template>
            <ng-template matTabContent>
                <app-filtered-requirements
                        [filteredRequirements$]="printRequirementsTray$"
                        [type]="'tray'"
                >
                </app-filtered-requirements>
            </ng-template>
        </mat-tab>


    </mat-tab-group>
</div>
<mat-menu #printersMenu="matMenu">
    <button mat-menu-item (click)="onSelectPrinters()">Seleccionar impresoras</button>
    <mat-checkbox
            class="example-margin"
            [checked]="onlyPrinterCards"
            (change)="changeOnlyPrinterCards()"
            labelPosition="before">
        Modo panel de control
    </mat-checkbox>
</mat-menu>
