import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSelect } from '@angular/material/select';
import { cloneDeep, isEqual, minBy } from 'lodash';
import { combineLatest, defer, forkJoin, Observable, of, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map, share, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { PageSize } from 'src/app/models/models';
import { PrintersService } from 'src/app/shared/printers.service';
import { ImpresionContentFilterType, ImpresionService, PrintOrderOptions, PrintOrderPartialOptions, PrinterStatusUI } from '../impresion.service';
import { ActionEvent } from './pending-jobs-table/pending-jobs-table.component';
import { AppService } from 'src/app/app.service';
import { MatDialog } from '@angular/material/dialog';
import { PrintPartialDialogComponent } from '../print-partial-dialog/print-partial-dialog.component';
import { PrintJob } from 'src/app/models/print-job.model';
import { SettingService } from 'src/app/shared/setting.service';
import { UserService } from 'src/app/core/user.service';
import { PrinterCapabilities } from 'src/app/models/printer-model';
import { PrintControlService } from "../../print-control/print-control.service";
import * as moment from "moment/moment";
import { CalendarDateRange } from "../../../../shared/elastic-search.service";

@Component({
  selector: 'app-pending-jobs',
  templateUrl: './pending-jobs.component.html',
  styleUrls: ['./pending-jobs.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PendingJobsComponent implements OnInit, OnDestroy {
  @Input() userPrintersStatus$: Observable<Array<PrinterStatusUI>>
  @Input() impresionContentFilterType: ImpresionContentFilterType = ImpresionContentFilterType.A4;
  totalOrders: number = 0;
  filter: string = '';

  userRole$ = this.userService.user.pipe(
    map(user => user?.role),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  settingsPrinter$ = this.settingService.get('printers').pipe(shareReplay({ bufferSize: 1, refCount: true }));

  printersStatus$: Observable<Array<PrinterStatusUI>>;
  colorPrinters$: Observable<Array<PrinterStatusUI>>;
  blackPrinters$: Observable<Array<PrinterStatusUI>>;
  mixedPrinters$: Observable<Array<PrinterStatusUI>>;

  mixedPrint = new FormControl(false);
  mixedPrint$ = this.mixedPrint.valueChanges.pipe(
    startWith(this.mixedPrint.value),
    switchMap(mixedPrint => combineLatest([of(mixedPrint), this.settingsPrinter$, this.userRole$])),
    tap(async ([mixedPrint, settingsPrinter, userRole]) => {
      this.impresionService.isMixedModeSelected = mixedPrint;
      const filter = !mixedPrint || settingsPrinter.mixedPrintAllowedByRole?.[userRole]?.includes(this.filter)
        ? this.filter
        : (settingsPrinter.mixedPrintAllowedByRole?.[userRole]?.[0] ?? ''); // select first available
      // console.log('PRE on filter change: ', filter);
      this.onfilterChange({ value: filter } as any); // this also saves user default
    }),
    map(([mixedPrint, _, __]) => mixedPrint),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  mixedPrintAllowed$ = combineLatest([this.settingsPrinter$, this.userRole$]).pipe( // general checkmark
    map(([settingsPrinter, userRole]) => settingsPrinter.mixedPrintAllowed && settingsPrinter.mixedPrintAllowedByRole?.[userRole]?.length > 0),
    distinctUntilChanged(),
    tap(mixedPrintAllowed => {
      if (!mixedPrintAllowed) {
        this.mixedPrint.patchValue(!!mixedPrintAllowed);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  bnOnColorWarnPctByManufacturer$ = this.settingsPrinter$.pipe(
    map(printers => printers.bnOnColorWarnPctByManufacturer),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  autoSuggest = new FormControl(false);
  autoSuggest$ = this.autoSuggest.valueChanges.pipe(
    startWith(this.autoSuggest.value),
    shareReplay({ bufferSize: 1, refCount: true }),
  );


  refreshMs = 30 * 1000;
  autorefresh$ = timer(0, this.refreshMs);


  bnOnColorData$ = combineLatest([this.autorefresh$, this.printControlService.availablePrinters$, this.bnOnColorWarnPctByManufacturer$]).pipe(
    switchMap(([_, __, bnOnColorWarnPctByManufacturer]) => forkJoin({
      canon: this.getBnOnColorData(this.printControlService.printerBrandFilters['Canon']).pipe(
        map(data => this.printControlService.mapPrintedHistogram(data)),
        map(data => this.mapBnOnColorData(data, bnOnColorWarnPctByManufacturer['Oce']))
      ),
      konica: this.getBnOnColorData(this.printControlService.printerBrandFilters['Konica']).pipe(
        map(data => this.printControlService.mapPrintedHistogram(data)),
        map(data => this.mapBnOnColorData(data, bnOnColorWarnPctByManufacturer['KONICA MINOLTA']))
      )
    })),
    map(results => ({
      canon: {
        percentage: (results.canon.percentage ?? 0) / 100,
        remaining: results.canon.remaining
      },
      konica: {
        percentage: (results.konica.percentage ?? 0) / 100,
        remaining: results.konica.remaining
      }
    })),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  isFilterDisabled$(filter: string): Observable<boolean> {  // filter buttons
    return combineLatest([this.mixedPrint$, this.settingsPrinter$, this.userRole$]).pipe(
      map(([mixedPrint, settingsPrinter, userRole]) => {
        return (this.impresionContentFilterType == ImpresionContentFilterType.PPL && filter == 'N')
          || (mixedPrint && !(settingsPrinter.mixedPrintAllowedByRole?.[userRole]?.includes(filter)));
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  };

  pageSizeSelected = new FormControl(PageSize.A4);

  isColorPrintersVisible = true;
  isBlackPrintersVisible = true;
  isMixedPrintersVisible = false;

  isPageSizeVisible = true;

  colorPrinterSelected = new FormControl();
  bnPrinterSelected = new FormControl();
  mixedPrinterSelected = new FormControl();
  colorPrinterCapabilities$: Observable<PrinterCapabilities>;
  bnPrinterCapabilities$: Observable<PrinterCapabilities>;
  mixedPrinterCapabilities$: Observable<PrinterCapabilities>;

  printerChanged$ = combineLatest([
    this.colorPrinterSelected.valueChanges,
    this.bnPrinterSelected.valueChanges,
    this.mixedPrinterSelected.valueChanges,
    // this.mixedPrint.valueChanges,
  ]);

  largerPageSize = new FormControl(false);
  largerPageSize$ = this.largerPageSize.valueChanges;
  largerPageSizeAllowed$ = combineLatest([
    this.settingsPrinter$,
    this.userRole$,
    this.pageSizeSelected.valueChanges.pipe(startWith(this.pageSizeSelected.value)),
  ]).pipe( // show/hide general checkmark
    // tap(_ => console.log('largerPageSizeAllowed$', _)),
    map(([settingsPrinter, userRole, pageSizeSelected]) => {
      return [ImpresionContentFilterType.F, ImpresionContentFilterType.PPL].includes(this.impresionContentFilterType)
        && [PageSize.A5].includes(pageSizeSelected)
        && settingsPrinter.largerPageSizeAllowed
        && settingsPrinter.largerPageSizeAllowedByRole?.[userRole]?.length > 0;
    }),
    distinctUntilChanged(),
    tap(largerPageSizeAllowed => {
      if (!largerPageSizeAllowed) {
        this.largerPageSize.patchValue(!!largerPageSizeAllowed);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  printerChangedSubscription: Subscription;
  largerPageSizeSubscription: Subscription;

  constructor(
    private impresionService: ImpresionService,
    private printersService: PrintersService,
    private appService: AppService,
    private userService: UserService,
    private settingService: SettingService,
    private printControlService: PrintControlService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    // console.log(`=======>>> PendingJobsComponent INIT`, this.impresionContentFilterType);

    // 1st
    if (this.impresionContentFilterType == ImpresionContentFilterType.A4) {
      this.isPageSizeVisible = false;
    }

    this.printersStatus$ = this.userPrintersStatus$.pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.colorPrinterCapabilities$ = printerCapabilitiesDef$(this.printersStatus$, this.colorPrinterSelected.valueChanges);
    this.bnPrinterCapabilities$ = printerCapabilitiesDef$(this.printersStatus$, this.bnPrinterSelected.valueChanges);
    this.mixedPrinterCapabilities$ = printerCapabilitiesDef$(this.printersStatus$, this.mixedPrinterSelected.valueChanges);


    // 2nd
    this.setDefault();
    this.printerChangedSubscription = this.printerChanged$.subscribe(_ => this.saveDefault('printers'));
    this.largerPageSizeSubscription = this.largerPageSize$.subscribe(_ => this.saveDefault('largerPageSize'));

    // 3rd
    this.colorPrinters$ = this.printersStatus$.pipe(
      map(printersStatus => printersStatus.filter(printerStatus => printerStatus.colorSupported)),
      tap(printersStatus => this.setSelectPrinters(printersStatus, this.colorPrinterSelected)),
    )

    this.blackPrinters$ = this.printersStatus$.pipe(
      map(printersStatus => printersStatus.filter(printerStatus => (!printerStatus.colorSupported || printerStatus.allowBnJobs))),
      tap(printersStatus => this.setSelectPrinters(printersStatus, this.bnPrinterSelected)),
    )

    this.mixedPrinters$ = this.printersStatus$.pipe(
      map(printersStatus => printersStatus.filter(printerStatus => printerStatus.allowMixedJobs)),
      tap(printersStatus => this.setSelectPrinters(printersStatus, this.mixedPrinterSelected)),
    )

  }

  ngOnDestroy(): void {
    this.printerChangedSubscription?.unsubscribe();
    this.largerPageSizeSubscription?.unsubscribe();
  }

  onfilterChange(event: MatButtonToggleChange) {
    // console.log('on filter change: ', event.value);
    // this._filterMode.next(event.value);
    this.filter = event.value;
    this.isBlackPrintersVisible = true;
    this.isColorPrintersVisible = true;
    this.isMixedPrintersVisible = this.mixedPrint.value;
    if (this.mixedPrint.value) {
      this.isBlackPrintersVisible = false;
      this.isColorPrintersVisible = false;
    } else {
      switch (this.filter) {
        case 'C':
          this.isBlackPrintersVisible = false;
          break;
        case 'N':
          this.isColorPrintersVisible = false;
          break;
      }
    }
    this.saveDefault('filter/mixed');
  }

  byId(o1, o2) {
    return o1 && o2 ? o1.deviceId === o2.deviceId : o1 === o2
  }

  setSelectPrinters(printersStatus: Array<PrinterStatusUI>, printerSelected: FormControl) {
    const printerValue = printerSelected.value;
    // console.log('setSelectPrinters OUT');
    if (!printerValue || !printersStatus.find(f => f.deviceId === printerValue.deviceId)) {
      // console.log('setSelectPrinters IN');
      const bestPrinter = minBy(printersStatus, 'estimatedSeconds')
      printerSelected.setValue(bestPrinter);
    }
  }

  onTableAction(event: ActionEvent) {
    const actions = {
      print: this.print,
      partialPrint: (payload) => this.partialPrint(payload),
      loaded: (payload) => this.setOrdersStats(payload),
      block: (payload) => this.block(payload),
      delete: (payload) => this.delete(payload),
      reset: (payload) => this.reset(payload),
    }
    actions[event.type](event.payload) as any;
  }

  print = (payload) => {
    payload.order.isProcessing = true; // block button asap
    const order = cloneDeep(payload.order);
    const type = payload.type;

    const printOrderOptions: PrintOrderOptions = {
      type,
      pageSize: this.pageSizeSelected.value,
      isMixed: this.mixedPrint.value,
      forceLargerPageSize: this.largerPageSize.value,
    };
    const selectedPrinters = this.selectedPrinters();
    const ret = this.impresionService.print(order, selectedPrinters, printOrderOptions);
    if (ret?.errors?.length) {
      alert("Impresora no seleccionada")
      payload.order.isProcessing = false;
    } else {
      // payload.order.isProcessing = true;
    }
  }

  partialPrint = async (payload) => {
    const order = cloneDeep(payload.order);
    const type = payload.type;
    const dialogRef = this.dialog.open(PrintPartialDialogComponent, {
      data: {
        order,
        printers: {
          colorPrinters$: this.colorPrinters$,
          blackPrinters$: this.blackPrinters$,
          mixedPrinters$: this.mixedPrinters$,
          default: {
            colorPrinter: this.colorPrinterSelected.value,
            blackPrinter: this.bnPrinterSelected.value,
            mixedPrinter: this.mixedPrinterSelected.value,
          }
        }
      },
      hasBackdrop: true,
      // position:{right:'30px'}
    });

    const rest = await dialogRef.afterClosed().toPromise();
    if (rest) {
      payload.order.isProcessing = true; // block button asap after dialog is closed
      // console.log('RESP PARTIAL JOBS:');
      // console.log(rest);
      // return;
      const {
        skipReport,
        printGroupIndexes,
        printGroupFileIndexes,
        printTypeSelected,
        selectedPrinters,
        selectedMode,
        pageSize,
        discardOnPrint,
        // exceptions:
        forceLargerPageSize,
        exceptionCoverLaminatedPageSize,
      } = rest;
      const partial: PrintOrderPartialOptions = {
        skipReport,
        printGroupIndexes,
        printGroupFileIndexes,
        printTypeSelected,
        selectedMode,
        // exceptions:
        exceptionCoverLaminatedPageSize,
      }

      const isMixed = printTypeSelected === PrintJob.Type.MIXED;

      if (discardOnPrint) {
        await this.printersService.deleteOrder(order); // delete from print list
      }
      const printOrderOptions: PrintOrderOptions = { type, pageSize, isMixed, forceLargerPageSize, partial };
      const ret = this.impresionService.print(order, selectedPrinters, printOrderOptions);
      if (ret?.errors?.length) {
        alert("Impresora no seleccionada")
        payload.order.isProcessing = false;
      } else {
        // payload.order.isProcessing = true;
      }
    }

  }


  block = (payload) => {
    const order = payload.order;
    const _block = !!payload.block;
    payload.order.isProcessing = true;
    this.printersService.blockOrder(order, _block);
  }

  delete = (payload) => {
    const order = payload.order;
    payload.order.isProcessing = true;
    this.printersService.deleteOrder(order);
  }

  reset = async (payload) => {
    const order = payload.order;
    payload.order.isProcessing = true;
    let requirements = await this.impresionService.printerRequirements$.pipe(take(1)).toPromise();
    requirements = requirements.filter(e => e.order?.id == order.id);
    const printStatusGroups = await this.printersService.resetOrderPrintProgress(order, requirements);
    payload.order.printStatusGroups = [...printStatusGroups];
  }

  selectedPrinters() {
    const printers = [];
    if (this.mixedPrint.value) {
      if (this.mixedPrinterSelected.value) {
        printers.push(this.mixedPrinterSelected.value)
      }
    } else {
      if (this.bnPrinterSelected.value) {
        printers.push(this.bnPrinterSelected.value)
      }
      if (this.colorPrinterSelected.value) {
        printers.push(this.colorPrinterSelected.value)
      }
    }
    return printers;
  }

  setOrdersStats(values) {
    this.totalOrders = values.totalOrders;
  }


  async saveDefault(arg?) {
    // console.log('saveDefault: ', arg, this.largerPageSize.value);
    const selected = {
      largerPageSize: this.largerPageSize.value,
      mixed: this.mixedPrint.value,
      filter: this.filter,
      colorPrinter: this.colorPrinterSelected.value ?? null,
      blackPrinter: this.bnPrinterSelected?.value ?? null,
      mixedPrinter: this.mixedPrinterSelected?.value ?? null,
    }
    this.appService.saveUserDefault(selected);
  }

  async setDefault() {
    const options = await this.appService.getDefault();
    // console.log('===>> ON INIT', options);
    this.filter = options.filter ?? "";
    this.colorPrinterSelected.setValue(options.colorPrinter ?? null);
    this.bnPrinterSelected.setValue(options.blackPrinter ?? null);
    this.mixedPrinterSelected.setValue(options.mixedPrinter ?? null);
    this.mixedPrint.patchValue(!!options.mixed);
    this.largerPageSize.patchValue(!!options.largerPageSize);
  }

  pageSizeValues = pageSizeValues;

  getBnOnColorData(filter: any = []) {
    // const canonTableData = this.printControlService.printSidesPrintedByJobType()
    const dateRange = {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate()
    } as CalendarDateRange; // Mes actual
    return this.printControlService.printSidesByJobTypeHistogram(filter, dateRange, undefined)
  }

  mapBnOnColorData(data, limitPercentage) {
    return this.printControlService.getBnOnColorDataForRange(data, limitPercentage);
  }
}


export function pageSizeValues(): Array<PageSize> {
  return [PageSize.A4, PageSize.A5, PageSize.A3]; // force order to show
}

const printerCapabilitiesDef$ = (printersStatus$: Observable<Array<PrinterStatusUI>>, printerSelected$: Observable<PrinterStatusUI>) => {
  return combineLatest([printersStatus$, printerSelected$]).pipe(
    // tap(_ => console.log('color CAPPPP: ', _)),
    map(([printersStatus, printerSelected]: [Array<PrinterStatusUI>, PrinterStatusUI]) => {
      return printersStatus?.find(e => e.deviceId == printerSelected?.deviceId) as PrinterStatusUI;
    }),
    map(printerStatus => PrinterCapabilities.fromPrinterStatus(printerStatus)),
    distinctUntilChanged(isEqual),
    // tap(_ => console.log('color CAPPPP 2: ', _)),
  );
}
