<div class="pt-10 w-100" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="column" fxLayoutAlign="start center">
    <mat-button-toggle-group appearance="legacy" name="jobsFilters" aria-label="Jobs Filters" [value]=filter
      (change)="onfilterChange($event)">
      <mat-button-toggle value="" [ngClass]="{disabled:isFilterDisabled$('') | async}">
        Todos
      </mat-button-toggle>
      <mat-button-toggle value="N" [ngClass]="{disabled:isFilterDisabled$('N') | async}">
        100% BN
      </mat-button-toggle>
      <mat-button-toggle value="C" [ngClass]="{disabled:isFilterDisabled$('C') | async}">
        100% Color
      </mat-button-toggle>
      <mat-button-toggle value="M1" [ngClass]="{disabled:isFilterDisabled$('M1') | async}">
        Mix. Grupos
      </mat-button-toggle>
      <mat-button-toggle value="M2" [ngClass]="{disabled:isFilterDisabled$('M2') | async}">
        Mix. Portadas
      </mat-button-toggle>
      <mat-button-toggle value="M3" [ngClass]="{disabled:isFilterDisabled$('M3') | async}">
        Mix. Gr. y Port.
      </mat-button-toggle>
      <mat-button-toggle value="M4" [ngClass]="{disabled:isFilterDisabled$('M4') | async}">
        Mix. Docs
      </mat-button-toggle>
      <!-- <mat-button-toggle value="M!E">Mix. sin Enc.</mat-button-toggle> --> <!-- sad :( -->
    </mat-button-toggle-group>
    <div fxLayout="column" fxLayoutAlign="start center">
      <div>
        <div fxFlex="1 0 1100px" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="60px">
          <div><b>{{ totalOrders }}</b> {{totalOrders == 1 ? 'Pedido' : 'Pedidos'}}</div>
          <div fxLayout="column" fxLayoutAlign="end center">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
              <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                <!-- <mat-checkbox [formControl]="autoSuggest" >Sugerir impresora</mat-checkbox> -->
                <mat-checkbox [formControl]="largerPageSize" [fxHide]="!(largerPageSizeAllowed$ | async)"
                  matTooltip="Imprimir grupos A5 en formato A4">
                  Impresión A5 en A4
                </mat-checkbox>
                <mat-checkbox [formControl]="mixedPrint" [fxHide]="!(mixedPrintAllowed$ | async)"
                  matTooltip="Imprimir ambos colores por una sola impresora">
                  Impresión Mixta
                </mat-checkbox>
              </div>

              <div [fxFlex]="isPageSizeVisible ? '1 0 560px' : '1 0 380px'" fxLayout="row" fxLayoutGap="10px"
                fxLayoutAlign="end center">
                <!-- black printer -->
                <mat-form-field [fxHide]="!isBlackPrintersVisible" appearance="legacy">
                  <mat-select [formControl]="bnPrinterSelected" [compareWith]="byId">
                    <mat-select-trigger>
                      <div class="pelotica"></div>
                      {{ bnPrinterSelected.value?.label }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let printer of blackPrinters$ | async" [value]="printer">
                      <div class="pelotica"></div>
                      {{ printer.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- color printer -->
                <mat-form-field [fxHide]="!isColorPrintersVisible" appearance="legacy">
                  <mat-select [formControl]="colorPrinterSelected" [compareWith]="byId">
                    <mat-select-trigger>
                      <div class="pelotica roja"></div>
                      {{ colorPrinterSelected.value?.label }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let printer of colorPrinters$ | async" [value]="printer">
                      <div class="pelotica roja"></div>
                      {{ printer.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- mixed printer -->
                <mat-form-field [fxHide]="!isMixedPrintersVisible" appearance="legacy">
                  <mat-select [formControl]="mixedPrinterSelected" [compareWith]="byId">
                    <mat-select-trigger>
                      <div class="pelotica verde"></div>
                      {{ mixedPrinterSelected.value?.label }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let printer of mixedPrinters$ | async" [value]="printer">
                      <div class="pelotica verde"></div>
                      {{ printer.label }}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
                <!-- page size -->
                <mat-form-field [fxHide]="!isPageSizeVisible" appearance="legacy">
                  <mat-select [formControl]="pageSizeSelected">
                    <mat-select-trigger>
                      Folio {{ pageSizeSelected.value }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let pageSize of pageSizeValues()" [value]="pageSize">
                      Folio {{ pageSize }}
                    </mat-option>
                  </mat-select>
                  <!-- <mat-select [(value)]="selectedPageSize">
                <mat-select-trigger>
                  Folio {{ selectedPageSize }}
                </mat-select-trigger>
                <mat-option *ngFor="let pageSize of pageSizeValues()" [value]="pageSize">
                  Folio {{ pageSize }}
                </mat-option>
              </mat-select> -->
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!!mixedPrint.value && (bnOnColorData$ | async); let bnOnColorData" class="bnOnColorDiv" fxLayout="row"
        fxLayoutGap="20px" fxLayoutAlign="start center" fxFlexAlign="end">
        <div>
          <label>Canon: </label>
          <span>{{ bnOnColorData.canon.percentage | percent:'1.0-0' }}
            (Restante: {{ bnOnColorData.canon.remaining | number }})</span>
        </div>
        <div>
          <label>Konica: </label>
          <span>{{ bnOnColorData.konica.percentage | percent:'1.0-0' }}
            (Restante: {{ bnOnColorData.konica.remaining | number }})</span>
        </div>
      </div>
    </div>
  </div>

  <!-- [impresionContentFilterType]="impresionContentFilterType" [selectedPageSize]="selectedPageSize" -->
  <app-pending-jobs-table class="w-100" [filterContains]="filter"
    [impresionContentFilterType]="impresionContentFilterType" [selectedPageSize]="pageSizeSelected.value"
    [isMixedPrintActive]="mixedPrint.value" [isLargerPageSizeActive]="largerPageSize.value"
    [bnOnColorWarnPctByManufacturer]="bnOnColorWarnPctByManufacturer$ | async"
    [colorPrinterCapabilities]="colorPrinterCapabilities$ | async"
    [bnPrinterCapabilities]="bnPrinterCapabilities$ | async"
    [mixedPrinterCapabilities]="mixedPrinterCapabilities$ | async" (action)="onTableAction($event)">
  </app-pending-jobs-table>

  <div>