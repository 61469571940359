<div class="container">
    <div class="mat-small">Tipo de Impresión</div>
    <div class="p-10">
        <mat-select [formControl]="printTypeSelected">
            <mat-option
                *ngFor="let filteredType of filteredTypes$ | async"
                id="key"
                [value]="filteredType.key"
            >
                {{filteredType.label}}
            </mat-option>
        </mat-select>
    </div>
</div>