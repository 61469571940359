<div class="dialog" *ngIf="!!addressForm">
  <form [formGroup]="addressForm" (ngSubmit)="onSubmit()" fxLayout="column">

    <ng-container [ngTemplateOutlet]="mateInput"
                  [ngTemplateOutletContext]="{data:{control: 'recipient',title: 'Destinatario',placeholder: 'Escriba el destinatario'}}"></ng-container>
    <ng-container *ngIf="data.selectedAddress == address.SHIP" [ngTemplateOutlet]="mateInput"
                  [ngTemplateOutletContext]="{data:{control: 'phone',title: 'Teléfono',placeholder: 'Escriba el numero de teléfono'}}"></ng-container>
    <ng-container [ngTemplateOutlet]="mateInput"
                  [ngTemplateOutletContext]="{data:{control: 'addressLine1',title: 'Dirección',placeholder: 'Escriba la dirección'}}"></ng-container>
    <ng-container [ngTemplateOutlet]="mateInput"
                  [ngTemplateOutletContext]="{data:{control: 'addressLine2',title: '',placeholder: ''}}"></ng-container>
    <ng-container [ngTemplateOutlet]="mateInput"
                  [ngTemplateOutletContext]="{data:{control: 'city',title: 'Ciudad',placeholder: 'Escriba la ciudad'}}"></ng-container>

    <!-- Exception -->
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="45">
        <mat-form-field fxFlex>
          <mat-label>Código Postal <span class="error" *ngIf="!!validationMessages['postalCode']">*</span></mat-label>
          <input [formControlName]="'postalCode'" placeholder="Escriba el código postal"
                 (keydown)="inputChanges('postalCode')" matInput type="text">
          <mat-error *ngIf="!!validationMessages['postalCode'] && !addressForm.controls['postalCode'].valid ">
            <ng-container *ngFor="let validation of validationMessages['postalCode']">
              <div *ngIf="addressForm.get('postalCode').hasError(validation.type) && ((addressForm.get('postalCode').dirty || addressForm.get('postalCode').touched ))">
                {{ validation.message }}
              </div>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="45">
        <mat-form-field fxFlex>
          <mat-label>Provincia<span class="error" *ngIf="!!validationMessages['province']">*</span></mat-label>
          <input [formControlName]="'province'" placeholder="" (keydown)="inputChanges('province')" matInput
                 [readonly]="true" type="text">
          <mat-error *ngIf="!!validationMessages['province'] && !addressForm.controls['province'].valid ">
            <ng-container *ngFor="let validation of validationMessages['province']">
              <div *ngIf="addressForm.get('province').hasError(validation.type) && ((addressForm.get('province').dirty || addressForm.get('province').touched ))">
                {{ validation.message }}
              </div>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- <ng-container  [ngTemplateOutlet]="mateInput" [ngTemplateOutletContext]="{data:{control: 'postalCode',title: 'Código Postal',placeholder: 'Escriba el código postal', flex: 90}}"></ng-container> -->
      <!-- <ng-container  [ngTemplateOutlet]="mateInput" [ngTemplateOutletContext]="{data:{control: 'province',title: 'Provincia',placeholder: '',readonly: true, flex: 90}}"></ng-container> -->
    </div>
    <div class="flex flex-col">
      <div *ngIf="addressForm.get('pickupPointCode').value" class="flex flex-row gap-2 items-center mb-2">
        <span class="font-bold ">(Punto de recogida seleccionado: {{ addressForm.get('pickupPointName').value }}
        </span>
        <button [disabled]="isLoading" (click)="clearPickupPoint()" mat-icon-button color="warn"
                type="button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="flex flex-row gap-2">
        <button [disabled]="isLoading || data.order.shippingType === ShippingType.URGENT"
                (click)="openPickupPointsSelector()" mat-raised-button color="accent"
                type="button">{{
            data.order.shippingType !== ShippingType.URGENT
              ? 'Seleccionar punto de recogida'
              : 'No disponible para envío urgente'
          }}
        </button>
      </div>
    </div>


    <!-- Como no usaremos facturación las comento... -->
    <ng-container *ngIf="data.selectedAddress == address.BILL" [ngTemplateOutlet]="mateInput"
                  [ngTemplateOutletContext]="{data:{control: 'organization',title: 'Empresa',placeholder: 'Escriba la empresa'}}"></ng-container>
    <ng-container *ngIf="data.selectedAddress == address.BILL" [ngTemplateOutlet]="mateInput"
                  [ngTemplateOutletContext]="{data:{control: 'taxId',title: 'CIF/DNI',placeholder: 'Escriba el CIF/DNI'}}"></ng-container>

    <br>

    <span fxFlex="1 1 auto"></span>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button [disabled]="isLoading" (click)="cancelForm()" mat-raised-button color="warn" type="button">Cancelar
      </button>
      &nbsp;
      <button [disabled]="!checkChanges() || isLoading || addressForm.invalid" mat-raised-button color="primary"
              type="submit">Guardar
      </button>
      &nbsp;
      <mat-spinner [diameter]="25" color="primary" *ngIf="isLoading"></mat-spinner>
    </div>
  </form>
</div>

<!-- Template Section -->
<ng-template #mateInput let-data="data">
  <div>
    <form [formGroup]="addressForm" (ngSubmit)="onSubmit()" fxFlex fxLayoutAlign="center">
      <mat-form-field [fxFlex]="data.flex ? data.flex : 100">
        <mat-label>{{ data.title }} <span class="error" *ngIf="!!validationMessages[data.control]">*</span></mat-label>
        <input [formControlName]="data.control" [placeholder]="data.placeholder" (keydown)="inputChanges(data.control)"
               [readonly]="!!data.readonly" matInput type="text"
        >
        <mat-error *ngIf="!!validationMessages[data.control] && !addressForm.controls[data.control].valid ">
          <ng-container *ngFor="let validation of validationMessages[data.control]">
            <div *ngIf="addressForm.get(data.control).hasError(validation.type) && ((addressForm.get(data.control).dirty || addressForm.get(data.control).touched ))">
              {{ validation.message }}
            </div>
          </ng-container>
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</ng-template>
