import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { PrintType } from '../print-type-selection/print-type-selection.component';
import { PrintJob } from 'src/app/models/print-job.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-partial-printers-selected',
  templateUrl: './partial-printers-selected.component.html',
  styleUrls: ['./partial-printers-selected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartialPrintersSelectedComponent implements OnInit, OnChanges {
  @Input() printTypeSelected:PrintType;
  @Input() printers;
  @Output() changes = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges({printTypeSelected}: SimpleChanges): void {
    if(printTypeSelected){
      this.setPrinters(printTypeSelected.currentValue)
    }
  }

  isColorPrintersVisible = false;
  isBlackPrintersVisible = false;
  isMixedPrintersVisible = false;

  blackPrinter = new FormControl(null);
  colorPrinter = new FormControl(null);
  mixedPrinter = new FormControl(null);

  setPrinters(printTypeSelected:PrintType){
    this.isBlackPrintersVisible  = [PrintJob.Type.BN, 'BC'].includes(printTypeSelected);
    this.isColorPrintersVisible  = [PrintJob.Type.COLOR, 'BC'].includes(printTypeSelected); 
    this.isMixedPrintersVisible  = PrintJob.Type.MIXED == printTypeSelected;
    this.blackPrinter.patchValue(this.blackPrinter.value ?? this.printers.default.blackPrinter);
    this.colorPrinter.patchValue(this.colorPrinter.value ?? this.printers.default.colorPrinter);
    this.mixedPrinter.patchValue(this.mixedPrinter.value ?? this.printers.default.mixedPrinter);
    this.emitChanges();
  }


  onPrinterChange($event){
     this.emitChanges();
  }

  emitChanges(){
    const payload ={
      selectedPrinters : this.selectedPrinters()
    }
    this.changes.emit({type:'update', payload})
  }

  selectedPrinters(){
    const printers = [];
    if (this.isBlackPrintersVisible && this.blackPrinter.value){
      printers.push(this.blackPrinter.value);
    }
    if (this.isColorPrintersVisible && this.colorPrinter.value){
      printers.push(this.colorPrinter.value);
    }
    if (this.isMixedPrintersVisible && this.mixedPrinter.value){
      printers.push(this.mixedPrinter.value);
    }
    return printers;
  }

  byId(o1, o2){
    return o1 && o2 ? o1.deviceId === o2.deviceId : o1 === o2
  }
}
