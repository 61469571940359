import { Injectable } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { OrderPaymentStatus, OrderProcessStatus, PrintColor } from 'src/app/models/models';
import { BundleStatus, PrintJobTypeByPrinterColor, PrintJobTypeByPrinterColorSet } from 'src/app/models/printer-model';
import { CalendarDateRange, CalendarInterval, ElasticSearchService } from 'src/app/shared/elastic-search.service';
import { ChartType } from "angular-google-charts";
import { DecimalPipe } from "@angular/common";
import { Role } from "../../users/user/user.component";
import { PrintersService } from "../../../shared/printers.service";

export interface PrintSidesPendingByColor {
  total: { [PrintColor.BLACKNWHITE]: number, [PrintColor.COLOR]: number };
  mixed: { [PrintColor.BLACKNWHITE]: number, [PrintColor.COLOR]: number };
}

@Injectable()
export class PrintControlService {

  printerBrandFilters = {};
  printerBrands = [
    { manufacturer: 'Oce', label: 'Canon' },
    { manufacturer: 'KONICA MINOLTA', label: 'Konica' },
  ];

  availablePrinters$ = this.printersService.getAvailablePrinters$(false).pipe(
    map(printers => printers.map(e => ({ id: e.deviceId, label: e.label, manufacturer: e.manufacturer }))),
    distinctUntilChanged(isEqual),
    shareReplay(1),
  );

  constructor(
    private es: ElasticSearchService,
    private decimalPipe: DecimalPipe,
    private printersService: PrintersService
  ) {
    this.availablePrinters$.subscribe(printers => {
      this.printerBrands.forEach(pb => {
        this.printerBrandFilters[pb.label] = {
          "printer_id": [
            {
              "value": printers.filter(e => e.manufacturer === pb.manufacturer).map(e => e.id),
              "op": "===",
              "key": "printer.id"
            }
          ],
        }
      })
    })
  }

  private _aggsPrintSidesByPrintJobTypeByPrinterColor = {
    [PrintJobTypeByPrinterColor.BN_ON_BN]: {
      filter: { term: { 'printer.colorSupported': false } },
      aggs: {
        printSides: {
          sum: { field: `printSidesByColor.${PrintColor.BLACKNWHITE}` }
        }
      }
    },
    [PrintJobTypeByPrinterColor.BN_ON_COLOR]: {
      filter: { term: { 'printer.colorSupported': true } },
      aggs: {
        printSides: {
          sum: { field: `printSidesByColor.${PrintColor.BLACKNWHITE}` }
        }
      }
    },
    [PrintJobTypeByPrinterColor.COLOR_ON_COLOR]: {
      filter: { term: { 'printer.colorSupported': true } },
      aggs: {
        printSides: {
          sum: { field: `printSidesByColor.${PrintColor.COLOR}` }
        }
      }
    },
  };

  // already printed today:
  printSidesPrintedByJobType(_filter: any)
    : Observable<PrintJobTypeByPrinterColorSet> { // from today only!
    // console.log('===> ES printSidesPrintedByJobType', _filter);

    const index = 'bundles';
    const filter = [
      { term: { status: BundleStatus.FINISHED } },
      {
        range: {
          'createdAt._seconds': {
            gte: 'now/d', // today only
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
        }
      },
    ];
    const queryBool = this.es.getFilter(_filter);
    // console.log('===> ES printSidesPrintedByJobType B', queryBool);
    queryBool.filter.push(...filter);
    // console.log('===> ES printSidesPrintedByJobType C', queryBool);

    const query = { bool: queryBool };
    const size = 0;
    const aggs = this._aggsPrintSidesByPrintJobTypeByPrinterColor;

    const body = { query, size, aggs };

    // console.log('===> ES printSidesPrintedByJobType BODY', body);

    // resp.aggregations.byPrintJobType.buckets.map(b => ({ printJobType: b.key, printSides: b.printSides.value }))
    return this.es.search({ index, body }).pipe(
      map(resp => {
        return Object.keys(resp.aggregations).reduce((acc, e) => {
          acc[e] = resp.aggregations[e].printSides.value;
          return acc;
        }, {}) as any;
      }),
    );
  }

  // to be printed today:
  printSidesPendingByColor(__filter: any)
    : Observable<{ pendingOnly: PrintSidesPendingByColor, pendingOthers: PrintSidesPendingByColor }> {

    const index = 'orders';

    const _filter = {
      'stats.copies.pageSize': cloneDeep(__filter).pageSize.map(e => {
        e.key = 'stats.copies.pageSize';
        return e;
      })
    };
    // console.log('ES printSidesPendingByColor ORDERS', _filter);
    // TODO: qué hacer con el !== para poder usar esto??
    // const pageSizes = _filter.pageSize?.map(e => e.value);

    // const contains = pageSizes; // same values 
    const filter = [
      { term: { paymentStatus: OrderPaymentStatus.PAID } },
      {
        bool: {
          minimum_should_match: 1,
          should: [
            { term: { processStatus: OrderProcessStatus.PENDING } },
            { term: { processStatus: OrderProcessStatus.PAUSED } },
            { term: { processStatus: OrderProcessStatus.ONHOLD } },
            { term: { processStatus: OrderProcessStatus.INCIDENCE } },
          ],
        }
      },
      // { terms: { contains: contains } }, // just to have a coherent hits.total.value (not working with !==)
    ];

    const queryBool = this.es.getFilter(_filter);
    // console.log('ES printSidesPendingByColor ORDERS QUERYBOOL', queryBool);

    // queryBool.filter.push(...filter);

    const query = { bool: { filter } };
    const size = 0;
    const nestedCopies = {
      nested: { path: 'stats.copies' },
      aggs: {
        pageSize: {
          filter: { bool: queryBool },
          // filter: {
          //   terms: { 'stats.copies.pageSize': pageSizes }
          // },
          aggs: {
            byColor: {
              terms: { field: 'stats.copies.color', size: 10 },
              aggs: {
                total: {
                  sum: { field: 'stats.copies.sideCount' }
                }
              }
            }
          }
        }
      }
    };
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.17/search-aggregations-bucket-filter-aggregation.html#use-filters-agg-for-multiple-filters
    const mixedOnly = {
      filter: { term: { contains: 'M' } },
      aggs: { nestedCopies },
    };
    const aggs = {
      pendingOrders: {
        filters: {
          filters: {
            pendingOnly: {
              term: { processStatus: OrderProcessStatus.PENDING },
            },
            pendingOthers: {
              bool: {
                minimum_should_match: 1,
                should: [
                  { term: { processStatus: OrderProcessStatus.PAUSED } },
                  { term: { processStatus: OrderProcessStatus.ONHOLD } },
                  { term: { processStatus: OrderProcessStatus.INCIDENCE } },
                ],
              }
            },
          }
        },
        aggs: { nestedCopies, mixedOnly },
      }
    };

    const body = { query, size, aggs };
    // console.log('===> ES printSidesPendingByColor BODY', body);

    return this.es.search({ index, body }).pipe(
      map(resp => {
        const _pendingOnly = resp.aggregations.pendingOrders.buckets.pendingOnly;
        const _pendingOthers = resp.aggregations.pendingOrders.buckets.pendingOthers;
        const pendingOnly = {
          total: {
            [PrintColor.BLACKNWHITE]: _pendingOnly.nestedCopies.pageSize.byColor.buckets.find(e => e.key == PrintColor.BLACKNWHITE)?.total.value ?? 0,
            [PrintColor.COLOR]: _pendingOnly.nestedCopies.pageSize.byColor.buckets.find(e => e.key == PrintColor.COLOR)?.total.value ?? 0,
          },
          mixed: {
            [PrintColor.BLACKNWHITE]: _pendingOnly.mixedOnly.nestedCopies.pageSize.byColor.buckets.find(e => e.key == PrintColor.BLACKNWHITE)?.total.value ?? 0,
            [PrintColor.COLOR]: _pendingOnly.mixedOnly.nestedCopies.pageSize.byColor.buckets.find(e => e.key == PrintColor.COLOR)?.total.value ?? 0,
          },
        };
        const pendingOthers = {
          total: {
            [PrintColor.BLACKNWHITE]: _pendingOthers.nestedCopies.pageSize.byColor.buckets.find(e => e.key == PrintColor.BLACKNWHITE)?.total.value ?? 0,
            [PrintColor.COLOR]: _pendingOthers.nestedCopies.pageSize.byColor.buckets.find(e => e.key == PrintColor.COLOR)?.total.value ?? 0,
          },
          mixed: {
            [PrintColor.BLACKNWHITE]: _pendingOthers.mixedOnly.nestedCopies.pageSize.byColor.buckets.find(e => e.key == PrintColor.BLACKNWHITE)?.total.value ?? 0,
            [PrintColor.COLOR]: _pendingOthers.mixedOnly.nestedCopies.pageSize.byColor.buckets.find(e => e.key == PrintColor.COLOR)?.total.value ?? 0,
          },
        };
        // console.log(resp);
        return { pendingOnly, pendingOthers };
      }),
    );
  }

  // already printed, by date:
  printSidesByJobTypeHistogram(_filter: any, range: CalendarDateRange, interval?: CalendarInterval)
    : Observable<Array<PrintJobTypeByPrinterColorSet & { date: string }>> {
    // console.log('ES printSidesByJobTypeHistogram', _filter);

    const _intervalData = this.es.mapCalendarInterval(range, interval);
    const index = 'bundles';

    const filter = [
      { term: { status: BundleStatus.FINISHED } },
      this.es.toDateRangeFilter('createdAt', _intervalData),
    ] as any;

    const queryBool = this.es.getFilter(_filter);
    queryBool.filter.push(...filter);

    const query = { bool: queryBool };
    const size = 0;
    const aggs = {
      histo: {
        date_histogram: this.es.toDateHistogramAggr('createdAt', _intervalData),
        aggs: this._aggsPrintSidesByPrintJobTypeByPrinterColor,
      }
    }
    // extra: sort buckets backwards
    aggs.histo.date_histogram['order'] = { _key: 'desc' };

    const body = { query, size, aggs };
    // console.log('ES printSidesByJobTypeHistogram BODY', body);

    return this.es.search({ index, body }).pipe(
      map(resp => resp.aggregations.histo.buckets.map(b => {
        const ret = { date: b.key_as_string };
        [PrintJobTypeByPrinterColor.BN_ON_BN,
          PrintJobTypeByPrinterColor.BN_ON_COLOR,
          PrintJobTypeByPrinterColor.COLOR_ON_COLOR]
          .forEach(e => ret[e] = b[e]?.printSides.value ?? 0);
        return ret;
      })),
    );
  }


  getBnOnColorDataForRange(tableData, limitPercentage = null) {
    const data = tableData.data;
    const totalColor = data.reduce((accumulator, currentArray) => accumulator + currentArray[2], 0);
    const totalBNColor = data.reduce((accumulator, currentArray) => accumulator + currentArray[3], 0);
    return {
      percentage: (totalBNColor / (totalColor + totalBNColor)) * 100,
      remaining: !limitPercentage ? null : Math.trunc(((totalColor + totalBNColor) * (limitPercentage / 100)) - totalBNColor),
      limitPercentage
    }
  }

  mapPrintedHistogram(data: Array<PrintJobTypeByPrinterColorSet & { date: string }>) {
    const type = ChartType.Table;
    const columns = ['Rango', 'BN', 'Color', 'BN (Color)', '%'];
    const _data = data
      .map(e => this.mapPrintedToday(e) as any)
      .map(e => {
        return [
          e.date,
          e[PrintJobTypeByPrinterColor.BN_ON_BN],
          e[PrintJobTypeByPrinterColor.COLOR_ON_COLOR],
          e[PrintJobTypeByPrinterColor.BN_ON_COLOR],
          this._mapPctLine(e, PrintJobTypeByPrinterColor.BN_ON_COLOR),
        ];
      });
    return { type, data: _data, columns };
  }

  _mapPctLine(data: PrintJobTypeByPrinterColorSet, printJobTypeByPrinterColor: PrintJobTypeByPrinterColor) {
    const v = data[printJobTypeByPrinterColor + 'Pct'] ?? 0;
    return {
      v: v,
      f: v
        ? `${this.decimalPipe.transform(data[printJobTypeByPrinterColor + 'Pct'], '1.0-0')}%`
        : '',
    };
  }

  mapPrintedToday(data: PrintJobTypeByPrinterColorSet) {
    const total =
      (data[PrintJobTypeByPrinterColor.BN_ON_BN] ?? 0) +
      (data[PrintJobTypeByPrinterColor.BN_ON_COLOR] ?? 0) +
      (data[PrintJobTypeByPrinterColor.COLOR_ON_COLOR] ?? 0);

    const totalColor =
      (data[PrintJobTypeByPrinterColor.BN_ON_COLOR] ?? 0) +
      (data[PrintJobTypeByPrinterColor.COLOR_ON_COLOR] ?? 0);

    data[PrintJobTypeByPrinterColor.BN_ON_BN + 'Pct'] = total ? (100 * data[PrintJobTypeByPrinterColor.BN_ON_BN]) / total : 0;
    data[PrintJobTypeByPrinterColor.BN_ON_COLOR + 'Pct'] = total ? (100 * data[PrintJobTypeByPrinterColor.BN_ON_COLOR]) / totalColor : 0;
    data[PrintJobTypeByPrinterColor.COLOR_ON_COLOR + 'Pct'] = total ? (100 * data[PrintJobTypeByPrinterColor.COLOR_ON_COLOR]) / total : 0;

    return data;
  }

}
