import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuardService } from './core/auth/guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


const routes: Routes = [
  {path: 'admin', canActivate: [GuardService], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  {path: 'support', canActivate: [GuardService], loadChildren: () => import('./support/support.module').then(m => m.SupportModule)},
  {path: 'test', canActivate: [GuardService], loadChildren: () => import('./test-page/test-page.module').then(m => m.TestPageModule)},
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: '',   redirectTo: '/admin/orders', pathMatch: 'full' },
  { path: 'business', loadChildren: () => import('./admin/business/business.module').then(m => m.BusinessModule) },
  { path: 'stock', loadChildren: () => import('./admin/business/stock/stock.module').then(m => m.StockModule) },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
