import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCustom } from 'src/app/custom-paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CouponService } from './shared/coupon.service';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { REGION } from '@angular/fire/functions';
import { ElasticSearchService } from '@a2system/angular/common';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxMaskModule } from 'ngx-mask';
import { A2matFiltersModule } from '@a2system/angular/material';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { rollbarFactory, RollbarService } from './core/rollbar.service';
import { ImpresionModule } from './admin/produccion/impresion/impresion.module';
import { StatusToESPipe } from './pipe/status-es.pipe';
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";

registerLocaleData(localeEs, 'es-ES');


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * Inicializa al correr la app
 */
export function appInit(couponService: CouponService, es: ElasticSearchService) {
  // https://angular.io/api/core/FactoryProvider
  // https://medium.com/@gmurop/managing-dependencies-among-app-initializers-in-angular-652be4afce6f
  return () => [
    //añade aquí las funciones que quieras inicializar
    couponService.loadData(),
    es.setEsConfig(environment)
  ];
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    HttpClientModule,
    CodemirrorModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxDaterangepickerMd.forRoot(),
    GoogleChartsModule.forRoot({
      mapsApiKey: environment.googleMapsConfig.mapsApiKey,
    }),
    NgxMaskModule.forRoot(),
    A2matFiltersModule,
    NgxMaterialTimepickerModule,

    // Google Maps
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsConfig.mapsApiKey,
      libraries: ['places'],
      region: environment.googleMapsConfig.region,
      language: environment.googleMapsConfig.language
    }),
    MatGoogleMapsAutocompleteModule,
    ImpresionModule,//.forRoot()
    // End
  ],
  providers: [
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: REGION, useValue: 'europe-west1' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    ElasticSearchService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [CouponService, ElasticSearchService]
    },
    StatusToESPipe,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
