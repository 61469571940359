import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BundlePrintJobsDialogComponent } from './bundle-print-jobs-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    BundlePrintJobsDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    MatTableModule,
    MatIconModule,
    MatSlideToggleModule,
    FormsModule,
  ]
})
export class BundlePrintJobsDialogModule { }
