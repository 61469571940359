import { UserModel } from './../core/user.model';
import { UpdatedBy } from './models';

export interface ActivityLog {
  createdAt: Date;
  ​
  ref: ActivityLog.refCollection;
  user: UserModel.Reference;
  type: ActivityLog.Type;
  payload: ActivityLog.PropertPayload | ActivityLog.CommentPayload;
  from: UpdatedBy.From;
}

export namespace ActivityLog {
  export interface refCollection {
    collection: String;
    id: String;
  }

  export interface PropertPayload {
    changes: [{
      property: String;
      old: any;
      new: any;
    }]
  }

  export interface CommentPayload {
    comment: string;
  }

  export interface DownloadPayload {
    reason: string;
    downloadRequest: any;
  }
  export interface ReportIncidentPayload {
    message: string;
  }

  export enum Type {
    PROPERTY_CHANGED = 'property_changed',
    CREATED = 'created',
    COMMENT = 'comment',
    DOWNLOAD = 'download',
    REPORT_INCIDENT = 'report_incident',
  }
}
