import { Pipe, PipeTransform } from '@angular/core';
import { isEqual } from 'lodash';



@Pipe({
  name: 'query'
})
export class QueryPipe implements PipeTransform {

  /**
   * Ask if is exist coincidence between 2 values
   * 
   * value contains an array of elements and the value to check
   * 
   * args is an array of nested profundity
   * ex:
   * !!(row.users | query:user:['id'])
   */
  transform(values: Array<any>, valToSee:any , finder: string[]=[]): boolean {
    const searchValFromFinder = (val) => {
      finder.forEach(f => val = val[f]);
      return val;
    }; // encuentra su respectivo elemento
    valToSee = searchValFromFinder(valToSee);
    // console.log(valToSee);
    return values?.find( v => {
      const result = isEqual(searchValFromFinder(v), valToSee);
      // console.log("result:" ,{v,valToSee,result});
      return result;
    } )
  }


}
