import { Injectable } from '@angular/core';
import { ArchiveModel } from '../models/archive.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireFunctions } from "@angular/fire/functions";

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {
  private collection: AngularFirestoreCollection<ArchiveModel>;
  private _API_ZIP_ORDER_GROUP = 'api2hl/archives/zipOrderGroupFiles';

  constructor(
    private db: AngularFirestore,
    private functions: AngularFireFunctions
  ) {
    // this.collection = this.db.collection<ArchiveModel>('archives', ref => ref.orderBy('createdAt','desc') );
    this.collection = this.db.collection<ArchiveModel>('archives', ref =>
      ref.orderBy('printGroupId', 'asc')
    );

  }


  /**
   * Tomamos los grupos de archivos en un array provenientes del id del pedido.
   * @param orderId
   */
  getByOrderId(orderId: string): Observable<any> {
    return this.db.collection<ArchiveModel>('archives', ref =>
      ref.where('orderId', '==', orderId))
      .valueChanges().pipe(map(archives => {
        let archivesObj = {};

        archives.forEach(archive => {
          archivesObj[archive.printGroupId] = archive
        })
        // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@archivesObj: ", archivesObj, archives, orderId);
        return archivesObj;

      }))
  }


  /**
   * Returns an archive based on a OrderId and a PrintGroupId
   * @param orderId
   * @param printGroupId
   * @returns
   */
  getByOrderIdAndPrintGroupId(orderId: string, printGroupId: number): Observable<any> {
    return this.db.collection<ArchiveModel>('archives', ref => ref.where('orderId', '==', orderId).where('printGroupId', '==', printGroupId).limit(1))
      .valueChanges()
      .pipe(map(archives => archives[0]))
  }

  createPrintGroupZip(orderId, printGroupIndexes) {
    return this.functions.httpsCallable(this._API_ZIP_ORDER_GROUP)({ orderId, printGroupIndexes });
  }

}
