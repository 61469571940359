import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserService } from 'src/app/core/user.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss']
})
export class VerticalMenuComponent implements OnInit {

  user;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
  }
  
  logout(){
    this.authService.doLogout()
    // .then(res => console.log("logout"));
     .then(res => this.router.navigate(['login']));
  }

  changePassword(){
    this.userService.user.subscribe(response => this.user = response);

    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: {
        user: this.user
      }
    });

    dialogRef.afterClosed().subscribe(user => {
      // console.log(user);
    });
  }
}
