import { map } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, pipe } from 'rxjs';
import { ActivityLog } from '../models/activity-log.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

  private collection: AngularFirestoreCollection<ActivityLog>;

  constructor(
    private db: AngularFirestore
  ) {
    this.collection = this.db.collection<ActivityLog>('activityLogs', ref=> ref.orderBy('createdAt', 'desc'));
  }

  get$(id): Observable<ActivityLog> {
    return this.collection.doc<ActivityLog>(id).valueChanges()
      .pipe(map(doc=>{return {id, ...this.normalize(doc)}}));
  }

  getbyOrder$(id): Observable<ActivityLog[]> {
    return this.db.collection<ActivityLog>(
      'activityLogs',
      ref => ref
      .where("ref.id", '==', id)
      .orderBy('createdAt', 'desc')
      .limit(30)
    ) 
    .snapshotChanges()  
    .pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        this.normalize(data)
        return {id,  ...data } as ActivityLog;
      }))
    )    
  }

  upsert(_data): Promise<any>{
    const {id, ...data} = _data;
    if(!id){data.createdAt = firestore.FieldValue.serverTimestamp();}
    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data);
  }  

  delete(id: string){
    return this.collection.doc<ActivityLog>(id).delete();
  }  

  normalize(data){
    data.createdAt = data.createdAt ? data.createdAt.toDate() : new Date();
    // Tenemos crear para lso casos del payload
    // data.payload.new = this.ifTimestamp2Date(data.payload.new);
    // data.payload.old = this.ifTimestamp2Date(data.payload.old);    
    return data
  }
  ifTimestamp2Date = property => property instanceof firestore.Timestamp ?  property.toDate() : property;
}
