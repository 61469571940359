import { firestore } from "firebase";
import { _User } from "../core/user.model";
import { PickupLocation } from "../models/pickup-location";
import { ProductType } from "./product.model";
import { PrintJob } from "./print-job.model";
import { getPrintFileCoverSettings } from "../shared/utils/print-group-document-helper";

// DB collections:
// products (Product)
// files (StoredFile)
// orders (Order)
// temporaryUploads (PrintFile)
// cart (ShoppingCart)
// settings.nextOrderNumber (id)

// products (unique copies, bindings and quick access products)
// export class Product {  // collection
//   createdAt?: Date;
//   updatedAt?: Date;
//   type: ProductType;
//   visible: boolean; // whether it's visible in website (copies won't be, binding neither)
//   title: string;
//   description: string;
//   unitPrice: Price; // page price? encuadernaciones dónde van? (otro producto?) // includes tax
//   pictureUrl?: string;
//   picturePath?: string;
//   weight: number;
// }

export interface OrderItemUi extends OrderItem {
  cartIndex?: number;
  _files?: any[];
}

// short product reference
export interface _Product {
  id: string; // reference to Product
  type: ProductType;
  title: string;
  description: string;
  unitPrice: Price; // page price, binding price, product price
  pictureUrl?: string;
  printingGroup?: PrintingGroup;
  extra?: Object[];
  weight: number;
}

export class Price {
  amt: number; // amount
  cur: string; // currency
}

export enum Contains { // why was this not defined as strings?? it could be used all over
  N, // Impresión B/N únicamente
  C, // Impresión Color únicamente
  M, // Impresión Mixta
  M1, // groups in this order are full BN or full COLOR
  M2, // groups in this order are BN with COVERS, at worst (no loose color documents)
  M3, // groups in this order are full COLOR groups, or BN with covers (mix of COLOR and m2)
  M4, // (old m3) groups in this order are a mix of BN, COLOR and COVER among different groups (with loose color documents)
  A3, // A3 page size
  A4, // A4 page size
  A5, // A5 page size
  W80, // 80g paper weight
  W90, // 90g paper weight
  W100, // 100g paper weight
  W120, // 120g paper weight
  W160, // 160g paper weight
  W200, // 200g paper weight
  W300, // 300g paper weight
  E, // Encuadernación
  Ex, // Encuadernación exclusivamente (sin otros acabados)
  Ec, // Encuadernación borde corto (alguna presente)
  G, // Grapado
  PL, // Plastificado
  T2, // Taladro 2
  T4, // Taladro 4
  T, // Taladro full
  PPL, // Plastificado
  W, // paper weight not 80g 100g
  F, // Folios no A4
  X, // Impresión Manual
  P, // Productos
  SP, // Solo Productos
  B, // Bono Monedero
  PRE, // Preventa
  AB, // Preventa
  BL, // Blank sheets
  // c,  // Portadas a Color (not used anymore)
}

export enum OrderWorkStation {
  WS0 = 0, // 0 - Sin acabado o grapado
  WS1 = 1, // 1 - Enc.B.Largo en A4
  WS2 = 2, // 2 - Enc.B.Corto en A4 o B.Lar
  WS3 = 3, // 3 - Enc.Borde Corto en A5
  WS4 = 4, // 4 - Taladro de 2 o 4 agujeros
  WS5 = 5, // 5 - Plastificados
}


export enum Format {
  FULLDATE = "dd/MM/yyyy H:mm",
  DATE = "dd/MM/yyyy",
  LONGDATE = "DD/MM/YYYY",
  TIMEDATE = "H:mm",
}

export enum Settings {
  PAGE_SIZE = "pageSize",
  PAPER_WEIGHT = "paperWeight",
  PAGE_ORIENTATION = "pageOrientation",
  COLOR = "color",
  PAGES_PER_SHEET = "pagesPerSheet",
  TWO_SIDED = "twoSided",
  PAGE_TURN = "pageTurn",
  FINISHING = "finishing",
  GROUPED = "grouped",
  COVER_COLOR = "coverColor",
  COVER_LAMINATED = "coverLaminated",
  RING_COLOR = "ringColor",
  HARD_COVER_FRONT = "hardCoverFront", // new
  HARD_COVER_BACK = "hardCoverBack", // new
  DOC_COLOR = "docColor",
  AUTO_ROTATE = "autoRotate",
  LAMINATION_TYPE = "laminationType",
  LAMINATION_WEIGHT = "laminationWeight",
}

export class PrintSettings {
  //copies: number; // total copies of this same document. > 0. default: 1
  [Settings.PAGE_SIZE]: PageSize; // default: A4
  [Settings.PAPER_WEIGHT]: PaperWeight; // default: W80
  [Settings.PAGE_ORIENTATION]: PageOrientation; // default: landscape
  [Settings.COLOR]: PrintColor; // default: bn
  [Settings.PAGES_PER_SHEET]: PagesPerSheet; // default: 1
  [Settings.TWO_SIDED]: boolean; // print on both sides. default: true
  [Settings.PAGE_TURN]: PageTurn;
  [Settings.FINISHING]: Finishing;
  [Settings.GROUPED]: boolean;
  [Settings.COVER_COLOR]: boolean;
  [Settings.COVER_LAMINATED]: boolean;
  [Settings.RING_COLOR]: any;
  [Settings.HARD_COVER_FRONT]: any;
  [Settings.HARD_COVER_BACK]: any;
  [Settings.DOC_COLOR]: boolean;
  [Settings.LAMINATION_TYPE]: LaminationType;
  [Settings.LAMINATION_WEIGHT]: LaminationWeight;
}

//old
// export class PrintSettings {
//   //copies: number; // total copies of this same document. > 0. default: 1
//   pageSize: PageSize;  // default: A4
//   pageOrientation: PageOrientation;  // default: landscape
//   color: PrintColor;  // default: bn
//   pagesPerSheet: PagesPerSheet; // default: 1
//   twoSided: boolean; // print on both sides. default: true
// }

export class StoredFile {
  // collection
  createdAt: Date;
  updatedAt: Date;
  name: string; // file name, as uploaded
  contentType: string; // TODO: does client has this? it comes from object once uploaded
  // these come from Storage Upload Response:
  path: string; // file path, reference to object in storage (files/<order id>/<printing group>/<file id>/<file name>)
  size: number; // file size, in bytes
  url: string; // download url
}

export class _StoredFile {
  // collection
  name: string; // file name, as uploaded
  // these come from Storage Upload Response:
  contentType: string; // TODO: does client have this? it comes from object once uploaded
  path: string; // file path, reference to object in storage (files/<order id>/<printing group>/<file id>/<file name>)
  size: number; // file size, in bytes
  url: string; // download url
}

export class PrintFile {
  // collection
  createdAt: Date;
  updatedAt: Date;
  // 2 docs: original and transformed. only original exists if PDF. reference is for print work:
  originalFile: _StoredFile; // file as originally uploaded
  originalType: FileType; // just for the icon
  printFile: _StoredFile; // transformed file (if PDF, it's a reference to the same stored document)
  pages: number; // pdf total pages, no matter printer settings
  thumbFile: _StoredFile; // 200px thumbnail
  status: PrintFileStatus;
  errorMessage?: string;
  substatus?: PrintFileSubStatus; // sub-status while in-process
}

export class _PrintFile extends PrintFile {
  id: string; // reference to print file document
  docColor?: boolean;
  coverColor?: boolean; // NOT ANYMORE since coverType
  coverLaminated?: boolean;
  blankSheetsBefore?: number;
  blankSheetsAfter?: number;
  coverType?: CoverType;
  laminationType?: LaminationType;
  laminationWeight?: LaminationWeight;
}

export class CoverType {
  id: string;
  coverColor: boolean;
  twoSided: boolean;
  pagesPerSheet: PagesPerSheet;
  paperWeight: PaperWeight;
  default: boolean; // whether this coverType is default for the printSettings (not really... used by app only, different from server meaning and usage)
}

export class PrintingGroup {
  // collection x 2
  printSettings: PrintSettings;
  //finishing: Finishing;
  comment: string;
  files: _PrintFile[];
  // MN: total pages (sides) to be printed: depends on pagesPerSheet
  printSides?: number; // amount of pages to be printed, for this group (página o cara del folio, es el total de impresiones)
  printSheets?: number; // amount of sheets to be used, for this group (folio, a 1 o 2 caras)
}

export class OrderItem {
  product: _Product; // reference to Product
  qty: number; // quantity: total amount of this item (in case of pages, total after print settings)
  price: Price; // item price: qty * product.unitPrice
  //id?: string; // optional id, to be able to be identified as a parent
  //parendId?: string; // optional id, if this item belongs to a parent item
}

export class FullPrice {
  cur: string; // currency
  taxPct: number; // tax percentage (from const!)
  noTaxAmt: number; // amount previous to tax  (totalAmt/(1+taxPct/100))
  taxAmt: number; // amount representing the tax (noTaxAmt * taxPct/100)
  totalAmt: number; // total amount (original sum from products, those include tax)
}

export class PostalAddress {
  recipient: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  province: string; // spain province enum?
  provinceId?: string; // new Xav 26/feb/2021
  postalCode: string;
  country: string; // country ISO2. enum country definition?
  phone?: string;
  organization?: string;
  taxId?: string;
  dni?: string; // new Xav 17/nov/2020
  pickupPointCode?: string
}

export class Order {
  // collection
  id?: string;
  createdAt?: Date | any;
  updatedAt?: Date | any;
  updatedBy?: UpdatedBy;
  // cartId: string; // reference to cart document
  number: number; // order number. this needs to be got from settings.nextOrderNumber
  type?: OrderType; // optional prior to v1.2.19 admin
  linkedOrders?: Array<LinkedOrders>;
  name: string;
  userId?: string; // reference to user document. may be anonymous
  email: string; // user's email address
  phone?: string; // user's phone
  cart: ShoppingCart;
  paymentStatus: OrderPaymentStatus;
  processStatus: OrderProcessStatus;
  shippingStatus: OrderShippingStatus;
  shippingAddress: PostalAddress;
  billingAddress: PostalAddress;
  shippingType: ShippingType;
  pickupAddress?: PickupLocation.Base;
  paymentType: PaymentType;
  paymentService?: PaymentService; // only if credit card, service used
  printGroupCount?: number; // print group counter
  userComments?: string; // by user on payment
  // on reply from payment:
  transactionId?: string; // reference to payment transaction // NEW 2
  // paymentRef?: string; // payment reference from service or according to payment type  // NEW 2
  // paymentLog?: any[];  // push payment response here (array so failed + retry success can be tracked) // NEW 2
  // not to be set initially by client:
  adminComments?: string; // by admin from panel
  // invoiceRef?: string; // invoice reference accotding to API // not used anymore
  responsible?: _User; // admin user responsible of processing this order  // NEW 2
  responsibleFinishing?: _User;
  policy?: boolean;
  ads?: boolean;

  //new Xav added 21/dic/2020
  shippingLabelId?: string;
  shippingNotified?: boolean;

  // 20/jun/2022
  printableStatusErrored?: boolean;
  // 2022 08 18 MN
  printStatusGroups?: Array<PrintStatusGroup>;

  workOrderId?: string;

  printableStatus: OrderPrintableStatus;

  // shippingType: ShippingType;
  shippingCourier?: ShippingCourier;
  shippingCourierService?: ShippingCourierService;

  priority?: OrderPriority;
  priorityReason?: string;
  isBusiness?: boolean;
  commentCount?: number;

  invoiceUrl?: string;
  invoiceId?: string;

  paymentAuthCode?;
  contains?: Array<string>;
  urgentForced?: boolean;
  refundedAmt?: number;
}

export interface PrintStatusGroup {
  bundleId?: string;
  pageSize: PageSize;
  paperWeight: PaperWeight;
  color: PrintColor;
  sheetCount: number;
  sideCount: number;
  status: OrderGroupPrintStatus;
}

// these represent the 3 tabs in admin print screen, for each bundle
// this is to avoid updating the order so frequently with each bundle status
export enum OrderGroupPrintStatus { // by bundle status: (same as PrintStatus in functions2)
  PENDING = "pending", // (not requested)
  PROCESSING = "processing", // pending, queued_process, preprocessing, queued_print, printing
  ERROR = "error", // warning, failed
  FINISHED = "finished", // finished
}

export class LinkedOrders {
  id: string; // order id
  number: number; // order number
}

export class ShoppingCart {
  createdAt: Date;
  updatedAt: Date;
  userId?: string; // reference to user document. may be anonymous
  items: OrderItem[];
  shipping: {
    weight: number;
    price: Price;
  };
  price: FullPrice; // total to pay
  discounts: Array<Discount>;
}

export class Transaction {
  // NEW 2
  createdAt: Date;
  orderId: string; // reference to order document
  paymentStatus: OrderPaymentStatus;
  paymentType: PaymentType;
  paymentService?: PaymentService; // only if credit card, service used
  data: any; // service dependant
}

export class DocReference {
  id: string; // document id
  col: string; // collection
}

export class Discount {
  type: DiscountType;
  amount: number;
  currency: string;
  refId: string; // reference to entity generating this discount, according to type (userId, couponId)
  transactionRef: DocReference; // reference to transaction generated by this discount
}

/*
Falta: carrito. completar order.
registro individual de pagos? (full response)
*/

//XA: 12/oct/2020
export interface ShippingLabel {
  // on client create:
  status: ShippingLabelStatus;
  orderId: String;
  createdAt: Date | any;
  // on server update:
  errorMessage?: String;
  file: StoredFile;
  request: any;
  response: any;
  updatedAt: Date | any;

  tracking?: {
    description?: string;
    statusDate?: Date;
    code?: string;
    url?: string;
    // hay varios otros campos de servidor, no sé si los usas. Si no los usas si quieres le pones any o los quitas
    response?: any;
    status?: ShippingLabelStatus;
    //etc etc etc...
  };

  shippingType: ShippingType;
  shippingCourier?: ShippingCourier;
  shippingCourierService?: ShippingCourierService;
}

export interface WorkOrder {
  // on client create:
  status: WorkOrderStatus;
  orderId: String;
  createdAt: Date | any;
  // on server update:
  errorMessage?: String;
  file?: StoredFile;
  updatedAt: Date | any;
}

//XA: New 16/ene/2020
export interface PiggyTransactions {
  createdAt: Date; // <<=== declarar como en otros modelos (Date?)
  updatedAt: Date;
  amount: number;
  currency: String; // siempre "EUR"
  prevBalance: number; // balance anterior
  finalBalance: number; // balance luego de amount
  status: PiggyTransactionStatus;
  type: PiggyTransactionType;
  payload?: {
    bankAccount?: String; // only on PiggyTransactionType:bank
    taxId?: String; // only on PiggyTransactionType:bank
    bankRef?: String; // only on PiggyTransactionType:bank
    feeType?: FeeType; // only on PiggyTransactionType:fee
    feePct?: number; // only on PiggyTransactionType:fee
    description?: String; // maybe i'll add something here...
    orderPriceNoTaxAmt?: number; // Importe de la Orden (sin IVA) on Type:fee
    orderPriceNoTaxAmtBase?: number; // Importe base Comisión (sin IVA) on Type:fee
  };
  ref: {
    // documento causante de esta transacción
    col: String; // collection
    id: String; // id dentro de collection
  };
  user: {
    id: String; // user id
    name: String; // user's name
  };
}

//XA 2/marz/2020
export interface ValidatorMessage {
  [key: string]: Array<ErrorType> | ErrorType[];
}

export interface ErrorType {
  type: string;
  message: string;
}

/**
 * Values from Firestore Database in their services.
 */
export interface IService {
  id?: string;
  createdAt?: firestore.FieldValue | Date;
  updatedAt?: firestore.FieldValue | Date;
}

export const TAX_RATE_PCT = 21; // tax rate

export interface UpdatedBy {
  id: string;
  displayName: string;
  from: UpdatedBy.From;
}

export namespace UpdatedBy {
  export enum From {
    APP = "app",
    ADMIN = "admin",
    SERVER = "server",
  }
}

export enum DiscountType {
  WALLET = "wallet",
  COUPON = "coupon",
}

export enum PageSize {
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
}

export enum PaperWeight {
  W80 = "80",
  W90 = "90",
  W100 = "100",
  W120 = "120",
  W160 = "160",
  W200 = "200",
  W300 = "300",
}

export enum PageOrientation {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape",
}

export enum PrintColor {
  BLACKNWHITE = "bn",
  COLOR = "color",
}

export enum PagesPerSheet {
  ONE = 1,
  TWO = 2,
  FOUR = 4,
} // 6, 9, 16
export enum PageTurn {
  LARGESIDE = "largeside",
  SHORTSIDE = "shortside",
}

export enum Finishing {
  NONE = "none",
  STAPLED = "stapled",
  BINDING = "binding",
  BINDINGX = "bindingx",
  LAMINATED = "laminated",
  LAMINATEDA4 = "laminatedA4", // TODO: remove this definition after some time for old orders
  LAMINATEDA3 = "laminatedA3", // TODO: remove this definition after some time for old orders
  PERFORATED2 = "perforated2",
  PERFORATED4 = "perforated4",
  PERFORATEDBIND = "perforatedbind",
} // bingingx needed for backward compatibility

export enum LaminationType { MATTE = 'matte', GLOSS = 'gloss' };

export enum LaminationWeight { W80 = '80', W125 = '125', W150 = '150', W175 = '175', W250 = '250' };
export const LaminationTypeDefault = LaminationType.GLOSS;
export const LaminationWeightDefault = LaminationWeight.W125;
export const LaminationTypeLabel = {
  [LaminationType.GLOSS]: 'Brillo',
  [LaminationType.MATTE]: 'Mate',
}


export enum FileType {
  PDF = "pdf",
  WORD = "word",
  EXCEL = "excel",
  POWERPOINT = "powerpoint",
  IMAGE = "image",
  OTHER = "other",
} // TODO: redefinir esto? o usar mime type? separar image type? se usará para icono generalmente. el mime type está en el StoredFile

export enum PrintFileStatus {
  NEW = "new",
  PROCESSING = "processing",
  FINISHED = "finished",
  FAILED = "failed",
}

export enum ShippingLabelStatus {
  PROCESSING = "processing",
  SUCCESS = "success",
  FAILED = "failed",
}

export enum WorkOrderStatus {
  PROCESSING = "processing",
  SUCCESS = "success",
  FAILED = "failed",
}

export const RING_COLORS = [
  { name: "Transparente", color: "#F0F0F0", id: "transparent" /*, default: true */ },
  { name: "Blanco", color: "#FFFFFF", id: "white" },
  { name: "Negro", color: "#000000", id: "black" },
  { name: "Turquesa", color: "#40E0D0", id: "turquoise" },
  { name: "Rosa Pastel", color: "#FFC0CB", id: "light-pink" },
  { name: "Lila", color: "#9370DB", id: "lilac" },
  { name: "Azul Pastel", color: "#87CEFA", id: "light-blue" },
  { name: "Naranja", color: "#FFA500", id: "orange" },
  { name: "Amarilla", color: "#FFFF00", id: "yellow" },
  { name: "Verde Pastel", color: "#BDECB6", id: "light-green" },
  { name: "Rojo", color: "#FF6961", id: "red" },
  { name: "Verde", color: "#76CD26", id: "green" },
  { name: "Fucsia", color: "#CF4C82", id: "fuchsia" },
  { name: 'Violeta', color: '#a33c65', id: 'violet' },
  { name: 'Crema', color: '#ede3af', id: 'cream' },
  { name: 'Burdeos', color: '#b84956', id: 'burgundy' },
  { name: 'Marrón', color: '#AF734F', id: 'brown' },
  { name: 'Turquesa Oscuro', color: '#00b398', id: 'dark-turquoise' },
  { name: 'Azul Marino', color: '#21025a', id: 'navy-blue' },
  { name: 'Verde Botella', color: '#324d32', id: 'bottle-green' },
  { name: 'Salmón', color: '#FFBDA8', id: 'salmon' },
  { name: 'Transparente (purpurina)', color: '#ffffff', id: 'transparent-glitter' },
  { name: 'Verde (purpurina)', color: '#75FFAA98', id: 'green-glitter' },
  { name: 'Azul (purpurina)', color: '#79CAEDA3', id: 'blue-glitter' },
  { name: 'Rosa (purpurina)', color: '#E889B7A8', id: 'pink-glitter' },
];

export const HARD_COVER_COLORS = [
  { name: "Transparente", color: "#FFFFFF", id: "transparent" /*, defaultFront: true */ },
  { name: "Negro (Opaca)", color: "#000000", id: "black" /*,       defaultBack: true */ },
  { name: "Turquesa (Opaca)", color: "#40E0D0", id: "turquoise" },
  { name: "Rosa Pastel (Opaca)", color: "#FFC0CB", id: "light-pink" },
  { name: "Lila Pastel (Opaca)", color: "#9370DB", id: "lilac" },
  { name: "Azul Pastel (Opaca)", color: "#87CEFA", id: "light-blue" },
  { name: "Naranja Pastel (Opaca)", color: "#FFA500", id: "orange" },
  { name: "Salmón", color: "#FFBDA8", id: "salmon" },
  { name: "Naranja", color: "#FFA500", id: "orange" },
  { name: "Amarillo Pastel (Opaca)", color: "#FFFF00", id: "yellow" },
  { name: "Roja (Opaca)", color: "#FF6961", id: "red" },
  { name: "Verde", color: "#76CD26", id: "green" },
  { name: "Fucsia", color: "#CF4C82", id: "fuchsia" },
  { name: 'Verde Pastel', color: '#2ae890', id: 'light-green' },
  { name: 'Marrón', color: '#AF734F', id: 'brown' },
  { name: 'Violeta', color: '#943C8F', id: 'violet' },
  { name: 'Turquesa oscuro', color: '#00b398', id: 'dark-turquoise' },
  { name: 'Crema', color: '#ede3af', id: 'cream' },
  { name: 'Burdeos', color: '#b84956', id: 'burgundy' },
  { name: 'Verde Botella', color: '#324d32', id: 'bottle-green' },
  { name: 'Azul Marino', color: '#21025a', id: 'navy-blue' },
];

export const RING_DIAMETERS = [6, 8, 10, 12, 14, 16, 18, 20, 23, 25, 28, 32, 35, 38, 42, 45, 50];

// export enum OrderStatus {  // TODO: comment this definitions
//   WAITINGFORPAYMENT = 'waitingforpayment',  // order entered from client when PaymentType=TRANSFER. waiting for admin to set it as paid (pending)
//   PENDING = 'pending',  // order paid and pending to be processed (this is usually first step)
//   PROCESSING = 'processing', // order is currently processing
//   FINISHED = 'finished',  // order has finished processing, waiting for dispatch
//   DISPATCHED = 'dispatched',  // order has been dispatched. this is the final step.
//   // other posibilities:
//   BLOCKED = 'blocked',  // waiting for client (questions or something not-payment related)
//   CANCELED = 'canceled',  // order was cancelled while waiting for payment
//   REFUNDED = 'refunded', // order has been refunded (no partial refunds?)
//   FAILED = 'failed'  // order failed from payment (retry available to user?)
// }
/// TODO: definir... credit card --> sub: redsys? stripe, etc?
// export enum PaymentType { TRANSFER = 'transfer', BIZUM = 'bizum', CREDITCARD = 'creditcard',

export enum PaymentType {
  TRANSFER = "transfer",
  BIZUM = "bizum",
  CREDITCARD = "creditcard",
  MONTHLYPAYMENT = "monthlypayment",
  FULLBONUS = "fullbonus",
}

export enum PaymentService {
  REDSYS = "redsys",
} // stripe, etc...

// export enum ShippingType {
//   STANDARD = 'standard',
//   PICKUP = 'pickup'
// } // so far, only this one... these 2
export enum ShippingType {
  ECONOMIC = 'economic',
  STANDARD = "standard", // 24/48 h
  STANDARD_24 = "standard_24", // 24 h (+1€)
  URGENT = "urgent", // next day 14 h (+4€)
  PICKUP = "pickup", // local pick-up
}

export enum OrderType {
  REGULAR = "regular",
  COMPLEMENTARY = "complementary",
}

export enum OrderPaymentStatus {
  PAID = "paid", // order has been paid (from payment gateway reply, or from admin setting it as paid after bank transfer)
  FAILED = "failed", // payment failed (from payment gateway reply, add error message to order)
  PENDING = "pending", // (step 1A) order entered from client when PaymentType=TRANSFER. waiting for admin to set it as paid
  PROCESSING = "processing", // (step 1B) order is being processed by the payment gateway
  REFUNDED = "refunded", // order has been partially or completelly refunded to client (only available after being PAID)
  // (only available after being PAID)
}

export enum OrderProcessStatus {
  PENDING = "pending", // order is still pending to be processed
  PROCESSING = "processing", // order is being processed (set by admin, needs to add admin as 'processedBy')
  COMPLETE = "complete", // order is complete and ready to be dispatched
  SHIPPED = "shipped", // order has been shipped (no matter the transit status)
  CANCELED = "canceled", // order has been canceled and won't be processed
  PAUSED = "paused", // order en pausa
  ONHOLD = "onhold", // put on hold by client (translate labels are swapped with paused)
  RETURNED = "returned", // order has been returned by client
  INCIDENCE = "incidence", // order has an incidence to be solved prior to shipping
}

export enum OrderPrintableStatus {
  PENDING = "pending", // order is still pending to be printed
  PROCESSING = "processing", // order is being printed
  READY = "ready", // order has been fully requested to be printed
  DISCARDED = "discarded", // order has been discarded for printing
}

export enum OrderTypeStatus {
  PROCESS = "processStatus",
  SHIPPING = "shippingStatus",
  PAYMENT = "paymentStatus",
  PAYTYPE = "paymentType",
  PIGTRANSTYPE = "piggyTransactionType",
  PIGTRANSSTATUS = "piggyTransactionStatus",
  PIGTRANSOPT = "piggyTransactionOption",
}

export enum OrderShippingStatus {
  PENDING = "pending", // package still pending to be shipped
  DELIVERED = "delivered", // package has been delivered to client
  SHIPPED = "shipped",
  // ADMITTED = 'admitted', // package admited by the shipping service
  // INTRANSIT = 'inTransit', // package is in transit
  // CANCELED = 'canceled', // shipping has been canceled (before being in transit?)
  // RETURNED = 'returned', // package has been returned by final user (or not received?)
}

export enum OrderPriority {
  // REGULAR = 30,
  // HIGH    = 40,
  // URGENT  = 50,
  URGENT1 = 60, // shipping urgent
  URGENT2 = 50, // shipping priority
  HIGH1 = 45, // client is influencer
  HIGH2 = 40, // client is business
  REGULAR = 30, // shipping 24/48 h
  LOW = 20,
}

export enum PrintFileSubStatus {
  NONE = "none",
  UPLOADING = "uploading",
  CONVERTING = "converting",
  COUNTING = "counting",
  THUMBNAIL = "thumbnail",
}

export enum FeeType {
  SHARE = "share",
  REFERRER = "referrer",
  COUPON = "coupon", // referrer coupon
  CREATOR = "creator",
  ROYALTY = "royalty", // <<===== NUEVO
}

export enum InvoiceType {
  SALE = 'sale',
  PAYMENT = 'payment',
  AUTO = 'auto',
}

export enum InvoiceProcessStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILED = 'failed'
}

export enum InvoicePaymentStatus {
  PENDING = 'pending',
  PAID = 'paid',
}

export enum RefundStatus {
  PAID = 'paid',
  PENDING = 'pending',
  REJECTED = 'rejected'
}

export enum SlugType {
  HARD_COVERS = 'portadas-impresion',
  RINGS = 'anillas-impresion',
  LAMINATED_COVERS = 'fundas-plastificado',
  PACKING_MATERIALS = 'material-de-embalaje',
  PACKAGING_CARDS = 'tarjetas-de-packging',
  PRINTING_FOLIOS = 'folio-impresion'
}

export interface Refund {
  id: string;
  description: string;
  orderId: string;
  orderNumber: string;
  amtBank: number;
  amtWallet: number;
  amtTotal: number;
  status: RefundStatus;
  createdAt: any;
}

export interface Invoice {
  id: string;
  amtBase: number;
  amtTax: number;
  amtTotal: number;
  description: string;
  file: object;
  file2: object;
  lines: object;
  number: string;
  orderId: string;
  type: InvoiceType;
  paymentType: string;
  serial: string;
  status: InvoiceProcessStatus;
  paymentStatus: InvoicePaymentStatus;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Piggy {
  id: string;
  balance: number;
  currency: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum PiggyTransactionType {
  WALLET = "wallet",
  BANK = "bank",
  FEE = "fee",
  ROLLBACK = "rollback",
  EXPIRATION = "expiration",
}

export enum PiggyTransactionStatus {
  PENDING = "pending", // bank, fee
  COMPLETE = "complete", // wallet, bank, fee (like withdrawn), rollback
  REJECTED = "rejected", // bank
  EXPIRED = "expired", // fee
  ERROR = "error", // not even used...
}

export enum PiggyTransactionOption { // why this? it's supposed to be FeeType
  SHARE = "share",
  REFERRER = "referrer",
  COUPON = "coupon", // referrer coupon
  CREATOR = "creator",
  ROYALTY = "royalty", // <<===== NUEVO
}

export enum AcceptanceType {
  PRIVACY = "privacy",
  TOS = "tos",
  REFERRER = "referrer",
  ADS = "ads",
  PRINTPROTECTED = "printProtected",
}

export enum ShippingCourier {
  NONE = "none", // on local pick-up, courier is none
  CEX = "cex", // correos express
  MRW = "mrw", // mrw
  GLS = "gls", // gls
  SENDING = "sending", // sending courier,
  CORREOS = 'correos'
}

// contract name on different couriers
export enum ShippingCourierService {
  NONE = "none", // on local pick-up, courier service is none
  // https://www.correosexpress.com/web/correosexpress/nacional
  CEX_PAQ_24 = "cex_paq_24", // CEX=  standard shipping=  24-48 h
  CEX_PAQ_14 = "cex_paq_14", // CEX=  urgent shipping=  next day <14h
  // https://www.sending.es/servicios/#transporte
  // Sending=  El servicio depende de cual queráis usar para ese envío.
  // El 01 es para Send Exprés, el 02 para Send Top 10h,
  // el 18 para Send Marítimo, y el 17 para Send Internacional Carga
  SENDING_SEND_EXPRESS = "sending_send_express", // SENDING=  next day <14h (01)
  SENDING_SEND_TOP = "sending_send_top", // SENDING=  next day <10h (02)
  MRW_URG_14_EXP = "mrw_urgent_14_expedition",
  MRW_URG_19_EXP = "mrw_urgent_19_expedition",
  MRW_ECOMMERCE = "mrw_ecommerce",
  GLS_BUSINESS_PARCEL = 'gls_business_parcel',
  GLS_EXPRESS = 'gls_express',
  "CORREOS_PREMIUM_DOMICILIO" = "correos_premium_domicilio",
  "CORREOS_PREMIUM_OFICINA" = "correos_premium_oficina",
  "CORREOS_PREMIUM_CITYPAQ" = "correos_premium_citypaq",
  LOCAL = "local",
}

export enum TrackingInternalStatus {
  DELIVERED = "delivered",
  ONDELIVERY = "ondelivery",
  RETURNED = "returned",
  TRIED = "tried",
  INPROGRESS = "inprogress",
  PICKUP = "pickup",
}

export function _getPrintLabels(settings) {
  let labels = [];
  let keys = [
    // force order to be shown
    Settings.PAGE_SIZE,
    Settings.PAPER_WEIGHT,
    Settings.COLOR,
    Settings.TWO_SIDED,
    Settings.PAGES_PER_SHEET,
    Settings.AUTO_ROTATE,
    Settings.PAGE_ORIENTATION,
    Settings.PAGE_TURN,
    Settings.FINISHING,
    Settings.AUTO_ROTATE,
  ];
  const labelSetting = {
    [Settings.PAGE_SIZE]: {
      [PageSize.A3]: "A3",
      [PageSize.A4]: "A4",
      [PageSize.A5]: "A5",
    },
    [Settings.PAPER_WEIGHT]: Object.values(PaperWeight).reduce((acc, e) => {
      acc[e] = `${e}g`;
      return acc;
    }, {}),
    [Settings.PAGE_ORIENTATION]: {
      [PageOrientation.LANDSCAPE]: "Folio: Horizontal",
      [PageOrientation.PORTRAIT]: "Folio: Vertical",
    },
    [Settings.COLOR]: {
      [PrintColor.BLACKNWHITE]: "B/N",
      [PrintColor.COLOR]: "Color",
    },
    [Settings.PAGES_PER_SHEET]: {
      [PagesPerSheet.ONE]: "1 Pág. x Cara",
      [PagesPerSheet.TWO]: "2 Pág. x Cara",
      [PagesPerSheet.FOUR]: "4 Pág. x Cara",
    },
    [Settings.TWO_SIDED]: {
      true: "Doble Cara",
      false: "Una Cara",
    },
    [Settings.PAGE_TURN]: {
      [PageTurn.SHORTSIDE]: "Pasar Pág: Borde Corto",
      [PageTurn.LARGESIDE]: "Pasar Pág: Borde Largo",
    },
    [Settings.FINISHING]: {
      [Finishing.BINDING]: "Encuadernación",
      [Finishing.BINDINGX]: "Encuadernación lado corto", // backward compatibility
      [Finishing.STAPLED]: "Grapado",
      [Finishing.LAMINATED]: "Plastificado",
      [Finishing.LAMINATEDA3]: "Plastificado", // TODO: remove this definition after some time for old orders
      [Finishing.LAMINATEDA4]: "Plastificado", // TODO: remove this definition after some time for old orders
      [Finishing.PERFORATED2]: "Perforado 2 agujeros",
      [Finishing.PERFORATED4]: "Perforado 4 agujeros",
      [Finishing.PERFORATEDBIND]: "Perforado encuadernación",
      [Finishing.NONE]: "Sin Acabado",
    },
    [Settings.GROUPED]: {
      true: "Agrupados",
      false: "Individuales",
    },
    [Settings.COVER_COLOR]: {
      false: "", // no se usa
      true: "Portada a Color",
    },
    [Settings.COVER_LAMINATED]: {
      false: "", // no se usa
      true: "Portada plastificada a color",
    },
    [Settings.DOC_COLOR]: {
      false: "", // no se usa
      true: "Doc. a Color",
    },
    [Settings.AUTO_ROTATE]: {
      false: "Rot. Automática: No",
      true: "Rot. Automática: Sí",
    },
  };

  keys.forEach((e) => {
    if (settings[e] === undefined) {
      return; // backward compatible: setting may not exist in old orders
    }
    let label;
    label = labelSetting[e][settings[e]];
    if ((e == Settings.COLOR && settings[Settings.COLOR] == PrintColor.BLACKNWHITE) || settings[Settings.COVER_LAMINATED]) {
      // some times settings[Settings.COVER_COLOR] = true even though PrintColor.COLOR!
      if (settings[Settings.COVER_COLOR] && settings[Settings.DOC_COLOR] && !settings[Settings.COVER_LAMINATED]) {
        label += ` (Mixto)`;
      } else if (settings[Settings.COVER_COLOR] && settings[Settings.DOC_COLOR] && settings[Settings.COVER_LAMINATED]) {
        label += ` (Mixto y portada plastificada)`;
      } else if (settings[Settings.COVER_LAMINATED]) {
        label += ` (${labelSetting[Settings.COVER_LAMINATED][settings[Settings.COVER_LAMINATED]]})`;
      } else if (settings[Settings.COVER_COLOR]) {
        label += ` (${labelSetting[Settings.COVER_COLOR][settings[Settings.COVER_COLOR]]})`;
      } else if (settings[Settings.DOC_COLOR]) {
        label += ` (${labelSetting[Settings.DOC_COLOR][settings[Settings.DOC_COLOR]]})`;
      }
    } // this previous block is not used anymore (in admin at least)
    if (e == Settings.FINISHING && (settings[Settings.FINISHING] == Finishing.BINDING || settings[Settings.FINISHING] == Finishing.BINDINGX)) {
      // backward compatible: when grouped setting didn't exist and default was grouped
      const grouped = settings[Settings.GROUPED] == true || settings[Settings.GROUPED] === undefined;
      const _labels = [];
      _labels.push(labelSetting[Settings.GROUPED][grouped.toString()]);
      if (settings[Settings.RING_COLOR]) {
        // _labels.push(`${settings[Settings.RING_COLOR]?.name}`);
        _labels.push(`A: ${settings[Settings.RING_COLOR]?.name}`);
      }
      if (settings[Settings.HARD_COVER_FRONT]) {
        // _labels.push(`${settings[Settings.HARD_COVER_FRONT].name}`);
        _labels.push(`T. Delantera: ${settings[Settings.HARD_COVER_FRONT].name}`);
      }
      if (settings[Settings.HARD_COVER_BACK]) {
        // _labels.push(`${settings[Settings.HARD_COVER_BACK].name}`);
        _labels.push(`T. Trasera: ${settings[Settings.HARD_COVER_BACK].name}`);
      }
      label += ` (${_labels.join(", ")})`;
    }
    labels.push(label);
  });
  return labels;
}

/*

Client Procedure:

1.- Sign in (anonymous or Google Facebook)
  - Update user record (collection: users)
  - Register token when available (collection: users.tokens). (after purchase?)
2.- Add to cart:
  - Cart is to be stored in Firebase (collection: carts) on every change
  - Files need to be uploaded to storage, and result stored in DB temporarily (collection: temporaryUpload)
  - Files removed from cart? -> if possible, remove documents from DB (collection: temporaryUpload)
  - Adding a Binding to a printingGroup, adds the product Binding to the item list. (PrintingGroup has got a parent/child reference)
  - Binding product is always a child item. Child items can't be removed aloone. Only parents.
  2.1.- After uploading to temporary storage:
    - Add document to collection: temporaryUpload (PrintFile). Only needs: createdAt, updatedAt, originalFile, status = NEW.
    - Listen to document and wait for changes on "status". If FINISHED, "pages" and "originalType" will be available.
    - If originalType = OTHER, file type is unsupported. Don't allow order to be placed until this item is removed
3.- Place Order:
  - Transactionally update doc: settings.nextOrderNumber to get order number
  - Create order with all required fields (items[] and price from cart)



Server Procedure:

* onCreateTemporaryUploads:
- if (!pdf), call ilovepdf API
- get file and upload to storage
- count pages
- update document with new data (including updatedAt)

* onCreateOrder:
- Remove cart document (from cartId)

* onWriteOrder:
(check on status change to send notifications)

*/

export function getPrintGroupJobType(printingGroup: PrintingGroup) {
  const printSettings = printingGroup.printSettings;
  const color = printSettings.color;
  // const coverColor = printSettings.coverColor; // these 2 are not properly being set from app. calculate locally
  // const docColor = printSettings.docColor;
  const files = printingGroup.files || [];
  // const coverColor = files.some(f => {
  //   return f.coverType?.coverColor ?? f.coverColor ?? false; // consider old orders
  // });
  const coverColor = files.some(f => {
    const {
      coverType, isCoverDefault, isDepaginated,
      wouldNeedSeparateCover, needsSeparateCover,
      separateCoverSides, separateCoverPages, isDocCoverOnly,
      needsColorDoc, needsBnDoc, needsTraceCover,
    } = getPrintFileCoverSettings(f, printSettings);
    // console.log(` F${findex} needsBnDoc:${needsBnDoc} needsSeparateCover:${needsSeparateCover} coverType.coverColor:${coverType.coverColor} isDocCoverOnly:${isDocCoverOnly}`);
    return needsSeparateCover && coverType.coverColor;
    // return needsBnDoc && needsSeparateCover && coverType.coverColor;
  });
  const docColor = color == PrintColor.BLACKNWHITE
    ? files.some(e => e.docColor) // bn group: docColor:true means there's at least 1 color doc
    : files.every(e => e.docColor); // color group: docColor:false means there's at least 1 bn doc


  let printJobType = PrintJob.Type.MIXED;

  // print colors:
  // decide from the 3 cases: B/N exclusive, Color exclusive, Mixed
  // now docColor represents:
  // - in color group: docColor:false means there's at least 1 bn file
  // - in bn group: docColor:true means there's at least 1 color file
  if (color == PrintColor.COLOR) {
    if (docColor) {
      // full color - no bn docs in color group
      printJobType = PrintJob.Type.COLOR; // C
    } else {
      // some bn docs in a color group
      const allBn = files.every(f => !f.docColor);
      if (allBn) {
        // they're actually all bn (evaluate like a bn group)
        if (coverColor) {
          printJobType = PrintJob.Type.MIXED; // M2
        } else {
          printJobType = PrintJob.Type.BN; // N
        }
      } else {
        // some bn docs in color group
        if (coverColor) {
          printJobType = PrintJob.Type.MIXED; // M3
        } else {
          printJobType = PrintJob.Type.MIXED; // M4
        }
      }
    }
  } else { // color == 'bn'
    if (!docColor) {
      // no color docs
      if (coverColor) {
        printJobType = PrintJob.Type.MIXED; // M2
      } else {
        printJobType = PrintJob.Type.BN; // N
      }
    } else {
      // some color docs in a bn group
      const allColor = files.every(f => f.docColor);
      if (allColor) {
        // they're all color docs
        printJobType = PrintJob.Type.COLOR; // C
      } else {
        if (coverColor) {
          printJobType = PrintJob.Type.MIXED; // M3
        } else {
          printJobType = PrintJob.Type.MIXED; // M4
        }
      }
    }

  }
  return printJobType;
}

export function getPrintFileJobType(file: _PrintFile, printSettings: PrintSettings) {
  // const color = printSettings.color;
  const coverColor = file.coverType?.coverColor ?? file.coverColor ?? false;
  const docColor = file.docColor;
  // const coverLaminated = file.coverLaminated; // not needed, handled by coverColor

  let printJobType = PrintJob.Type.MIXED;

  // new: now docColor forces file color, no matter global setting

  if (docColor) {
    printJobType = PrintJob.Type.COLOR; // full color
  } else {
    if (coverColor) {
      printJobType = PrintJob.Type.MIXED; // bn with cover
    } else {
      printJobType = PrintJob.Type.BN; // bn only
    }
  }

  return printJobType;
}
