import { UiService } from '@a2system/angular/material';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Format } from 'src/app/models/models';
import { PrintRequirement } from 'src/app/models/printer-model';
import { PrintersService } from 'src/app/shared/printers.service';
import { ImpresionService } from '../impresion.service';

@Component({
  selector: 'app-filtered-requirements',
  templateUrl: './filtered-requirements.component.html',
  styleUrls: ['./filtered-requirements.component.scss']
})
export class FilteredRequirementsComponent implements OnInit {
  @Input() filteredRequirements$: Observable<Partial<PrintRequirement>[]>;
  @Input() type: 'process' | 'problem' | 'tray';

  displayedColumns = ['orderDate', 'priority', 'orderNumber', 'contains', 'bundles', 'createdAt', 'actions']
  format: typeof Format = Format;
  constructor(
    private printersService: PrintersService,
    private impresionService: ImpresionService,
    private ui: UiService,
  ) { }

  ngOnInit(): void {
  }
  onDelete(requirement) {
    this.printersService.deleteRequirement(requirement);
  }
  async onSetPending(requirement, printableStatusErrored = false) {
    // console.log({requirement, printableStatusErrored});
    const resp = await this.ui.confirmDialog({
      title: "Confirmación",
      message: `¿Pasar el pedido #${requirement.order.number} a ${printableStatusErrored ? "Pendiente y Bloqueado" : "Pendiente"}?"`
    }).toPromise();

    if (resp) {
      // console.log(resp);
      const orderId = requirement.order.id;
      let requirements = await this.impresionService.printerRequirements$.pipe(take(1)).toPromise();
      requirements = requirements.filter(e => e.order?.id == orderId);
      this.printersService.resetOrderPrintStatus(orderId, requirements, printableStatusErrored);
    }
  }
  async onDeleteAll(filteredRequirements) {
    const resp = await this.ui.confirmDialog({
      title: "Eliminar Todos los completados",
      message: "¿Seguro que quieres eliminar todos los requerimientos Completados?"
    }).toPromise()
    if (resp) {
      this.ui.spinner.show();
      this.printersService.deleteRequirements(filteredRequirements)
        .then(res => {
          this.ui.spinner.reset();
        })
        .catch(error => {
          this.ui.spinner.reset();
          this.ui.toast('Ha ocurrido un error.', 5000);
        });
    }
  }

  trackById(index: number, item: any) {
    return item.id
  }
}
