<!-- <div class="p-7" fxLayout="column"> -->
<!-- <div class="mat-small"></div> -->
<mat-form-field appearance="fill" fxLayout="column" style="margin: 0 10px;">
  <mat-label>Grupos de impresión</mat-label>
  <mat-select [formControl]="groupIndex" #select>
    <mat-select-trigger>
      <app-print-partial-group-info *ngIf="groupData[select.value]"
        [element]="groupData[select.value]"></app-print-partial-group-info>
    </mat-select-trigger>
    <mat-option *ngFor="let group of groupData" [value]="group.id" id="id">
      {{group.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
<!-- </div> -->
<table #tableData mat-table [dataSource]="files$">
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
        [disabled]="!files.length">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- info Column -->
  <ng-container matColumnDef="info">
    <th mat-header-cell *matHeaderCellDef>
      Archivos del grupo
    </th>
    <td mat-cell *matCellDef="let element">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <img [src]="element.thumbFile.url" width="48">
        <!-- element.thu -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <div class="pelotica" [ngClass]="element.peloticaColor"></div>
          <b>F{{element.index + 1}}</b>
          <div class="pt-5 pb-5" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
            <a target="_blank" [href]="element.originalFile.url">{{element.printFile.name}}</a>
            <div *ngIf="element.coverTags?.length > 0" class="caption">
              <span *ngIf="element.coverTagsHl?.length > 0" class="cover-color-hl">{{element.coverTagsHl.join(' - ')}}</span>
              <span *ngIf="element.coverTagsRegular?.length > 0"> - {{element.coverTagsRegular.join(' - ')}}</span>
            </div>
          </div>
          <span class="caption">({{element.pages}} {{element.pages == 1 ? 'página' : 'páginas'}})</span>
        </div>
      </div>
    </td>
  </ng-container>
  <tbody>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
    <tr *matNoDataRow>
      <div class="caption p-40">No hay archivos para imprimir con esta configuración</div>
    </tr>
  </tbody>
</table>