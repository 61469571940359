import { Component } from '@angular/core';
import { AppService } from './app.service';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'copi-admin';


  constructor(
    private appService: AppService,
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    translate.setDefaultLang('es');
    translate.use('es');
    this.matIconRegistry.addSvgIcon(
      `tracking_courier`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/tracking_courier.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `tracking_copyfly`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/tracking_copyfly.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `tracking_incidence`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/tracking_incidence.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `lista_de_tablas`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/lista_de_tablas.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `pedidos_a4`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/pedidos_a4.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `pedidos_f`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/pedidos_f.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `pedidos_ppl`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/pedidos_ppl.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `print_done`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/print_done.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `print_pending`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/print_pending.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `turtle`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/tortuga.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `grapado`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/grapado.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `plastificado`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/plastificado.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `perforado`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/perforado.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `refund`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/refund.svg")
    );
  }
}
