import { Component, OnInit, Input, } from '@angular/core';
import { isNil } from 'lodash'
import { AcceptanceType } from '../../models/models';


/**
 * VisualAcceptanceStatusComponent representa el icono a mostrar con su contenido
 */
@Component({
  selector: 'app-visual-acceptance-status',
  templateUrl: './visual-acceptance-status.component.html',
  styleUrls: ['./visual-acceptance-status.component.scss']
})
export class VisualAcceptanceStatusComponent implements OnInit {

  @Input() key: AcceptanceType;
  @Input() showTitle: boolean = true; // por defecto lo muestra
  @Input() set acceptance(acceptance) {
    this.visualAceptances.key = this.key;
    this._acceptance = acceptance;
    this.SetAcceptance();
    // this['loadCount']++;
    // console.log(this.loadCount, this.key, acceptance);
  }

  ['loadCount'] = 0;
  // TypeOf
  acceptanceType = AcceptanceType as typeof AcceptanceType;
  //END TypeOf

  _acceptance = null;

  // visualAceptances: VisualAcceptances;
  visualAceptances: VisualAcceptances = {
    key: null,
    // key: this.key,
    title: null,
  }


  public readonly ['visualTitles'] = {
    [AcceptanceType.PRIVACY]: "Política de Privacidad",
    [AcceptanceType.TOS]: "Términos de Servicio",
    [AcceptanceType.REFERRER]: "Términos de Referrer",
    [AcceptanceType.ADS]: "Publicidad",
    [AcceptanceType.PRINTPROTECTED]: "Archivos protegidos",
  }


  ngOnInit(): void {
    //coloco por defecto algo
    this.SetAcceptance();
  }

  /**
   * Sets the aceptance of the clients
   */
  SetAcceptance() {
    const key: string = this.visualAceptances.key;
    const _acceptance = this._acceptance;
    this.visualAceptances.title = this['visualTitles'][key];

    // console.log(_acceptance, !isNil(_acceptance[key]) );
    //si hay aceptance, si hay campo y  el valor no es undefined o null
    if (!isNil(_acceptance) && (key in _acceptance) && !isNil(_acceptance[key])) {

      const _value = _acceptance[key];

      this.visualAceptances.value = _value;
      this.visualAceptances.icon = _value ? 'check_circle_outline' : 'do_not_disturb_alt';
      this.visualAceptances.color = _value ? 'primary' : 'warn';
      this.visualAceptances.tooltip = _value ? 'Aceptado' : 'Denegado'

    } else {

      //eres tipo NULL/No existente, sin campo
      this.visualAceptances.value = null;
      this.visualAceptances.icon = "remove_circle_outline"
      this.visualAceptances.color = "";
      this.visualAceptances.tooltip = 'Pendiente'

    }
  }

}

export interface VisualAcceptances {
  key: AcceptanceType, //parametro con el cual buscaremos
  title: string, // campo mostrado en el template
  value?: boolean, // resultado del estado actual del cliente en su aceptacion
  icon?: string, // estado actual visualmente en un ico
  color?: string,
  tooltip?: string,
}
