import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ImpresionContentFilterType, ImpresionService } from './impresion.service';
import { UserPrintersDialogComponent } from './user-printers-dialog/user-printers-dialog.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-impresion',
  templateUrl: './impresion.component.html',
  styleUrls: ['./impresion.component.scss'],
})
export class ImpresionComponent implements OnInit {
  onlyPrinterCards = false;
  impresionContentFilterType: ImpresionContentFilterType = ImpresionContentFilterType.A4;

  userPrintersStatus$ = this.impresionService.printerStatus$;
  availablePrinters$ = this.impresionService.availablePrinters$;

  printRequirementsProcess$ = this.impresionService.printRequirementsProcess$;
  printRequirementsProblem$ = this.impresionService.printRequirementsProblem$;
  printRequirementsTray$ = this.impresionService.printRequirementsTray$;

  constructor(
    private impresionService: ImpresionService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    // console.log(`=======>>> ImpresionComponent ${JSON.stringify(this.route.parent.snapshot.url[0]?.path)}`);
    const currentPath = this.route.parent.snapshot.url[0]?.path;
    switch (currentPath) {
      case 'impresion': { this.impresionContentFilterType = ImpresionContentFilterType.A4 } break;
      case 'impresion-f': { this.impresionContentFilterType = ImpresionContentFilterType.F } break;
      case 'impresion-ppl': { this.impresionContentFilterType = ImpresionContentFilterType.PPL } break;
      default: { this.impresionContentFilterType = ImpresionContentFilterType.A4 } break;
    }
    // configure filter type before observables trigger
    this.impresionService.impresionContentFilterType = this.impresionContentFilterType;
  }

  ngOnDestroy() {
    // console.log(`=======>>> ImpresionComponent ON DESTROY`, this.impresionContentFilterType);
  }


  onSelectPrinters() {
    const dialogRef = this.dialog.open(UserPrintersDialogComponent, {
      width: '420px',
      data: { availablePrinters$: this.availablePrinters$ },
    });
  }

  changeOnlyPrinterCards() {
    this.onlyPrinterCards = !this.onlyPrinterCards;
    this.changeDetector.detectChanges();
  }
}

