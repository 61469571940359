import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { Setting } from 'projects/shared-lib/src/lib/models';
import { Observable } from 'rxjs';


export type IndexCollection = 'general' | 'admin' | 'server' | 'banners';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private collection: AngularFirestoreCollection<any>;
  public readonly generalIndex: IndexCollection = 'general'; //nombre del documento general;



  constructor(
    private db: AngularFirestore
    
  ) {
    this.collection = this.db.collection<any>('settings');
  }
  /**
   * 
   * Crear un actualizador en setting.component
   * llamado algo como Last Edition que sea un timestamp y que te puedas 
   */
 


  get(id: string): Observable<any>{
    return this.collection.doc<any>(id).valueChanges();
  }

  upsert(uData): Promise<any> {
    const {id, ...data} = uData;// aquí se tiene la info...
    // console.log("Actualizando en: ", id);
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    return (id)
    ? this.collection.doc(id).update(data)
    : this.collection.add(data);
  }

  /**
   * gets one of the specified collections
   */
  getSettings(index: IndexCollection): Observable<any>{
    return this.collection.doc(index)
    .valueChanges().pipe()
  }

  getBanners$(): Observable<Setting.AppBanners>{
    return  this.get("banners");
  }
  saveBanner(options){
    const {page, banner, bannerImages} = options;
    const data ={
      id: 'banners',
      [page+'.'+banner]: bannerImages
    }
    return this.upsert(data);
  }

  updateFees(value) {
    const data = {
      fees: value,
      updatedAt: firestore.FieldValue.serverTimestamp()
    };

    return this.collection.doc(this.generalIndex).update(data);
  }
}
