import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { ElasticSearchService } from '@a2system/angular/common'

import { Coupon, UserCoupon } from '../models/coupon.model';
import { SettingService } from "./setting.service";

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  couponArr;
  private readonly _coupons = new BehaviorSubject<Coupon[]>([]);
  readonly coupons$: Observable<Coupon[]> = this._coupons.asObservable();

  private collection: AngularFirestoreCollection<Coupon>;

  constructor(
    private db: AngularFirestore,
    private es: ElasticSearchService,
    private settingService: SettingService
  ) {
    this.collection = this.db.collection<Coupon>('coupons');
  }

  //called in app init
  async loadData() {
    // this.coupons$ = this.getAll();
    // this.coupons$.subscribe(c => this.couponArr = c)
    this.getAll().subscribe(this._coupons);
  }

  $get(id): Observable<Coupon> {
    return this.collection
      .doc<Coupon>(id)
      .valueChanges()
      .pipe(
        map(coupon => {
          coupon.startDate = coupon.startDate ? coupon.startDate.toDate() : coupon.startDate
          coupon.expirationDate = coupon.expirationDate ? coupon.expirationDate.toDate() : coupon.expirationDate
          return coupon
        })
      )
  }

  getAll(): Observable<Coupon[]> {
    return this.collection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Coupon;
        const id = a.payload.doc.id;
//        console.log(data);
        return { id, ...data };
      }))
    )
  }

  delete(id: string) {
    return new Promise((resolve, reject) => {
      this.settingService.getSettings('general').subscribe(generalSettings => {
        console.log('settings')
        const couponLevels = generalSettings.couponLevels;
        const couponLevelExists = couponLevels.find(c => c.coupon === id);
        if (couponLevelExists) {
          console.log('reject')
          reject('Este cupón no puede borrarse porque está asignado a un nivel de embajador.');
        } else {
          resolve(this.collection.doc<Coupon>(id).delete());
        }
      });
    });
  }

  upsert(_data, _id): Promise<any> {
    let { id, ...data } = _data;
    if (!id) {
      data.createdAt = firestore.FieldValue.serverTimestamp();
      id = _id
    }
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    return (id)
      ? this.collection.doc(id).set(data, { merge: true })
      : this.collection.add(data);
  }

  async query(paginator, sort, filter, options?) {
    const index = 'coupons'
    const extras = {
      code: {
        type: "wildcard"
      },
      startDate: {
        type: "dateRange"
      },
      expirationDate: {
        type: "dateRange"
      },
      user_id: {
        type: "wildcard"
      },
    }
    let _options: any = null;
    if (options?.body) {
      _options = {
        body: true
      }
    }
    // convertimos los parámetros al formato de ElasticSearch
    const body = this.es.mtToBody(paginator, sort, filter, extras);
    let _body = body as any;
    // console.log({filter, _body});
    ///⛔️ PATCH - ask only for _source fields. this should be part of mtToBody

    //No hace falta por que usamos casi todos los documentos
    // _body._source=[
    //   'code','title','type','amount','usage','limit','amtOrders','amtDiscounts',
    //   'amtFees','startDate','expirationDate','firstPurchaseOnly','private',
    //   'freeShipping', 'multiUsage','enabled','user',
    //   'coupon.id','category','dates',
    //   'label','status','makeModel','colorSupported','firstBuy',
    //   'createdAt','updatedAt'
    // ]
    /// PATCH - END ⛔️

    //debe retornar a Datasource los registros
    return this.es.query(index, _body, (item) => {
      item.createdAt = new Date(item.createdAt._seconds * 1000);
      item.startDate = item.startDate ? new Date(item.startDate._seconds * 1000) : null;
      item.expirationDate = item.expirationDate ? new Date(item.expirationDate._seconds * 1000) : null;
      return item;
    },);
  }

  getCoupons(clientId) {
    return this.db.collection('users').doc(clientId)
      .collection('usercoupons').valueChanges().pipe()
  }

  setCoupon(coupon, clientId) {

    let {
      title,
      firstPurchaseOnly,
      startDate,
      expirationDate,
      createdAt,
      code,
      currency,
      type,
      amount,
      recurrent,
      ...maxAmount
    } = coupon;
    let data: UserCoupon = {
      title: title,
      firstPurchaseOnly: firstPurchaseOnly,
      startDate: startDate,
      expirationDate: expirationDate,
      createdAt: new Date(),
      // description: coupon.description,
      code: coupon.code,
      currency: coupon.currency,
      type: coupon.type,
      amount: coupon.amount,
      recurrent: coupon.recurrent,
      maxAmount: coupon.maxAmount || null
    }
    console.log('data', data)
    // console.log( coupon.code + " AÑADIDO: ",data);    
    return this.db.collection('users').doc(clientId)
      .collection('usercoupons').doc(coupon.code).set({ ...data })
  }

  removeCoupon(couponCode, clientId) {
    return this.db.collection('users').doc(clientId)
      .collection('usercoupons').doc(couponCode)
      .delete()
      .then(
        () => {
          // console.log("ELIMINADO : " + couponCode );
        }
      )
  }

  /**
   * Find the first refferred coupon in 'coupon' collection
   *
   */
  public firstReferredCoupon(ReferrerId): Observable<Coupon> {

    return this.db.collection<Coupon>('coupons', ref =>
      ref.limit(1)
        .where('user.id', '==', ReferrerId)
    )
      .valueChanges()
      .pipe(map((ref) => ref[0]))
  }


  onChange(): Observable<any> {
    return this.db
      .collection<any>('refresh')
      .doc('coupons')
      .valueChanges();
  }
}
