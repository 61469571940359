<div fxLayout="column" class="bundle-card mat-elevation-z1" fxLayoutGap="3px" fxLayoutAlign="start stretch">

  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px">
    <!-- printer title -->
    <div class="title" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
      <div class="pelotica" [ngClass]="peloticaColor"></div>
      <div>{{bundle.printer.label}}</div>
    </div>

    <ng-container *ngIf="bundle.retryStatus">
      <div [class]="'retry bundle-' + bundle.status" [ngClass]="bundle.retryStatus" [matTooltip]="retryTooltip">
        Reintentado
      </div>
    </ng-container>
    <ng-container *ngIf="!!bundle.retriedFrom">
      <div class="retry retried-from" [matTooltip]="retryTooltip">
        Reintento
      </div>
    </ng-container>
  </div>

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
    <!-- sheet and counters -->
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <div class="page-size" fxLayout="column" fxLayoutGap="0px" fxLayoutAlign="center center"
        [ngClass]="{ 'page-size-forced': !!bundle.pageSizeForced }">
        <div *ngIf="bundle.pageSizeForced" class="page-size-from">{{bundle.pageSize}} en</div>
        <div>{{bundle.pageSizeForced ?? bundle.pageSize}}</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start">
        <div *ngFor="let paperWeight of paperWeightValues" fxLayout="row" fxLayoutGap="10px"
          fxLayoutAlign="space-between center">
          <ng-container *ngIf="bundle?.printSheetsByWeight?.[paperWeight] || paperWeight == PaperWeight.W80">
            <div class="paper-weight">{{paperWeight}}g</div>
            <div class="counter">{{bundle?.printSheetsByWeight?.[paperWeight] ?? 0}}</div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- status and caption-->
    <div fxLayout="column" fxLayoutAlign="center end" fxLayoutGap="2px">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
        <ng-container *ngIf="bundle.status == BundleStatus.FAILED">
          <button *ngIf="bundle.error?.name != 'ProcessError'" mat-icon-button class="sm-btn no-padding" color="warn"
            (click)="onShowPrintJobs()" matTooltip="Error de impresión">
            <mat-icon>print</mat-icon>
          </button>
          <button *ngIf="bundle.error?.name == 'ProcessError'" mat-icon-button class="sm-btn no-padding" color="warn"
            matTooltip="Error de procesamiento de archivos" disabled="true">
            <mat-icon>settings</mat-icon>
          </button>
        </ng-container>
        <div class="bundle-status">{{"BUNDLESTATUS." + bundle?.status | uppercase | translate}}</div>
      </div>
      <div *ngIf="caption" class="caption side-caption single-line-ellipsis" [matTooltip]="caption"
        matTooltipPosition="below" matTooltipShowDelay="500">{{caption}}</div>
    </div>
  </div>

  <!-- caption on error -->
  <div *ngIf="captionBottom" class="caption" [innerHTML]="captionBottom"></div>
</div>