<div mat-dialog-title *ngIf="requirement$ | async; let requirement" fxLayout="column" fxLayoutGap="0px">
  <div fxLayout="row" fxLayoutGap="14px">
    <div>Trabajos de impresión</div>
    <a [routerLink]="['/admin/orders/',requirement.order.id]" target="_blank">
      #{{requirement.order.number.toString().padStart(4, '0')}}
    </a>
    <div class="order-contains">
      <span *ngFor="let content of requirement.order.contains" class="shape" [ngClass]="content"
        [matTooltip]="(('ORDER.CONTENT.SHAPE.'+content)| translate)">
        {{content}}
      </span>
    </div>
  </div>
  
  
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <!-- printer title -->
    <div class="title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
      <div class="pelotica" [ngClass]="peloticaColor"></div>
      <div>{{bundle.printer.label}}</div>
    </div>
    <!-- finished switch -->
    <mat-slide-toggle [(ngModel)]="hideCompleted" class="untitle" (change)="onCompletedToggle($event)">
      Ocultar completados
    </mat-slide-toggle>
  </div>
</div>

<mat-dialog-content>
  <mat-table #tableData [dataSource]="printJobs2$ | async">

    <!-- queue order -->
    <ng-container matColumnDef="queueOrder">
      <mat-header-cell *matHeaderCellDef>
        <div>Orden de Impresión</div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>{{row.queueOrder + 1}}</div>
      </mat-cell>
    </ng-container>
    <!-- file info -->
    <ng-container matColumnDef="file-info">
      <mat-header-cell *matHeaderCellDef>
        <div>Nombre del Trabajo</div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <div class="pelotica" [ngClass]="_peloticaColor(row.printJobType)"></div>
            <div class="single-line-ellipsis">{{row.name}}</div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="ml-15">
            <span class="caption">
              {{row.printSettings.copies}} {{row.printSettings.copies == 1 ? 'duplicado' : 'duplicados'}}
              x
              {{row.printSides}} {{row.printSides == 1 ? 'página' : 'páginas'}}
            </span>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <!-- queue status -->
    <ng-container matColumnDef="queueStatus">
      <mat-header-cell *matHeaderCellDef>
        <div>Enviado a la Impresora</div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div [ngSwitch]="row.queueStatus">
          <ng-template [ngSwitchCase]="PrintJobQueueStatus.FINISHED">
            <mat-icon class="small" [ngClass]="row.queueStatus" matTooltip="Completado">
              done
            </mat-icon>
          </ng-template>
          <ng-template [ngSwitchCase]="PrintJobQueueStatus.INPROGRESS">
            <mat-icon class="small" [ngClass]="row.queueStatus" matTooltip="En proceso de envío">
              start
            </mat-icon>
          </ng-template>
          <ng-template ngSwitchDefault>
            <mat-icon class="small" [ngClass]="row.queueStatus" matTooltip="Pendiente por enviar">
              scheduled
            </mat-icon>
          </ng-template>
        </div>
      </mat-cell>
    </ng-container>
    <!-- print status -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        <div>Estado de Impresión</div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="print-status-color" [ngClass]="row.status">
          {{'PRINT.JOB.STATUS.' + row.status | uppercase | translate }}
        </div>
      </mat-cell>
    </ng-container>
    <!-- job id -->
    <ng-container matColumnDef="jobId">
      <mat-header-cell *matHeaderCellDef>
        <div>Identificador en la Impresora</div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>{{row.jobId ?? '-'}}</div>
      </mat-cell>
    </ng-container>
    <!-- actions -->
    <!-- <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>Imprimir</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button color="primary" [matTooltip]="'Imprimir'"
              (click)="onPrintFile(row)">
              <mat-icon>edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container> -->

    <tbody>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </tbody>
  </mat-table>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    Cerrar
  </button>
</div>