<mat-form-field appearance="outline">
  <mat-select [formControl]="control" 
    [disabled]="disabled"
  >
    <mat-select-trigger >
      <ng-container *ngIf="iconTrigger">
        <mat-icon>{{iconTrigger}}</mat-icon>
        &nbsp;
      </ng-container>
      {{currentValue ?? defaultValue}}
    </mat-select-trigger>
    <mat-option *ngFor="let printer of options" 
      [value]="printer" 
      (onSelectionChange)="emitAction(callbackName,printer)"
    >
      <mat-icon>print</mat-icon>
      <span>{{getPropOf(printer)}}</span>
    </mat-option>
  </mat-select>
</mat-form-field>