import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moreInfo'
})
export class MoreInfoPipe implements PipeTransform {

  private readonly defaultInfoText:string =` y  <> más`;
  private readonly defaultKeyDetection:string= "<>";

  transform(_infos: Array<string>, _moreInfoText?: string, _keyDetection?:string): unknown {
    return this.reduceDetection(_infos, (_moreInfoText ?? this.defaultInfoText), _keyDetection ?? this.defaultKeyDetection );
  }


  /**
   * Based on the qty of elements decide to show 1 or more infos, if exceed the limit then it shows a message 
   * @returns 
   */
  reduceDetection(_infos: Array<string>, extraInfo:string, _keyDetection:string){

    let message = "-";

    switch (_infos?.length) {
      case 0:
      case null:
      case undefined:
        break;
      case 1:
      case 2:
        message = _infos.reduce((acc,e) => `${acc} y ${e}`);
        break;
      default:

        extraInfo = extraInfo.replace(_keyDetection, (_infos.length - 1).toString());
        // más de 3
        message = `${_infos[0]} ${extraInfo}`;
        break;
    }

    return message;
  }

}
