<h2 class="text-center" mat-dialog-title>{{ data.title }}</h2>
<h4 *ngIf="data.subtitle" mat-dialog-title>{{ data.subtitle }}</h4>
<mat-dialog-content *ngIf="data.message">
  {{ data.message }}
  <br/><br/>
</mat-dialog-content>
<mat-dialog-actions style="padding-bottom: 20px;" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
  <button *ngIf="!data?.removeCancel" mat-stroked-button mat-dialog-close
          color="warn">{{ data?.noBtn ? data.noBtn : default_noBtn }}
  </button>
  <button mat-stroked-button [mat-dialog-close]='true' color="primary">
    {{ data?.okBtn ? data.okBtn : default_okBtn }}
  </button>
</mat-dialog-actions>
