import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UiService } from "../../shared/ui.service";
import { RefundService } from "../../shared/refund.service";
import { RefundStatus, UpdatedBy } from "../../models/models";
import { UserService } from "../../core/user.service";
import { ActivityLog } from "../../models/activity-log.model";
import { ActivityLogService } from "../../shared/activity-log.service";

@Component({
  selector: 'app-generate-refund-dialog',
  templateUrl: './generate-refund-dialog.component.html',
  styleUrls: ['./generate-refund-dialog.component.scss']
})
export class GenerateRefundDialogComponent implements OnInit {

  bankSelected = false;
  walletSelected = false;
  amountWallet = 0;
  amountBank = 0;
  amount = 0;
  description = '';
  errMsg = '';
  fullBonus = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>, private ui: UiService, private refundService: RefundService,
              private userService: UserService, private activityLogService: ActivityLogService) {
  }

  ngOnInit(): void {
    this.fullBonus = this.data.order.paymentType === 'fullbonus';
    if (this.fullBonus) {
      this.data.maxRefundAmt = Math.abs(this.data.order.cart.discounts[0].amount);
    }
  }

  handleChanges() {
    if (!this.bankSelected || !this.walletSelected) {
      if (this.bankSelected) {
        this.amountBank = this.amount;
        this.amountWallet = 0;
      } else {
        this.amountWallet = this.amount;
        this.amountBank = 0;
      }
    }
    this.checkErrors();
  }

  checkErrors() {
    this.errMsg = '';
    if (!this.amountIsCorrect()) {
      this.errMsg = `La cantidad a reembolsar excede el importe máximo reembolsable (${this.data.maxRefundAmt} €) o no es correcta`;
    } else if (!this.description) {
      this.errMsg = 'Añade una descripción';
    } else if (!this.bankSelected && !this.walletSelected) {
      this.errMsg = 'Selecciona algún tipo de reembolso';
    }
  }

  async confirm() {
    this.ui.spinner.show();
    const refund = {
      description: this.description,
      amtBank: this.amountBank,
      amtWallet: this.amountWallet,
      amtTotal: this.amountBank + this.amountWallet,
      status: RefundStatus.PENDING,
      orderId: this.data.order.id,
      orderNumber: this.data.order.number,
      orderDate: this.data.order.createdAt,
      order: {
        id: this.data.order.id,
        number: this.data.order.number,
        createdAt: this.data.order.createdAt
      },
      createdBy: {
        id: this.userService.userG.id,
        displayName: this.userService.userG.displayName
      }
    };
    await this.refundService.upsert(refund);

    this.activityLogService.upsert({
      ref: {
        collection: 'orders',
        id: this.data.order.id
      },
      user: {
        id: this.userService.userG.id,
        displayName: this.userService.userG.displayName,
      },
      type: ActivityLog.Type.COMMENT,
      from: UpdatedBy.From.ADMIN,
      payload: {
        comment: `Solicitud de reembolso: ${this.description}`
      }
    });

    this.ui.spinner.reset();
    this.dialogRef.close(true);
  }

  canConfirm() {
    if (this.bankSelected && this.walletSelected) {
      return this.amountBank && this.amountWallet && !this.errMsg;
    } else if (this.bankSelected) {
      return !!this.amountBank && !this.errMsg;
    } else if (this.walletSelected) {
      return !!this.amountWallet && !this.errMsg;
    } else {
      return false
    }
  }

  amountIsCorrect() {
    return this.amountBank + this.amountWallet <= this.data.maxRefundAmt && this.amountBank >= 0 && this.amountWallet >= 0;
  }


}
