import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from "rxjs";

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input: ElementRef;
  @Input() searchValue;
  @Input() debounceTime = 1500;
  @Output() searchEmitter = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {


  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.searchEmitter.emit(this.input.nativeElement.value);
        })
      )
      .subscribe();
  }

  clearValue() {
    this.searchValue = '';
    console.log(this.input.nativeElement.value)
    this.searchEmitter.emit('');
  }
}
