<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div *ngIf="!fullBonus" class="refund-option" fxFlex="50%" fxLayout="row" fxLayoutAlign="start center"
         fxLayoutGap="10px">
      <mat-checkbox
              [(ngModel)]="bankSelected"
              labelPosition='before'
              color="primary"
              (change)="handleChanges()"
      ></mat-checkbox>
      <mat-icon>account_balance</mat-icon>
      <span>A CUENTA BANCARIA</span>
    </div>
    <div class="refund-option" fxFlex="50%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-checkbox
              [(ngModel)]="walletSelected"
              labelPosition='before'
              color="primary"
              (change)="handleChanges()"
      ></mat-checkbox>
      <mat-icon>wallet</mat-icon>
      <span>AL BONO MONEDERO</span>
    </div>
  </div>
  <div fxLayout="column">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Descripción</mat-label>
      <input matInput type="text" [(ngModel)]="description" (keyup)="handleChanges()" (ngModelChange)="handleChanges()">
    </mat-form-field>
    <mat-form-field *ngIf="!bankSelected || !walletSelected" appearance="outline"
                    fxFlex>
      <mat-label>Cantidad a reembolsar</mat-label>
      <input matInput type="number" [(ngModel)]="amount" min="0" (keyup)="handleChanges()"
             (ngModelChange)="handleChanges()">
    </mat-form-field>
    <div fxLayout="row" *ngIf="bankSelected && walletSelected" fxLayoutGap="20px">
      <mat-form-field appearance="outline"
                      fxFlex>
        <mat-label>Reembolso en cuenta</mat-label>
        <input matInput type="number" [(ngModel)]="amountBank" min="0" (keyup)="handleChanges()"
               (ngModelChange)="handleChanges()">
      </mat-form-field>
      <mat-form-field appearance="outline"
                      fxFlex>
        <mat-label>Reembolso en bono</mat-label>
        <input matInput type="number" [(ngModel)]="amountWallet" min="0" (keyup)="handleChanges()"
               (ngModelChange)="handleChanges()">
      </mat-form-field>
    </div>
  </div>
  <mat-error *ngIf="errMsg">
    {{errMsg}}
  </mat-error>
</mat-dialog-content>
<mat-dialog-actions style="padding-bottom: 20px;" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
  <button fxFlex="50" mat-stroked-button mat-dialog-close color="warn">
    Cerrar
  </button>
  <button fxFlex="50" mat-stroked-button color="primary" [disabled]="!canConfirm()"
          (click)="confirm()">
    Confirmar
  </button>
</mat-dialog-actions>
