import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BundlePrintJobsDialogComponent } from 'src/app/components/bundle-print-jobs-dialog/bundle-print-jobs-dialog.component';
import { PaperWeight } from 'src/app/models/models';
import { BundleRetryStatus, BundleStatus, PrintBundle } from 'src/app/models/printer-model';

@Component({
  selector: 'app-bundle-card',
  templateUrl: './bundle-card.component.html',
  styleUrls: ['./bundle-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundleCardComponent implements OnInit {
  @Input() bundle!: PrintBundle;
  @Input() requirementId?: string;

  PaperWeight = PaperWeight;
  paperWeightValues = Object.values(PaperWeight);
  peloticaColor = 'bn';
  caption: string = null;
  captionBottom: string = null;
  retryTooltip: string = null;

  BundleStatus = BundleStatus;
  BundleRetryStatus = BundleRetryStatus;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.peloticaColor = setBundleColor(this.bundle);
    ({ caption: this.caption, captionBottom: this.captionBottom } = setBundleCaptions(this.bundle));
    
    if (this.bundle.retriedFrom) {
      this.retryTooltip = 'Esta solicitud es un reintento automático';
    } else if (this.bundle.retryStatus == BundleRetryStatus.PROCESSING) {
      this.retryTooltip = 'Hay un reintento automático en proceso';
    } else if (this.bundle.retryStatus == BundleRetryStatus.FINISHED) {
      if (this.bundle.status == BundleStatus.FINISHED) {
        this.retryTooltip = 'El reintento automático ha terminado con éxito';
      } else {
        this.retryTooltip = 'El reintento automático ha fallado';
      }
    }
  }

  async onShowPrintJobs() {
    const dialogRef = this.dialog.open(BundlePrintJobsDialogComponent, {
      data: { bundle: this.bundle, requirementId: this.requirementId },
      // minWidth: '800px',
      maxWidth: '90vw',
      minHeight: '300px',
      maxHeight: '90vh',
    });
    await dialogRef.afterClosed().toPromise();
  }

}

export const setBundleColor = (bundle: PrintBundle) => {
  return bundle.printJobType === 'mixed'
    ? 'mixed'
    : bundle.printer.colorSupported ? 'color' : 'bn'
  // TODO: this is not quite correct: bn will be able to be printed from color printer
  // TODO: find and use `export function peloticaColor(type: PrintJob.Type) {`
}

export const setBundleCaptions = (bundle: PrintBundle): { caption: string, captionBottom: string } => {
  const captions: Array<string> = [];
  const captionsBottom: Array<string> = [];

  switch (bundle.status) {
    case BundleStatus.PREPROCESSING: { // Procesando...
      const length = bundle.preprocessJobIdsLength ?? 0;
      if (length) {
        captions.push(`${length == 1 ? 'Queda' : 'Quedan'} ${length} ${length == 1 ? 'archivo' : 'archivos'}`);
      }
    } break;
    case BundleStatus.PRINTING: { // Imprimiendo...
      const length = bundle.printJobStatusesLength ?? 0;
      if (length) {
        captions.push(`${length == 1 ? 'Queda' : 'Quedan'} ${length} ${length == 1 ? 'archivo' : 'archivos'}`);
      }
    } break;
    case BundleStatus.FAILED: { // Fallido...
      if (bundle.error) {
        // may not exist: temp: from requirement failure
        if (bundle.error.jobName) {
          captionsBottom.push(bundle.error.jobName);
        }
        if (bundle.error.message) {
          captionsBottom.push(bundle.error.message);
        }
      }

    } break;
  }

  if (bundle.reportOnly) {
    captions.push('Solo reporte');
  } else {
    const indexes = [
      ...(bundle.printGroupIndexes ?? []).map(i => `G${i + 1}`),
      ...(bundle.printGroupFileIndexes ?? []).map(i => `F${i + 1}`),
    ];
    if (indexes.length > 0) {
      captions.push(indexes.join(' '));
    }
  }

  return {
    caption: captions.length ? captions.join(' - ') : null,
    captionBottom: captionsBottom.length ? captionsBottom.join('<br>') : null,
  };
}
