import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, Observable } from "rxjs";
import { map, share, take } from "rxjs/operators";
import { OrderPrintableStatus, PageSize } from "src/app/models/models";
import { PrintJob } from "src/app/models/print-job.model";
import { PrintProduct } from "src/app/models/printProduct.model";
import { ProductType } from "src/app/models/product.model";
import { OrderService } from "src/app/shared/order.service";

@Component({
  selector: "app-print-partial-dialog",
  templateUrl: "./print-partial-dialog.component.html",
  styleUrls: ["./print-partial-dialog.component.scss"],
})
export class PrintPartialDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<PrintPartialDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private orderDbService: OrderService) { }

  order$ = this.orderDbService.get(this.data.order.id).pipe(take(1), share());
  printProducts$ = this.order$.pipe(
    map((order) => order.cart.items.filter((e) => e.product.type === ProductType.PRINT).map((cartItem) => cartItem.product))
  );
  isPrintableStatusFinished$ = this.order$.pipe(
    map(order => [OrderPrintableStatus.READY, OrderPrintableStatus.DISCARDED].includes(order.printableStatus)),
  );
  showExceptionCoverLaminated$ = this.order$.pipe(
    map(order => order.contains.includes('PPL')),
  );

  selectedMode = new FormControl("groups");
  exceptionCoverLaminated = new FormControl(false);

  _printWorksSelected = new BehaviorSubject<Array<PrintWorkSelected>>(null);
  printWorksSelected$ = this._printWorksSelected.asObservable();
  _printFilesSelected = new BehaviorSubject<Array<any>>(null);
  printFilesSelected$ = this._printFilesSelected.asObservable();
  printersSelection = null;

  pageSize: PageSize = null;
  exceptionCoverLaminatedPageSize: PageSize = null;

  canPrint: boolean = false;


  ngOnInit(): void { }

  onPrint() {
    //Mapeamos lo recibido al formato que lo necesitaremos para mandar a imprimir
    const { skipReport, printTypeSelected, selectedPrinters, discardOnPrint, forceLargerPageSize } = this.printersSelection;
    const resp = {
      skipReport,
      printGroupIndexes: this._printWorksSelected.value.map((ps) => ps.id),
      printGroupFileIndexes: this._printFilesSelected.value ? this._printFilesSelected.value.map((pf) => pf.index) : null,
      printTypeSelected,
      selectedPrinters,
      selectedMode: this.selectedMode.value,
      pageSize: this.pageSize,
      discardOnPrint,
      // exceptions:
      forceLargerPageSize: !!forceLargerPageSize,
      exceptionCoverLaminatedPageSize:
        this.exceptionCoverLaminated.value && this.exceptionCoverLaminatedPageSize
          ? this.exceptionCoverLaminatedPageSize : null,
    };
    this.dialogRef.close(resp);
  }

  onSelectionChanges(event, type) {
    // console.log('onSelectionChanges', type, event?.payload);
    const { payload } = event;
    const selected = payload.selected as Array<PrintWorkSelected>;
    this.pageSize = payload.pageSize;

    this._printFilesSelected.next(type == "files" ? payload.selectedFiles?.sort((a, b) => a.index - b.index) : null);
    if (type == "files") {
      this.pageSize = selected[0]?.size;
    }

    // console.log('new page size: ', this.pageSize);

    // handle exceptions:
    if (this.exceptionCoverLaminated) {
      switch (this.pageSize) {
        case PageSize.A5: { this.exceptionCoverLaminatedPageSize = PageSize.A4 } break;
        case PageSize.A4: { this.exceptionCoverLaminatedPageSize = PageSize.A3 } break;
        default: { this.exceptionCoverLaminatedPageSize = null } break;
      }
    } else {
      this.exceptionCoverLaminatedPageSize = null;
    }


    this._printWorksSelected.next(selected?.sort((a, b) => a.id - b.id));

    this.canPrint = type == "files"
      ? this._printFilesSelected.value?.length > 0
      : this._printWorksSelected.value?.length > 0;
  }

  onPrintersChanges(event) {
    this.printersSelection = event.payload;
  }
}

export interface PrintWorkSelected {
  id: number;
  name: string;
  docs: number;
  pages: number;
  printLabels: Array<any>;
  size: PageSize,
  type: PrintJob.Type.COLOR | PrintJob.Type.BN | PrintJob.Type.MIXED;
  printProduct: PrintProduct;
}
