import { Injectable } from '@angular/core';
import  firebase  from 'firebase/app';
import { AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore/public_api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Master representa todas las acciones que NO se deberían estar repitiendo dentro de cada
 * servicio.
 * - tambien debe ser todas funciones generales (no especificas del servicio)
 */
@Injectable({
  providedIn: 'root'
})
export class MasterService {

  

  constructor(
  ) { }


   /**
   * Get all the documents stored in the collection
   */
  public getAll(collection: AngularFirestoreCollection):Observable<any[]> {
    return collection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        this.normalize(data)
        // console.log("...",data);
        return {id,  ...data };
      }))
    )
  }  


  /**
   * Deletes the document specifyied in the @param id, at the @param collection
   * @param id 
   * @param collection 
   * @returns a promise void when it gets a re
   */
  public delete(id: string, collection: AngularFirestoreCollection): Promise<void>{
    return collection.doc<any>(id).delete();
  }  


  /**
   *  updates a document or creates a new if id does not exist
   * @param _data  where we have the info
   * @param collection the group of docs in DB
   * @param _id used if does not exist in _data
   * @returns a void responses, same as .finally()
   */
  public upsert(_data, collection: AngularFirestoreCollection, _id?): Promise<void | DocumentReference>{
    let {id, ...data} = _data;
    if(!id){
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp(); 
      id = _id
    }
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    return (id)
      ? collection.doc(id).set(data, {merge: true})
      : collection.add(data);
  }  
  

  // private getDocIn(collection: string, idDoc: string){
  //   return  this.db.collection(collection).doc(idDoc)
  //   .valueChanges().pipe()
  // }

  /**
   * Check if exist date in data and set the timestamp to date, else create a new Date()
   * @param data 
   */
  public normalize(data){
    data.createdAt = data.createdAt ? data.createdAt.toDate() : new Date();
    return data
  }

  /**
   * normalize the timestamp to date
   */
  public ifTimestamp2Date = property =>  property instanceof firebase.firestore.Timestamp ?  property.toDate() : property;
}
