<div fxLayout="row">
  <div>
    <div class="pelotica" [ngClass]="peloticaColor(element.type)"></div>
  </div>
  <div>
    <div class="mb-2">
        <b>{{element.name}}</b>,
        <b>{{element.docs}}</b>
        documentos,
        <b>{{element.pages}}</b>
        páginas
    </div>
    <mat-chip-list>
        <ng-container *ngFor="let adminLabel of element.printLabels">
            <mat-chip
                class="{{adminLabel.classes}}"
                [matTooltip]="adminLabel.tooltip"
                [matTooltipDisabled]="!adminLabel.tooltip"
            >
                {{adminLabel.label}}
            </mat-chip>
        </ng-container>
    </mat-chip-list>
  </div>
</div>
